import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  IconButton,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckOverflow from "../../utils/CheckOverflow";
import TaskSheetReportAndResolve from "../TaskSheetReportAndResolve";
import { useQuery } from "react-query";
import { getReportIssueData } from "../TaskSheet/mutation";
import dayjs from "dayjs";
import Loader from "../../components/Loader";
import { defaultSeconds } from "../../config/masterdata";

function TaskSheetManager({
  setSecondsForFirstInterval,
  isTelevision,
  setPauseInterval,
  pauseInterval,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterSlNo, setFilterSlNo] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  const managerId = parseInt(localStorage.getItem("id"), 10);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [isForm, setIsform] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isSeek, setIsSeek] = useState(false);
  const [isResolve, setIsResolve] = useState(false);

  const [showIssueData, setShowIssueData] = useState({});

  const isMD = localStorage.getItem("roles") === "MANAGING_DIRECTOR";

  const {
    data: reportIssueData,
    isLoading: isReportDataLoading,
    refetch,
  } = useQuery(
    "task-sheets-report-issue",
    async () => {
      if (!isTelevision && !isManager) {
        setIsLoadingData(true);
      }
      try {
        const response = await getReportIssueData();
        return response;
      } catch (error) {
        throw error;
      } finally {
        if (!isTelevision && !isManager) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  //this is for pause the setTimeout when filter the data
  useEffect(() => {
    if (isTelevision) {
      if (filterSlNo) {
        setPauseInterval(true);
      } else if (!filterSlNo) {
        setPauseInterval(false);
      }
    }
  }, [filterSlNo, isTelevision, setPauseInterval]);

  // Set the interval based on the length of the data for television ui change
  useEffect(() => {
    if (filteredData && isTelevision) {
      setSecondsForFirstInterval(() => {
        //this is for to get the average no of data that we show in the ui
        const dataLength = Math.ceil(filteredData?.length / rowsPerPage);

        return dataLength * defaultSeconds;
      });
    }
  }, [filteredData, isTelevision, rowsPerPage, setSecondsForFirstInterval]);

  // Set the interval based on the length of the data in this ui change
  useEffect(() => {
    if (isTelevision) {
      const totalPages = Math.ceil(filteredData?.length / rowsPerPage);

      const intervalId = setInterval(() => {
        if (!pauseInterval) {
          setPage((prevPage) => (prevPage + 1) % totalPages);
        }
      }, defaultSeconds); // Switch data every 20 seconds

      return () => clearInterval(intervalId);
    }
  }, [filteredData, isTelevision, pauseInterval, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    if (isNaN(event.target.value)) {
      alert("Please enter a number only");
      return;
    }

    setFilterSlNo(event.target.value);
    setPage(0);
  };

  const handleResetFilter = () => {
    setFilterSlNo("");
    setPage(0);
  };

  const handleStatusFilterChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
      setPage(0);
    }
  };

  //this is for pretend the rerender issue when we give checkoverflow params

  useEffect(() => {
    let newFilteredData = [];

    if (isTelevision || isMD) {
      //for television we should show the status 'PENDING' Data only
      newFilteredData =
        reportIssueData?.data?.filter((row) => {
          const matchesFilterTerm =
            !filterSlNo || row?.slNo?.toString() === filterSlNo;

          const matchesPendingOnly = row.status === "PENDING";

          return matchesFilterTerm && matchesPendingOnly;
        }) || [];
    } else {
      //for manager we can show all the data
      newFilteredData =
        reportIssueData?.data?.filter((row) => {
          const matchesFilterTerm =
            !filterSlNo || row.slNo.toString() === filterSlNo;

          const matchesStatusFilter =
            statusFilter === "All" || row.status === statusFilter;

          return matchesFilterTerm && matchesStatusFilter;
        }) || [];
    }

    setFilteredData(newFilteredData);
  }, [filterSlNo, isMD, isTelevision, reportIssueData?.data, statusFilter]);

  //handle overflow text
  const [isOverflowDraft, addDraftRef] = CheckOverflow(filteredData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflowDraft[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  const handleResolve = (row) => {
    setShowIssueData(row);
    setIsform(true);
    setIsManager(true);
    setIsResolve(true);
    setIsSeek(false);
    if (isTelevision) {
      setPauseInterval(true);
    }
  };

  const handleSeek = (row) => {
    setShowIssueData(row);
    setIsform(true);
    setIsManager(true);
    setIsSeek(true);
    setIsResolve(false);
    if (isTelevision) {
      setPauseInterval(true);
    }
  };

  const goBack = () => {
    setIsform(false);
    setIsManager(false);
    setIsResolve(false);
    setIsSeek(false);
    setShowIssueData({});
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  //handle show image in television only
  const [openNew, setOpenNew] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpenNew(true);
    if (isTelevision) {
      setPauseInterval(true);
    }
  };

  const handleClose = () => {
    setOpenNew(false);
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  if (isReportDataLoading || isLoadingData) {
    return <Loader />;
  }

  return (
    <Grid
      container
      item
      xs={12}
      alignContent={"flex-start"}
      rowGap={4}
      sx={{
        padding: "36px",
        "@media (max-width: 600px)": {
          padding: "0px 8px 0px 15px",
        },
      }}
    >
      {isForm ? (
        <TaskSheetReportAndResolve
          isManager={isManager}
          isSeek={isSeek}
          isResolve={isResolve}
          goBack={goBack}
          showIssueData={showIssueData}
          isTelevision={isTelevision}
        />
      ) : (
        <Grid container item xs={12} rowGap={4}>
          {!isTelevision && !isMD && (
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid container item md={6} sm={6} xs={8}>
                <TextField
                  fullWidth
                  placeholder="Enter Sl No to filter"
                  value={filterSlNo}
                  onChange={handleFilterChange}
                  InputProps={{
                    endAdornment: filterSlNo && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleResetFilter}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}

          {!isTelevision && !isMD && (
            <Grid container item md={6} sm={6} xs={9}>
              <ToggleButtonGroup
                value={statusFilter}
                exclusive
                onChange={handleStatusFilterChange}
                aria-label="status filter"
                fullWidth
                style={{ overflowX: "auto" }}
              >
                <ToggleButton value="All" aria-label="all">
                  All
                </ToggleButton>
                <ToggleButton value="PENDING" aria-label="pending">
                  Pending
                </ToggleButton>
                <ToggleButton value="RESOLVED" aria-label="resolved">
                  Resolved
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          <Grid container item md={12} sm={11} xs={9}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: "70px", fontWeight: "600" }}>
                      Sl No
                    </TableCell>
                    <TableCell style={{ maxWidth: "70px", fontWeight: "600" }}>
                      Date
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Description
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Department
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Reported By
                    </TableCell>
                    {(isTelevision || isMD) && (
                      <TableCell
                        style={{ maxWidth: "100px", fontWeight: "600" }}
                      >
                        Image
                      </TableCell>
                    )}
                    <TableCell style={{ maxWidth: "220px", fontWeight: "600" }}>
                      Action / Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => {
                        const isManagerResponse =
                          row?.reportingManagerSolution?.filter(
                            (item) => parseInt(item.id, 10) === managerId
                          );

                        const showSeekDateSelect =
                          isManagerResponse?.length > 0
                            ? row.reportingManagerSolution === null ||
                              (isManagerResponse[0]?.seekedDate === null &&
                                isManagerResponse[0]?.solution === null)
                            : true;

                        const showResolve =
                          isManagerResponse?.length > 0
                            ? row.reportingManagerSolution === null ||
                              isManagerResponse[0]?.solution === null
                            : true;

                        return (
                          <TableRow key={row.id}>
                            <TableCell style={{ maxWidth: "70px" }}>
                              <p className="overflowContain"> {row.slNo}</p>
                            </TableCell>
                            <TableCell style={{ maxWidth: "70px" }}>
                              <p className="overflowContain">
                                {dayjs(row.date).format("DD-MM-YYYY")}
                              </p>
                            </TableCell>
                            <TableCell style={{ maxWidth: "150px" }}>
                              <p
                                // className="overflowContain"
                                ref={addDraftRef(`description-${row.id}`)}
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(
                                    e,
                                    `description-${row.id}`,
                                    row.description
                                  )
                                }
                                onMouseLeave={handlePopoverClose}
                                style={{
                                  color: "#e03131",
                                  fontWeight: "bold",
                                  textAlign: "justify",
                                  wordWrap: "break-word", // Breaks long words to fit within the container
                                  whiteSpace: "normal", // Allow wrapping on multiple lines
                                }}
                              >
                                {row.description}
                              </p>
                            </TableCell>
                            <TableCell style={{ maxWidth: "100px" }}>
                              <p
                                className="overflowContain"
                                ref={addDraftRef(`department-${row.id}`)}
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(
                                    e,
                                    `department-${row.id}`,
                                    row?.department
                                      ?.map((item) => item)
                                      .join(", ")
                                  )
                                }
                                onMouseLeave={handlePopoverClose}
                              >
                                {row?.department
                                  ?.map((item) => item)
                                  .join(", ")}
                              </p>
                            </TableCell>
                            <TableCell style={{ maxWidth: "100px" }}>
                              <p
                                className="overflowContain"
                                ref={addDraftRef(`reportedBy-${row.id}`)}
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(
                                    e,
                                    `reportedBy-${row.id}`,
                                    row.reportedBy
                                  )
                                }
                                onMouseLeave={handlePopoverClose}
                              >
                                {row.reportedBy}
                              </p>
                            </TableCell>

                            {(isTelevision || isMD) && (
                              <TableCell style={{ maxWidth: "100px" }}>
                                <img
                                  src={row?.image}
                                  alt="issue"
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => handleClickOpen(row.image)}
                                />
                              </TableCell>
                            )}

                            {isTelevision || isMD ? (
                              <TableCell style={{ maxWidth: "220px" }}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  rowGap={1}
                                  gap={2}
                                  alignItems="center"
                                >
                                  {row.status === "PENDING" && (
                                    <p
                                      style={{
                                        width: "100%",
                                        color: "#f08c00",
                                      }}
                                    >
                                      {row.status}
                                    </p>
                                  )}
                                </Grid>
                              </TableCell>
                            ) : (
                              <TableCell style={{ maxWidth: "220px" }}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  rowGap={1}
                                  gap={2}
                                  alignItems="center"
                                >
                                  {row.status === "RESOLVED" ? (
                                    <p
                                      style={{
                                        width: "100%",
                                        // display: "flex",
                                        // justifyContent: "center",
                                        color: "#2f9344",
                                      }}
                                    >
                                      {row.status}
                                    </p>
                                  ) : showSeekDateSelect ? (
                                    <>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                          fontSize: {
                                            md: "16px",
                                            sm: "16px",
                                            xs: "12px",
                                          },
                                          width: {
                                            md: "140px",
                                            sm: "140px",
                                            xs: "100px",
                                          },
                                        }}
                                        style={{
                                          textTransform: "none",
                                          color: "#007BFF",
                                          borderColor: "#007BFF",
                                        }}
                                        onClick={() => handleSeek(row)}
                                      >
                                        Seek Time
                                      </Button>
                                      {showResolve && (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          sx={{
                                            fontSize: {
                                              md: "16px",
                                              sm: "16px",
                                              xs: "12px",
                                            },
                                            width: {
                                              md: "140px",
                                              sm: "140px",
                                              xs: "100px",
                                            },
                                          }}
                                          style={{
                                            textTransform: "none",
                                            background: "#28A745",
                                            color: "white",
                                          }}
                                          onClick={() => handleResolve(row)}
                                        >
                                          Resolve
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    row.status === "PENDING" && (
                                      <>
                                        <p
                                          style={{
                                            width: "140px",
                                            // display: "flex",
                                            // justifyContent: "center",
                                            color: `${
                                              row.status === "PENDING"
                                                ? "#f08c00"
                                                : "#2f9344"
                                            }`,
                                          }}
                                        >
                                          {row.status}
                                        </p>
                                        {showResolve && (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                              fontSize: {
                                                md: "16px",
                                                sm: "16px",
                                                xs: "12px",
                                              },
                                              width: {
                                                md: "140px",
                                                sm: "140px",
                                                xs: "100px",
                                              },
                                            }}
                                            style={{
                                              textTransform: "none",
                                              background: "#28A745",
                                              color: "white",
                                            }}
                                            onClick={() => handleResolve(row)}
                                          >
                                            Resolve
                                          </Button>
                                        )}
                                      </>
                                    )
                                  )}
                                </Grid>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">
                          No data available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Dialog open={openNew} onClose={handleClose} maxWidth="lg">
                <DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{ position: "absolute", right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <img
                    src={selectedImage}
                    alt="issue"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </DialogContent>
              </Dialog>

              <Popover
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <p style={{ padding: "15px" }}>{popoverContent}</p>
              </Popover>
            </TableContainer>
            {!isTelevision && !isMD && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={filteredData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default TaskSheetManager;
