import { Grid, Box, TextField, Button, Tabs, Tab } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import GeneralMasterForm from "../../components/GeneralMasterForm";
import BlowroomMasterForm from "../../components/BlowroomMasterForm";
import CardingMasterForm from "../../components/CardingMasterForm";
import DrawingMasterForm from "../../components/DrawingMasterForm";
import SimplexMasterForm from "../../components/SimplexMasterForm";
import SpinningMasterForm from "../../components/SpinningMasterForm";
import AutoConerMasterForm from "../../components/AutoConerMasterForm";
import OthersMasterForm from "../../components/OthersMasterForm";
import { handleToastMsg } from "../../components/Toast";
import {
  createMasterRecord,
  createSavedMasterRecord,
  updateSavedRecord,
  updateSubmittedRecord,
} from "./mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllSubmittedRecords } from "../ParameterMasters/mutation";
import { getAllSavedRecords } from "../SavedParameterMaster/mutation";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { ContainerWrapper } from "../../components/styles";
import { CircularProgress } from "@mui/material";

function AddParameterMaster() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { editData } = location.state || {};
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: submittedMasterData } = useQuery(
    "all-parameters",
    getAllSubmittedRecords,
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  const { data: savedRecords } = useQuery(
    "all-saved-parameters",
    getAllSavedRecords,
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  const submittedPartyCodes = submittedMasterData?.data?.map(
    (row) => row.partyCode
  );
  const savedPartyCodes = savedRecords?.data?.map((row) => row.partyCode);

  const [partyName, setPartyName] = useState("");
  const [variety, setVariety] = useState("");
  const [partyCode, setPartyCode] = useState("");
  const [general, setGeneral] = useState({
    fiberMake: "",
    denierXCutLength: { x: "", y: "" },
    mergeNo: "",
  });

  const [blowroom, setBlowroom] = useState({
    rkSpeed: "",
    gridBar: "",
    kbSpeed: "",
    lapLength: "",
    lapWeight: "",
  });

  const [carding, setCarding] = useState({
    cardingNo: [], //multi select
    likerInSpeed: "",
    cylinderSpeed: "",
    flatSpeed: "",
    deliverySpeed: "",
    deliveryHank: "",
    draft: "",
  });

  const [drawing, setDrawing] = useState({
    breakerDrawing: "",
    breakerNoOfEnds: "",
    breakerBottomRollSetting: "",
    bdBreaker: "",
    breakerDrawingHank: "",
    breakerSpeed: "",
    finisherDrawing: "",
    finisherNoOfEnds: "",
    finisherBottomRollSetting: "",
    bdFinisher: "",
    finisherDeliveryHank: "",
    finisherDeliverySpeed: "",
  });

  const [simplex, setSimplex] = useState({
    simplexNo: "",
    simplexHank: "",
    tpiTm: "",
    speed: "",
    bottomRollSetting: { x: "", y: "", z: "" },
    topRollSetting: { x: "", y: "", z: "" },
    bd: "",
    spacer: "",
    topArmPressure: "",
    condensorBack: "",
    condensorMiddle: "",
    condensorFront: "",
  });

  const [spinning, setSpinning] = useState({
    frameNo: [], //multi select
    bottomRollSetting: { x: "", y: "", z: "" },
    topRollSetting: { x: "", y: "", z: "" },
    bd: "",
    totalDraft: "",
    tpiTm: "",
    spacer: "",
    traveller: "",
    speed: "",
    lycraDetailsMake: "",
    lycraDraft: "",
    calLycraPercentage: "",
    actLycraPercentage: "",
  });

  const [autoconer, setAutoconer] = useState({
    autoconerNo: "",
    speed: "",
    paperConeIdentification: [], //multi select
    coneWeight: "",
    splicingNozzle: "",
    splicingSetting: "",
    lLever: "",
    creadlePressure: "",
    yarnConditioning: "",
  });

  const [others, setOthers] = useState({
    yarnConditioning: "",
  });

  const [selectedCardingValues, setSelectedCardingValues] = useState([]);
  const [autoConerPaperCone, setAutoConerPaperCone] = useState([]);

  const initializeWithData = (data) => {
    setPartyName(data.partyName || "");
    setVariety(data.variety || "");
    setPartyCode(data.partyCode || "");

    // Set general
    setGeneral({
      fiberMake: data.general?.fiberMake || "",
      denierXCutLength: {
        x: data.general?.denierXCutLength?.x || "",
        y: data.general?.denierXCutLength?.y || "",
      },
      mergeNo: data.general?.mergeNo || "",
    });

    // Set blowroom
    setBlowroom({
      rkSpeed: data.blowroom?.rkSpeed || "",
      gridBar: data.blowroom?.gridBar || "",
      kbSpeed: data.blowroom?.kbSpeed || "",
      lapLength: data.blowroom?.lapLength || "",
      lapWeight: data.blowroom?.lapWeight || "",
    });

    // Set carding
    setCarding({
      cardingNo: data.carding?.cardingNo || [],
      likerInSpeed: data.carding?.likerInSpeed || "",
      cylinderSpeed: data.carding?.cylinderSpeed || "",
      flatSpeed: data.carding?.flatSpeed || "",
      deliverySpeed: data.carding?.deliverySpeed || "",
      deliveryHank: data.carding?.deliveryHank || "",
      draft: data.carding?.draft || "",
    });

    // Set drawing
    setDrawing({
      breakerDrawing: data.drawing?.breakerDrawing || "",
      breakerNoOfEnds: data.drawing?.breakerNoOfEnds || "",
      breakerBottomRollSetting: data.drawing?.breakerBottomRollSetting || "",
      bdBreaker: data.drawing?.bdBreaker || "",
      breakerDrawingHank: data.drawing?.breakerDrawingHank || "",
      breakerSpeed: data.drawing?.breakerSpeed || "",
      finisherDrawing: data.drawing?.finisherDrawing || "",
      finisherNoOfEnds: data.drawing?.finisherNoOfEnds || "",
      finisherBottomRollSetting: data.drawing?.finisherBottomRollSetting || "",
      bdFinisher: data.drawing?.bdFinisher || "",
      finisherDeliveryHank: data.drawing?.finisherDeliveryHank || "",
      finisherDeliverySpeed: data.drawing?.finisherDeliverySpeed || "",
    });

    // Set simplex
    setSimplex({
      simplexNo: data.simplex?.simplexNo || "",
      simplexHank: data.simplex?.simplexHank || "",
      tpiTm: data.simplex?.tpiTm || "",
      speed: data.simplex?.speed || "",
      bottomRollSetting: {
        x: data.simplex?.bottomRollSetting?.x || "",
        y: data.simplex?.bottomRollSetting?.y || "",
        z: data.simplex?.bottomRollSetting?.z || "",
      },
      topRollSetting: {
        x: data.simplex?.topRollSetting?.x || "",
        y: data.simplex?.topRollSetting?.y || "",
        z: data.simplex?.topRollSetting?.z || "",
      },
      bd: data.simplex?.bd || "",
      spacer: data.simplex?.spacer || "",
      topArmPressure: data.simplex?.topArmPressure || "",
      condensorBack: data.simplex?.condensorBack || "",
      condensorMiddle: data.simplex?.condensorMiddle || "",
      condensorFront: data.simplex?.condensorFront || "",
    });

    // Set spinning
    setSpinning({
      frameNo: data.spinning?.frameNo || [],
      bottomRollSetting: {
        x: data.spinning?.bottomRollSetting?.x || "",
        y: data.spinning?.bottomRollSetting?.y || "",
        z: data.spinning?.bottomRollSetting?.z || "",
      },
      topRollSetting: {
        x: data.spinning?.topRollSetting?.x || "",
        y: data.spinning?.topRollSetting?.y || "",
        z: data.spinning?.topRollSetting?.z || "",
      },
      bd: data.spinning?.bd || "",
      totalDraft: data.spinning?.totalDraft || "",
      tpiTm: data.spinning?.tpiTm || "",
      spacer: data.spinning?.spacer || "",
      traveller: data.spinning?.traveller || "",
      speed: data.spinning?.speed || "",
      lycraDetailsMake: data.spinning?.lycraDetailsMake || "",
      lycraDraft: data.spinning?.lycraDraft || "",
      calLycraPercentage: data.spinning?.calLycraPercentage || "",
      actLycraPercentage: data.spinning?.actLycraPercentage || "",
    });

    // Set autoconer
    setAutoconer({
      autoconerNo: data.autoconer?.autoconerNo || "",
      speed: data.autoconer?.speed || "",
      paperConeIdentification: data.autoconer?.paperConeIdentification || [],
      coneWeight: data.autoconer?.coneWeight || "",
      splicingNozzle: data.autoconer?.splicingNozzle || "",
      splicingSetting: data.autoconer?.splicingSetting || "",
      lLever: data.autoconer?.lLever || "",
      creadlePressure: data.autoconer?.creadlePressure || "",
      yarnConditioning: data.autoconer?.yarnConditioning || "",
    });

    // Set others
    setOthers({
      yarnConditioning: data.others?.yarnConditioning || "",
    });
  };

  useEffect(() => {
    if (editData) {
      initializeWithData(editData);
    }
  }, [editData]);

  const submitMutation = useMutation(createMasterRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-parameters");
      handleToastMsg("Submitted", "success");
      navigate("/parameter-master");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const saveMutation = useMutation(createSavedMasterRecord, {
    onSuccess: () => {
      handleToastMsg("Saved", "success");
      queryClient.invalidateQueries("all-saved-parameters");
      navigate("/parameter-master");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const updateSavedMutation = useMutation(
    (payload) => updateSavedRecord(editData.id, payload),
    {
      onSuccess: () => {
        handleToastMsg("Saved Successfully!", "success");
        queryClient.invalidateQueries("all-saved-records");
        navigate("/parameter-master");
      },
      onError: (error) => {
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const updateSubmittedMutation = useMutation(
    (payload) => updateSubmittedRecord(editData.id, payload),
    {
      onSuccess: () => {
        handleToastMsg("Updated Successfully!", "success");
        queryClient.invalidateQueries("all-submitted-records");
        navigate("/parameter-master");
        // Add any success handling logic here
      },
      onError: (error) => {
        // Add error handling logic here
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const validateAllParameters = () => {
    // Check partyName, variety, partyCode
    if (!partyName || !variety || !partyCode) {
      return false;
    }

    // Check general
    if (
      !general.fiberMake ||
      !general.denierXCutLength.x ||
      !general.denierXCutLength.y ||
      !general.mergeNo
    ) {
      return false;
    }

    // Check blowroom
    if (
      !blowroom.rkSpeed ||
      !blowroom.gridBar ||
      !blowroom.kbSpeed ||
      !blowroom.lapLength ||
      !blowroom.lapWeight
    ) {
      return false;
    }

    // Check carding
    if (
      carding.cardingNo.length === 0 ||
      !carding.likerInSpeed ||
      !carding.cylinderSpeed ||
      !carding.flatSpeed ||
      !carding.deliverySpeed ||
      !carding.deliveryHank ||
      !carding.draft
    ) {
      return false;
    }

    // Check drawing
    if (
      !drawing.breakerDrawing ||
      !drawing.breakerNoOfEnds ||
      !drawing.breakerBottomRollSetting ||
      !drawing.bdBreaker ||
      !drawing.breakerDrawingHank ||
      !drawing.breakerSpeed ||
      !drawing.finisherDrawing ||
      !drawing.finisherNoOfEnds ||
      !drawing.finisherBottomRollSetting ||
      !drawing.bdFinisher ||
      !drawing.finisherDeliveryHank ||
      !drawing.finisherDeliverySpeed
    ) {
      return false;
    }

    // Check simplex
    if (
      !simplex.simplexNo ||
      !simplex.simplexHank ||
      !simplex.tpiTm ||
      !simplex.speed ||
      !simplex.bottomRollSetting.x ||
      !simplex.bottomRollSetting.y ||
      !simplex.bottomRollSetting.z ||
      !simplex.topRollSetting.x ||
      !simplex.topRollSetting.y ||
      !simplex.topRollSetting.z ||
      !simplex.bd ||
      !simplex.spacer ||
      !simplex.topArmPressure ||
      !simplex.condensorBack ||
      !simplex.condensorMiddle ||
      !simplex.condensorFront
    ) {
      return false;
    }

    // Check spinning
    if (
      spinning.frameNo.length === 0 ||
      !spinning.bottomRollSetting.x ||
      !spinning.bottomRollSetting.y ||
      !spinning.bottomRollSetting.z ||
      !spinning.topRollSetting.x ||
      !spinning.topRollSetting.y ||
      !spinning.topRollSetting.z ||
      !spinning.bd ||
      !spinning.totalDraft ||
      !spinning.tpiTm ||
      !spinning.spacer ||
      !spinning.traveller ||
      !spinning.speed ||
      !spinning.lycraDetailsMake ||
      !spinning.lycraDraft ||
      !spinning.calLycraPercentage ||
      !spinning.actLycraPercentage
    ) {
      return false;
    }

    // Check autoconer
    if (
      !autoconer.autoconerNo ||
      !autoconer.speed ||
      autoconer.paperConeIdentification.length === 0 ||
      !autoconer.coneWeight ||
      !autoconer.splicingNozzle ||
      !autoconer.splicingSetting ||
      !autoconer.lLever ||
      !autoconer.creadlePressure ||
      !autoconer.yarnConditioning
    ) {
      return false;
    }

    // Check others
    if (!others.yarnConditioning) {
      return false;
    }

    return true;
  };

  const validateMandatoryParameters = () => {
    if (partyName === "" && partyCode === "" && variety === "") {
      return false;
    }

    return true; // Return true if all checks pass
  };

  const handleSave = (event) => {
    event.preventDefault();
    // call the api to save

    if (submittedPartyCodes?.includes(partyCode)) {
      handleToastMsg("Error: Party Code already submitted!", "error");
      return;
    }

    if (validateMandatoryParameters()) {
      const payload = {
        partyName,
        partyCode,
        variety,
        general,
        blowroom,
        carding,
        drawing,
        simplex,
        spinning,
        autoconer,
        others,
      };

      if (editData) {
        // update directly
        updateSavedMutation.mutate(payload);
        // updateSavedMutation.mutate({ id: editData.id, data: payload });
        return;
      } else if (savedPartyCodes.includes(partyCode)) {
        updateSavedMutation.mutate(payload);
        // updateSavedMutation.mutate({ id: editData.id, data: payload });
        // handleToastMsg("Updated (already saved) Successfully!", "success");
      } else {
        saveMutation.mutate(payload);
      }
    } else {
      handleToastMsg(
        "Please provide party name, variety and party code",
        "error"
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (editData) {
      if (validateAllParameters()) {
        const payload = {
          partyName,
          partyCode,
          variety,
          general,
          blowroom,
          carding,
          drawing,
          simplex,
          spinning,
          autoconer,
          others,
        };
        // update directly
        updateSubmittedMutation.mutate(payload);
        // handleToastMsg("Submitted successfully (edited)", "success");
      } else {
        handleToastMsg("Please fill all fields before submitting", "error");
      }
      return;
    } else if (submittedPartyCodes.includes(partyCode)) {
      handleToastMsg("Error: Party Code already exists!", "error");
      return;
    } else if (validateAllParameters()) {
      const payload = {
        partyName,
        partyCode,
        variety,
        general,
        blowroom,
        carding,
        drawing,
        simplex,
        spinning,
        autoconer,
        others,
      };
      if (savedPartyCodes.includes(partyCode)) {
        // submit, delete from saved, return
      }
      submitMutation.mutate(payload);
      // handleToastMsg("Submitted successfully", "success");
    } else {
      handleToastMsg("Please fill all fields before submitting", "error");
    }
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 0:
        return <GeneralMasterForm general={general} setGeneral={setGeneral} />;
      case 1:
        return (
          <BlowroomMasterForm blowroom={blowroom} setBlowroom={setBlowroom} />
        );
      case 2:
        return (
          <CardingMasterForm
            carding={carding}
            setCarding={setCarding}
            selectedCardingValues={selectedCardingValues}
            setSelectedCardingValues={setSelectedCardingValues}
          />
        );
      case 3:
        return <DrawingMasterForm drawing={drawing} setDrawing={setDrawing} />;
      case 4:
        return <SimplexMasterForm simplex={simplex} setSimplex={setSimplex} />;
      case 5:
        return (
          <SpinningMasterForm spinning={spinning} setSpinning={setSpinning} />
        );
      case 6:
        return (
          <AutoConerMasterForm
            autoconer={autoconer}
            setAutoconer={setAutoconer}
            autoConerPaperCone={autoConerPaperCone}
            setAutoConerPaperCone={setAutoConerPaperCone}
          />
        );
      case 7:
        return <OthersMasterForm others={others} setOthers={setOthers} />;
      default:
        return <GeneralMasterForm general={general} setGeneral={setGeneral} />;
    }
  };

  const renderButtons = () => {
    if (selectedTab === 7) {
      return (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            className="saveButton"
            color="primary"
            style={{
              // background: "rgb(165, 58, 189)",
              color: "white",
              textTransform: "none",
              marginRight: "2%",
            }}
            onClick={handleSave}
            disabled={updateSavedMutation.isLoading || saveMutation.isLoading}
          >
            {updateSavedMutation.isLoading || saveMutation.isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Save and Edit Later"
            )}
          </Button>
          <Button
            variant="contained"
            // className="submitButton"
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleSubmit}
            disabled={
              updateSubmittedMutation.isLoading || submitMutation.isLoading
            }
          >
            {updateSubmittedMutation.isLoading || submitMutation.isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      );
    } else {
      return (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            className="saveButton"
            color="primary"
            style={{
              // background: "rgb(165, 58, 189)",
              color: "white",
              textTransform: "none",
              marginRight: "2%",
            }}
            onClick={handleSave}
            disabled={updateSavedMutation.isLoading || saveMutation.isLoading}
          >
            {updateSavedMutation.isLoading || saveMutation.isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Save and Edit Later"
            )}
          </Button>
          <Button
            variant="contained"
            onClick={() => setSelectedTab(selectedTab + 1)}
            className="nextButton"
            style={{
              background: "rgb(68, 31, 200)",
              color: "white",
              textTransform: "none",
            }}
          >
            Next
          </Button>
        </Box>
      );
    }
  };

  return (
    <ContainerWrapper
      container
      item
      md={12}
      sm={12}
      xs={12}
      alignContent="flex-start"
      style={{ width: "50%" }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{
          marginTop: "1%",
          paddingBottom: "2%",
        }}
      >
        <h3>Add New Data</h3>
      </Grid>
      <Grid container item xs={12} className="upperGrid">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Party Name"
              value={partyName}
              onChange={(e) => setPartyName(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Variety"
              value={variety}
              onChange={(e) => setVariety(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Party Code"
              value={partyCode}
              onChange={(e) => setPartyCode(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "2%", width: "100%", overflowX: "auto" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          // centered={!isSmallScreen}
          // variant={isSmallScreen ? "scrollable" : "standard"}
          variant={"scrollable"}
          scrollButtons="auto"
        >
          {[
            "General",
            "Blowroom",
            "Carding",
            "Drawing",
            "Simplex",
            "Spinning",
            "Autoconer",
            "Others",
          ].map((label, index) => (
            <Tab
              key={index}
              label={label}
              style={{
                backgroundColor:
                  selectedTab === index
                    ? "rgb(175, 186, 250)"
                    : "rgb(233, 230, 244)",
                border:
                  selectedTab === index ? "2px solid gray" : "1px solid gray",
              }}
            />
          ))}
        </Tabs>
        <Box sx={{ mt: 4 }}>{renderComponent()}</Box>
        <Box sx={{ mt: 6 }}>{renderButtons()}</Box>
      </Box>
    </ContainerWrapper>
  );
}

export default AddParameterMaster;
