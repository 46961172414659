import React from "react";
import Navbar from "./Navbar";
import SidebarComponent from "./Sidebar";

function Layout() {
  return (
    <>
      <Navbar />
      <SidebarComponent />
      {/* <Outlet/> */}
     
    </>
  );
}

export default Layout;
