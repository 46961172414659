import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const GeneralMasterForm = ({ general, setGeneral }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    // setGeneral((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    if (name === "x" || name === "y") {
      setGeneral((prevState) => ({
        ...prevState,
        denierXCutLength: { ...prevState.denierXCutLength, [name]: value },
      }));
    } else {
      setGeneral((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Fiber Make</TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  name="fiberMake"
                  value={general.fiberMake}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Denier X Cut Length</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="first"
                    // value={denierCutFileds.first}
                    // onChange={handleDenierCutFileds}

                    name="x"
                    value={general.denierXCutLength.x}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="second"
                    // value={denierCutFileds.second}
                    // onChange={handleDenierCutFileds}

                    name="y"
                    value={general.denierXCutLength.y}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Merge No</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  variant="outlined"
                  name="mergeNo"
                  value={general.mergeNo}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default GeneralMasterForm;
