import React, { useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import ManagerBlowroomNotification from "../Blowroom/Manager/Notifications/ManagerNotification";
import ManagerAutoconerNotification from "../Blowroom/Autoconer/Manager/Notifications/ManageAutoNotification";
import ManagerSpinningNotification from "../Blowroom/Manager/Notifications/ManagerSpinningNotification";
import ManagerFinisherDrawingNotification from "../Blowroom/Manager/Notifications/ManagerFinisherDrawingNotification";
import { useQuery } from "react-query";
import {
  managerAutoconerNotification,
  managerBlowroomNotification,
  managerSpinningNotification,
} from "../Blowroom/mutation/manager/blowroomReportMutation";
import { managerFinisherDrawingNotification } from "../Blowroom/Manager/Notifications/ManagerFinisherDrawingNotification/mutation";
import { defaultSeconds } from "../../config/masterdata";
import BlowroomSettingManager from "../ManagerMachineSettingCorrection/BlowroomCorrection/BlowroomSettingManager";
import AutoconerSettingManager from "../ManagerMachineSettingCorrection/AutoconerCorrection/AutoconerSettingManager";
import SpinningSettingManager from "../ManagerMachineSettingCorrection/SpinningCorrection/SpinningSettingManager";
import DrawingSettingManager from "../ManagerMachineSettingCorrection/DrawingCorrection/DrawingSettingManager";
import GeneralSettingManager from "../ManagerMachineSettingCorrection/GeneralCorrection/GeneralSettingManager";
import OtherSettingManager from "../ManagerMachineSettingCorrection/OtherCorrection/OtherSettingManager";
import SimplexSettingManager from "../ManagerMachineSettingCorrection/SimplexCorrection/SimplexSettingManager";
import CardingSettingManager from "../ManagerMachineSettingCorrection/CardingCorrection/CardingSettingManager";
import { getAllNotificationData } from "../ManagerMachineSettingCorrection/Mutation/mutation";
import { getReportIssueData } from "../TaskSheet/mutation";
import TaskSheetManager from "../TaskSheetManager";

const Television = () => {
  // useMemo to memoize userModules
  const userModules = useMemo(() => {
    const allModules = JSON.parse(localStorage.getItem("userModules")) || [];
    allModules.push("warning");

    return allModules;
  }, []);

  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);

  const [secondsForFirstInterval, setSecondsForFirstInterval] =
    useState(defaultSeconds);
  const [pauseInterval, setPauseInterval] = useState(false);

  //this is for all the report issue data(Task sheets)
  const {
    data: reportIssueData,
    isLoading: isReportDataLoading,
    isError: isReportDataError,
    refetch: reportsIssueRefetch,
  } = useQuery("task-sheets-report-issue", () => getReportIssueData(), {
    refetchOnMount: true,
  });

  //this is for all the machine settings data
  const [isMachineSettingLoadingData, setIsMachineSettingLoadingData] =
    useState(false);
  const {
    data: allNotificationData,
    isLoading: isMachineSettingLoading,
    isError: isMachineSettingError,
    refetch: machineSettingRefetch,
  } = useQuery(
    "all-notification-data",
    async () => {
      setIsMachineSettingLoadingData(true);
      try {
        const response = await getAllNotificationData();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        setIsMachineSettingLoadingData(false);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //handle all the machine settings notifications to show in ui
  const [isMachineSettingsData, setIsMachineSettingsData] = useState([]);
  useEffect(() => {
    if (allNotificationData) {
      const allKeys = Object.keys(allNotificationData?.data);
      const machineSettingsData = allKeys?.map((key) => ({
        key,
        isData: allNotificationData?.data[key]?.length > 0,
      }));
      setIsMachineSettingsData(machineSettingsData);
    }
  }, [allNotificationData]);

  //all the notifications api
  //blowroom notification api
  const [isBlowroomLoadingData, setIsBlowroomLoadingData] = useState(false);
  const {
    data: managerBlowroomNotifications,
    isLoading: isBlowroomLoading,
    isError: isBlowroomError,
    refetch: blowroomRefetch,
  } = useQuery(
    "manager-blowroom-notifications",
    async () => {
      setIsBlowroomLoadingData(true);
      try {
        const response = await managerBlowroomNotification();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        setIsBlowroomLoadingData(false);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //autoconer notification api
  const [isAutoconerLoadingData, setIsAutoconerLoadingData] = useState(false);
  const {
    data: managerAutoconerNotifications,
    isLoading: isAutoconerLoading,
    isError: isAutoconerError,
    refetch: autoconerRefetch,
  } = useQuery(
    "manager-autoconer-notifications",
    async () => {
      setIsAutoconerLoadingData(true);
      try {
        const response = await managerAutoconerNotification();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        setIsAutoconerLoadingData(false);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //spinning notification api
  const [isSpinningLoadingData, setIsSpinningLoadingData] = useState(false);
  const {
    data: managerSpinningNotifications,
    isLoading: isSpinningLoading,
    isError: isSpinningError,
    refetch: spinningRefetch,
  } = useQuery(
    "manager-spinning-notifications",
    async () => {
      setIsSpinningLoadingData(true);
      try {
        const response = await managerSpinningNotification();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        setIsSpinningLoadingData(false);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //finisher drawing notification api
  const [isFinisherDrawingLoadingData, setIsFinisherDrawingLoadingData] =
    useState(false);
  const {
    data: managerFinisherDrawingNotifications,
    isLoading: isFinisherDrawingLoading,
    isError: isFinisherDrawingError,
    refetch: finisherDrawingRefetch,
  } = useQuery(
    "manager-finisher-drawing-notifications",
    async () => {
      setIsFinisherDrawingLoadingData(true);
      try {
        const response = await managerFinisherDrawingNotification();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        setIsFinisherDrawingLoadingData(false);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    blowroomRefetch();
    autoconerRefetch();
    spinningRefetch();
    finisherDrawingRefetch();
    machineSettingRefetch();
    reportsIssueRefetch();
  }, [
    autoconerRefetch,
    blowroomRefetch,
    finisherDrawingRefetch,
    machineSettingRefetch,
    reportsIssueRefetch,
    spinningRefetch,
  ]);

  const [isBlowroomData, setIsBlowroomData] = useState(false);
  const [isAutoconerData, setIsAutoconerData] = useState(false);
  const [isSpinningData, setIsSpinningData] = useState(false);
  const [isFinisherDrawingData, setIsFinisherDrawingData] = useState(false);
  //handle all the notifications to show in ui
  useEffect(() => {
    //check if there is data in the blowroom notification api
    if (managerBlowroomNotifications) {
      setIsBlowroomData(
        Object.keys(managerBlowroomNotifications?.data).length > 0
      );
    }

    //check if there is data in the autoconer notification api
    if (managerAutoconerNotifications) {
      setIsAutoconerData(
        Object.keys(managerAutoconerNotifications?.data).length > 0
      );
    }

    //check if there is data in the spinning notification api
    if (managerSpinningNotifications) {
      setIsSpinningData(
        Object.keys(managerSpinningNotifications?.data).length > 0
      );
    }

    //check if there is data in the finisher drawing notification api
    if (managerFinisherDrawingNotifications) {
      setIsFinisherDrawingData(
        Object.keys(managerFinisherDrawingNotifications?.data).length > 0
      );
    }
  }, [
    managerAutoconerNotifications,
    managerBlowroomNotifications,
    managerFinisherDrawingNotifications,
    managerSpinningNotifications,
  ]);

  const modulesWithData = useMemo(() => {
    return userModules?.filter((module) => {
      switch (module) {
        case "blow_room":
          return (
            isBlowroomData ||
            isMachineSettingsData?.some(
              (item) => item.key === "BlowroomMaster" && item.isData === true
            )
          );
        case "autoconer":
          return (
            isAutoconerData ||
            isMachineSettingsData?.some(
              (item) => item.key === "AutoconerMaster" && item.isData === true
            )
          );
        case "spinning":
          return (
            isSpinningData ||
            isMachineSettingsData?.some(
              (item) => item.key === "SpinningMaster" && item.isData === true
            )
          );
        case "finisher_drawing":
          return (
            isFinisherDrawingData ||
            isMachineSettingsData?.some(
              (item) => item.key === "DrawingMaster" && item.isData === true
            )
          );

        case "general":
          return isMachineSettingsData?.some(
            (item) => item.key === "GeneralMaster" && item.isData === true
          );

        case "others":
          return isMachineSettingsData?.some(
            (item) => item.key === "OthersMaster" && item.isData === true
          );

        case "simplex_checking":
          return isMachineSettingsData?.some(
            (item) => item.key === "SimplexMaster" && item.isData === true
          );

        case "carding":
          return isMachineSettingsData?.some(
            (item) => item.key === "CardingMaster" && item.isData === true
          );

        case "task_sheets":
          const isPendingData = reportIssueData?.data?.filter(
            (item) => item.status === "PENDING"
          );

          return isPendingData?.length > 0 || false;

        case "warning":
          return true;
        default:
          return false;
      }
    });
  }, [
    userModules,
    isBlowroomData,
    isMachineSettingsData,
    isAutoconerData,
    isSpinningData,
    isFinisherDrawingData,
    reportIssueData?.data,
  ]);

  const [showFirstComponent, setShowFirstComponent] = useState(true);

  useEffect(() => {
    let newInterval = defaultSeconds;

    // Determine the interval duration based on the current module and its data status
    const currentModule = modulesWithData[currentModuleIndex];

    // newInterval = secondsForFirstInterval - defaultSeconds;
    switch (currentModule) {
      case "blow_room":
        newInterval = isMachineSettingsData?.some(
          (item) => item.key === "BlowroomMaster" && item.isData === true
        )
          ? secondsForFirstInterval - defaultSeconds
          : secondsForFirstInterval;
        break;
      case "autoconer":
        newInterval = isMachineSettingsData?.some(
          (item) => item.key === "AutoconerMaster" && item.isData === true
        )
          ? secondsForFirstInterval - defaultSeconds
          : secondsForFirstInterval;
        break;
      case "spinning":
        newInterval = isMachineSettingsData?.some(
          (item) => item.key === "SpinningMaster" && item.isData === true
        )
          ? secondsForFirstInterval - defaultSeconds
          : secondsForFirstInterval;
        break;
      case "finisher_drawing":
        newInterval = isMachineSettingsData?.some(
          (item) => item.key === "DrawingMaster" && item.isData === true
        )
          ? secondsForFirstInterval - defaultSeconds
          : secondsForFirstInterval;
        break;
      case "general":
        if (
          isMachineSettingsData.some(
            (item) => item.key === "GeneralMaster" && item.isData
          )
        ) {
          setSecondsForFirstInterval(defaultSeconds);
        }
        return;

      case "others":
        if (
          isMachineSettingsData.some(
            (item) => item.key === "OthersMaster" && item.isData
          )
        ) {
          setSecondsForFirstInterval(defaultSeconds);
        }
        return;

      case "simplex_checking":
        if (
          isMachineSettingsData.some(
            (item) => item.key === "SimplexMaster" && item.isData
          )
        ) {
          setSecondsForFirstInterval(defaultSeconds);
        }
        return;

      case "carding":
        if (
          isMachineSettingsData.some(
            (item) => item.key === "CardingMaster" && item.isData
          )
        ) {
          setSecondsForFirstInterval(defaultSeconds);
        }
        return;

      case "task_sheets":
        newInterval = secondsForFirstInterval;
        break;

      case "warning":
        setSecondsForFirstInterval(defaultSeconds);
        return;
      default:
        newInterval = secondsForFirstInterval;
        break;
    }
    if (!showFirstComponent) {
      newInterval = defaultSeconds;
    }

    //this is for to set the order for notification data showing and then machine setting data showing//
    const interval = setInterval(() => {
      if (!pauseInterval) {
        setShowFirstComponent(
          (prevShowFirstComponent) => !prevShowFirstComponent
        );
      }
    }, newInterval);

    return () => clearInterval(interval);
  }, [
    currentModuleIndex,
    isMachineSettingsData,
    modulesWithData,
    secondsForFirstInterval,
    showFirstComponent,
    isBlowroomData,
    isAutoconerData,
    isSpinningData,
    isFinisherDrawingData,
    pauseInterval,
    reportIssueData?.data,
  ]);

  //this is for refetch when the module is warning
  useEffect(() => {
    const currentModule =
      modulesWithData[currentModuleIndex % modulesWithData.length];

    if (currentModule === "warning") {
      machineSettingRefetch();
      blowroomRefetch();
      autoconerRefetch();
      spinningRefetch();
      finisherDrawingRefetch();
      reportsIssueRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModuleIndex]);

  //this is the setInterval for show the data in ui
  useEffect(() => {
    const moduleInterval = setInterval(() => {
      if (!pauseInterval) {
        setCurrentModuleIndex(
          (prevIndex) => (prevIndex + 1) % modulesWithData.length
        );
        setShowFirstComponent(true); // Reset to show the first component when module changes
      }
    }, secondsForFirstInterval); // Total duration for showing both components

    return () => clearInterval(moduleInterval);
  }, [modulesWithData, pauseInterval, secondsForFirstInterval, userModules]);

  //handle loading and error
  if (
    isBlowroomLoading ||
    isAutoconerLoading ||
    isSpinningLoading ||
    isFinisherDrawingLoading ||
    isBlowroomLoadingData ||
    isAutoconerLoadingData ||
    isSpinningLoadingData ||
    isFinisherDrawingLoadingData ||
    isMachineSettingLoadingData ||
    isMachineSettingLoading ||
    isReportDataLoading
  ) {
    return (
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (
    isBlowroomError ||
    isAutoconerError ||
    isSpinningError ||
    isFinisherDrawingError ||
    isMachineSettingError ||
    isReportDataError
  ) {
    return (
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <p>Error loading data</p>
      </Grid>
    );
  }

  // Render the current module based on module
  const renderCurrentModule = (module) => {
    if (module === "blow_room") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">BLOWROOM</h2>
          </Grid>
          <Grid item xs={12}>
            {isBlowroomData && showFirstComponent ? (
              <Grid item xs={12}>
                {" "}
                <ManagerBlowroomNotification
                  setSecondsForFirstInterval={setSecondsForFirstInterval}
                  isMachineSettingsData={isMachineSettingsData}
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                  pauseInterval={pauseInterval}
                />
              </Grid>
            ) : (
              !showFirstComponent &&
              isMachineSettingsData?.some(
                (item) => item.key === "BlowroomMaster" && item.isData
              ) && (
                <Grid item xs={12}>
                  <BlowroomSettingManager
                    isTelevision={true}
                    setPauseInterval={setPauseInterval}
                  />
                </Grid>
              )
            )}
          </Grid>
        </>
      );
    } else if (module === "autoconer") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">AUTOCONER</h2>
          </Grid>
          <Grid item xs={12}>
            {isAutoconerData && showFirstComponent ? (
              <Grid item xs={12}>
                {" "}
                <ManagerAutoconerNotification
                  setSecondsForFirstInterval={setSecondsForFirstInterval}
                  isMachineSettingsData={isMachineSettingsData}
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                  pauseInterval={pauseInterval}
                />
              </Grid>
            ) : (
              !showFirstComponent &&
              isMachineSettingsData?.some(
                (item) => item.key === "AutoconerMaster" && item.isData
              ) && (
                <Grid item xs={12}>
                  <AutoconerSettingManager
                    isTelevision={true}
                    setPauseInterval={setPauseInterval}
                  />
                </Grid>
              )
            )}
          </Grid>
        </>
      );
    } else if (module === "spinning") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">SPINNING</h2>
          </Grid>
          <Grid item xs={12}>
            {isSpinningData && showFirstComponent ? (
              <Grid item xs={12}>
                {" "}
                <ManagerSpinningNotification
                  setSecondsForFirstInterval={setSecondsForFirstInterval}
                  isMachineSettingsData={isMachineSettingsData}
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                  pauseInterval={pauseInterval}
                />
              </Grid>
            ) : (
              !showFirstComponent &&
              isMachineSettingsData?.some(
                (item) => item.key === "SpinningMaster" && item.isData
              ) && (
                <Grid item xs={12}>
                  <SpinningSettingManager
                    isTelevision={true}
                    setPauseInterval={setPauseInterval}
                  />
                </Grid>
              )
            )}
          </Grid>
        </>
      );
    } else if (module === "finisher_drawing") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">FINISHER DRAWING</h2>
          </Grid>
          <Grid item xs={12}>
            {isFinisherDrawingData && showFirstComponent ? (
              <Grid item xs={12}>
                {" "}
                <ManagerFinisherDrawingNotification
                  setSecondsForFirstInterval={setSecondsForFirstInterval}
                  isMachineSettingsData={isMachineSettingsData}
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                  pauseInterval={pauseInterval}
                />
              </Grid>
            ) : (
              !showFirstComponent &&
              isMachineSettingsData?.some(
                (item) => item.key === "DrawingMaster" && item.isData
              ) && (
                <Grid item xs={12}>
                  <DrawingSettingManager
                    isTelevision={true}
                    setPauseInterval={setPauseInterval}
                  />
                </Grid>
              )
            )}
          </Grid>
        </>
      );
    } else if (module === "general") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">GENERAL</h2>
          </Grid>
          <Grid item xs={12}>
            {isMachineSettingsData?.some(
              (item) => item.key === "GeneralMaster" && item.isData
            ) && (
              <Grid item xs={12}>
                <GeneralSettingManager
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    } else if (module === "others") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">OTHERS</h2>
          </Grid>
          <Grid item xs={12}>
            {isMachineSettingsData?.some(
              (item) => item.key === "OthersMaster" && item.isData
            ) && (
              <Grid item xs={12}>
                <OtherSettingManager
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    } else if (module === "simplex_checking") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">SIMPLEX CHECKING</h2>
          </Grid>
          <Grid item xs={12}>
            {isMachineSettingsData?.some(
              (item) => item.key === "SimplexMaster" && item.isData
            ) && (
              <Grid item xs={12}>
                <SimplexSettingManager
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    } else if (module === "carding") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">CARDING</h2>
          </Grid>
          <Grid item xs={12}>
            {isMachineSettingsData?.some(
              (item) => item.key === "CardingMaster" && item.isData
            ) && (
              <Grid item xs={12}>
                <CardingSettingManager
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    } else if (module === "task_sheets") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <h2 className="heading">Task Sheets</h2>
          </Grid>
          <Grid item xs={12}>
            {reportIssueData?.data?.filter((item) => item.status === "PENDING")
              .length > 0 && (
              <Grid item xs={12}>
                <TaskSheetManager
                  setSecondsForFirstInterval={setSecondsForFirstInterval}
                  isTelevision={true}
                  setPauseInterval={setPauseInterval}
                  pauseInterval={pauseInterval}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    } else if (module === "warning") {
      return (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            <Grid container item xs={12}>
              <Grid container item xs={12} justifyContent={"center"}>
                <h2 className="heading" style={{ color: "red" }}>
                  WARNING
                </h2>
              </Grid>

              <Grid container item xs={12} justifyContent={"center"}>
                <p
                  style={{
                    fontSize: "25px",
                    padding: "20px",
                    textAlign: "justify",
                  }}
                >
                  Bring to your attention a matter that requires immediate
                  correction to prevent potential problems. Your prompt action
                  is appreciated.
                </p>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={12} justifyContent={"center"}>
                <h2 className="heading" style={{ color: "red" }}>
                  எச்சரிக்கை
                </h2>
              </Grid>

              <Grid container item xs={12} justifyContent={"center"}>
                <p
                  style={{
                    fontSize: "25px",
                    padding: "20px",
                    textAlign: "justify",
                  }}
                >
                  சாத்தியமான சிக்கல்களைத் தடுக்க, உடனடி திருத்தம் தேவைப்படும்
                  விஷயத்தை உங்கள் கவனத்திற்குக் கொண்டு வருகிறோம் . உங்கள்
                  விரைவான நடவடிக்கை பாராட்டத்தக்கது.
                </p>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={12} justifyContent={"center"}>
                <h2 className="heading" style={{ color: "red" }}>
                  चेतावनी
                </h2>
              </Grid>

              <Grid container item xs={12} justifyContent={"center"}>
                <p
                  style={{
                    fontSize: "25px",
                    padding: "20px",
                    textAlign: "justify",
                  }}
                >
                  संभावित समस्याओं को रोकने के लिए, हम आपके ध्यान में एक ऐसा
                  मामला लाते हैं जिसमें तत्काल सुधार की आवश्यकता है। आपकी त्वरित
                  कार्रवाई की सराहना की जाती है.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <Grid container item xs={12} alignContent={"flex-start"} mt={2}>
      {renderCurrentModule(modulesWithData[currentModuleIndex])}
    </Grid>
  );
};

export default Television;
