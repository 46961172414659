import apiClient from "../../../utils/clients/axios";

// Create a new record in the others master
export const submitOthersData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/others_master`,
    data
  );
  return response;
};

// Get all records from the others master
export const getAllOthersSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/others_master`);
  return response;
};

// Get all drafts records from the others master
export const getAllOthersDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/others_master/getAllOthersMastersDraft`
  );
  return response;
};

// Get a specific record from the others master by ID
export const getOtherMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/others_master/${id}`
  );
  return response;
};

// Update a specific record in the others master by ID
export const updateOtherMasterRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/others_master/${id}`,
    data
  );
  return response;
};

// Create a new record in the blowroom master
export const saveOthersDraftsData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/others_master/createOthersMasterDraft`,
    payload
  );
  return response;
};

// update a new record in the blowroom master
export const updateOthersDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/others_master/updateOthersMasterDraft/${id}`,
    payload
  );
  return response;
};

// Delete a specific record from the others master by ID
export const deleteOthersMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/others_master/${id}`
  );
  return response;
};

// update manager(Respond)
export const managerUpdateOthersData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/others_master/${id}`,
    payload
  );
  return response;
};

// Delete a specific draft record from the others master by ID
export const deleteOthersDraftMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/others_master/deleteOthersMasterDraft/${id}`
  );
  return response;
};
