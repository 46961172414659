import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

const SpinningMasterForm = ({ spinning, setSpinning }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [firstValue, setFirstValue] = useState(spinning.frameNo[0] || "");
  const [secondValue, setSecondValue] = useState(
    spinning.frameNo[spinning.frameNo.length - 1] || ""
  );
  // const [numberArray, setNumberArray] = useState([]);

  const handleFirstChange = (event) => {
    const { value } = event.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setFirstValue(value);
  };

  const handleSecondChange = (event) => {
    const { value } = event.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setSecondValue(value);
  };

  useEffect(() => {
    if (firstValue && secondValue && firstValue < secondValue) {
      const start = parseInt(firstValue, 10);
      const end = parseInt(secondValue, 10);
      const array = [];
      for (let i = start; i <= end; i++) {
        array.push(i);
      }
      // setNumberArray(array);
      setSpinning((prevState) => ({
        ...prevState,
        frameNo: array,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstValue, secondValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    // setSpinning((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    const [field, subfield] = name.split(".");

    if (subfield) {
      setSpinning((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          [subfield]: value,
        },
      }));
    } else {
      setSpinning((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      {/*  */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Frame No</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="firstValue"
                    variant="outlined"
                    value={firstValue}
                    // value={spinning.frameNo[0] || ""}
                    onChange={handleFirstChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    to
                  </span>{" "}
                  <TextField
                    type="number"
                    name="secondValue"
                    variant="outlined"
                    value={secondValue}
                    // value={spinning.frameNo[spinning.frameNo.length - 1] || ""}
                    onChange={handleSecondChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom Roll Setting</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="first"
                    // value={spinningBottomRollFields.first}
                    // onChange={handleBottomRollFields}
                    name="bottomRollSetting.x"
                    value={spinning.bottomRollSetting.x}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                    // value={spinning.bottomRollSetting}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="second"
                    // value={spinningBottomRollFields.second}
                    // onChange={handleBottomRollFields}
                    name="bottomRollSetting.y"
                    value={spinning.bottomRollSetting.y}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                    // value={spinning.bottomRollSetting}
                  />
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="third"
                    // value={spinningBottomRollFields.third}
                    // onChange={handleBottomRollFields}
                    name="bottomRollSetting.z"
                    value={spinning.bottomRollSetting.z}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                    // value={spinning.bottomRollSetting}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Top Roll Setting</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="first"
                    // value={spinningTopRollFields.first}
                    // onChange={handleTopRollFields}
                    name="topRollSetting.x"
                    value={spinning.topRollSetting.x}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="second"
                    // value={spinningTopRollFields.second}
                    // onChange={handleTopRollFields}
                    name="topRollSetting.y"
                    value={spinning.topRollSetting.y}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="third"
                    // value={spinningTopRollFields.third}
                    // onChange={handleTopRollFields}
                    name="topRollSetting.z"
                    value={spinning.topRollSetting.z}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="bd"
                  variant="outlined"
                  value={spinning.bd}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Draft</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="totalDraft"
                  variant="outlined"
                  value={spinning.totalDraft}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TPI/TM</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="tpiTm"
                  variant="outlined"
                  value={spinning.tpiTm}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Spacer</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="spacer"
                  variant="outlined"
                  value={spinning.spacer}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Traveller</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="traveller"
                  variant="outlined"
                  value={spinning.traveller}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="speed"
                    variant="outlined"
                    value={spinning.speed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lycra details make</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="lycraDetailsMake"
                  variant="outlined"
                  value={spinning.lycraDetailsMake}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lycra Draft</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="lycraDraft"
                  variant="outlined"
                  value={spinning.lycraDraft}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cal.Lycra %</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="calLycraPercentage"
                    variant="outlined"
                    value={spinning.calLycraPercentage}
                    onChange={handleChange}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    %
                  </span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Act. Lycra %</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="actLycraPercentage"
                    variant="outlined"
                    value={spinning.actLycraPercentage}
                    onChange={handleChange}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    %
                  </span>{" "}
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SpinningMasterForm;
