import apiClient from "../../../utils/clients/axios";

// Create a new record in the spinning master
export const submitSpinningData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/spinning_master`,
    data
  );
  return response;
};

// Create a new draft record in the spinning master
export const saveSpinningDraftsData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/spinning_master/createSpinningMasterDraft`,
    data
  );
  return response;
};

// put general drafts records
export const updateSpinningDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/spinning_master/updateSpinningMasterDraft/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateSpinningData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/spinning_master/${id}`,
    payload
  );
  return response;
};

// Get all records from the spinning master
export const getAllSpinningSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/spinning_master`);
  return response;
};

// Get all draft records from the spinning master
export const getAllSpinningDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/spinning_master/getAllSpinningMastersDraft`
  );
  return response;
};

// Get a specific record from the spinning master by ID
export const getSpinningMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/spinning_master/${id}`
  );
  return response;
};

// Update a specific record in the spinning master by ID
export const updateSpinningMasterRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/spinning_master/${id}`,
    data
  );
  return response;
};

// Delete a specific record from the spinning master by ID
export const deleteSpinningMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/spinning_master/${id}`
  );
  return response;
};

// Delete a specific draft record from the spinning master by ID
export const deleteSpinningDraftMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/spinning_master/deleteSpinningMasterDraft/${id}`
  );
  return response;
};
