import apiClient from "../../../utils/clients/axios";

// Create a new record in the carding master
export const submitCardingData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/carding_master`,
    payload
  );
  return response;
};

// Get all records from the carding master
export const getAllCardingSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/carding_master`);
  return response;
};

// Get all draft records from the carding master
export const getAllCardingDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/carding_master/getAllCardingMastersDraft`
  );
  return response;
};

// Delete a specific record from the carding master by ID
export const deleteCardingMasterData = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/carding_master/${id}`
  );
  return response;
};

// Delete a specific draft record from the carding master by ID
export const deleteCardingDraftMasterData = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/carding_master/deleteCardingMasterDraft/${id}`
  );
  return response;
};

// Create a new record in the carding master
export const saveCardingDraftsData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/carding_master/createCardingMasterDraft`,
    payload
  );
  return response;
};

// update a new record in the carding master
export const updateCardingDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/carding_master/updateCardingMasterDraft/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateCardingData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/carding_master/${id}`,
    payload
  );
  return response;
};

// Get a specific record from the carding master by ID
export const getCardingMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/carding_master/${id}`
  );
  return response;
};

// Update a specific record in the carding master by ID
export const updateCardingMasterRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/carding_master/${id}`,
    data
  );
  return response;
};
