import apiClient from "../../../utils/clients/axios";

// Create a new record in the simplex master
export const submitSimplexData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/simplex_master`,
    data
  );
  return response;
};

// Create a new record in the draft simplex master
export const saveSimplexDraftsData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/simplex_master/createSimplexMasterDraft`,
    data
  );
  return response;
};

// Get all records from the simplex master
export const getAllSimplexSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/simplex_master`);
  return response;
};

// Get all records from the simplex master
export const getAllSimplexDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/simplex_master/getAllSimplexMastersDraft`
  );
  return response;
};

// Get a specific record from the simplex master by ID
export const getSimplexMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/simplex_master/${id}`
  );
  return response;
};

// Update a specific record in the simplex master by ID
export const updateSimplexDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/simplex_master/updateSimplexMasterDraft/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateSimplexData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/simplex_master/${id}`,
    payload
  );
  return response;
};

// Delete a specific record from the simplex master by ID
export const deleteSimplexMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/simplex_master/${id}`
  );
  return response;
};

// Delete a specific record from the simplex master by ID
export const deleteSimplexDraftMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/simplex_master/deleteSimplexMasterDraft/${id}`
  );
  return response;
};
