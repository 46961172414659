import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
} from "@material-ui/core";
import { MACHINE_SETTINGS_CARDING_NO } from "../config/masterdata";

const CardingMasterForm = ({
  carding,
  setCarding,
  selectedCardingValues,
  setSelectedCardingValues,
}) => {
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedCardingValues(value);
    setCarding((prevState) => ({
      ...prevState,
      cardingNo: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setCarding((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      {/* {selectedCardingValues.length > 0 && (
        <div>Selected Values: {selectedCardingValues.join(", ")}</div>
      )} */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Carding No</TableCell>
              <TableCell>
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  {/* <InputLabel id="multiple-select-label">Select Numbers</InputLabel> */}
                  <Select
                    labelId="multiple-select-label"
                    multiple
                    value={carding.cardingNo}
                    onChange={handleSelectChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {MACHINE_SETTINGS_CARDING_NO?.map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          checked={selectedCardingValues.indexOf(value) > -1}
                        />
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lickerin Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="likerInSpeed"
                    variant="outlined"
                    value={carding.likerInSpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cylinder Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="cylinderSpeed"
                    variant="outlined"
                    value={carding.cylinderSpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Flat Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="flatSpeed"
                    variant="outlined"
                    value={carding.flatSpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MM/MIN</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="deliverySpeed"
                    variant="outlined"
                    value={carding.deliverySpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Hank</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="deliveryHank"
                  variant="outlined"
                  value={carding.deliveryHank}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Draft</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="draft"
                  variant="outlined"
                  value={carding.draft}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default CardingMasterForm;
