import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { ContainerWrapper } from "../../components/styles";
import DashboardCard from "../../components/DashboardCard";
import Dialog from "@mui/material/Dialog";
import { MenuItem, Grid } from "@mui/material";

function Dashboard() {
  const [dashboardSubtitle, setDashboardSubtitle] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [userModules, setUserModules] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const dashboardMenu = JSON.parse(localStorage.getItem("dashboardMenu")) || [];
  const role = localStorage.getItem("roles") || "";

  const userSubModules = useMemo(
    () => JSON.parse(localStorage.getItem("userSubModules")) || [],
    []
  );

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchUserModules = async () => {
      try {
        const userModulesData =
          JSON.parse(localStorage.getItem("userModules")) || [];
        setUserModules(userModulesData);
        updateSubtitles(userModulesData, userSubModules);
      } catch (error) {
        throw error;
      }
    };

    fetchUserModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubModules]);

  useEffect(() => {
    updateSubtitles(userModules, userSubModules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuItem, userModules, userSubModules]);

  const updateSubtitles = (userModulesData, userSubModules) => {
    const menu = dashboardMenu?.find((item) => item.link === activeMenuItem);

    if (role === "MANAGING_DIRECTOR") {
      if (menu) {
        if (menu.menu === "Notifications") {
          setDashboardSubtitle([
            { menutitle: "Notifications" },
            {
              menu: "All notifications",
              link: "md-notifications",
            },
          ]);
        }

        if (menu.menu === "Task Sheets") {
          setDashboardSubtitle([
            { menutitle: "Task Sheets" },
            {
              menu: "All Task Sheets",
              link: "md-task-sheets",
            },
          ]);
        }
      }

      // setDashboardSubtitle([
      //   { menutitle: "Notifications" },
      //   {
      //     menu: "All notifications",
      //     link: "md-notifications",
      //   },

      //   { menutitle: "Task Sheets" },
      //   {
      //     menu: "All Task Sheets",
      //     link: "md-notifications",
      //   },
      // ]);

      return;
    }

    if (menu) {
      switch (menu.menu) {
        case "Blowroom": {
          let blowroomsubmenu = [];
          if (userSubModules.includes("blow_room_machine_settings")) {
            blowroomsubmenu.push({
              menu: "Blowroom Machine Settings",
              link: "blowroom-machine-setting",
            });
          }
          if (userSubModules.includes("lap_feed")) {
            blowroomsubmenu.push({
              menu: "Lap Feed Waste",
              link: "lap-feed-waste",
            });
          }
          if (userSubModules.includes("chute_feed_cotton")) {
            blowroomsubmenu.push({
              menu: "Chute Feed waste (Cotton)",
              link: "chute-feed-waste",
            });
          }
          if (userSubModules.includes("chute_feed_others")) {
            blowroomsubmenu.push({
              menu: "Chute Feed waste (Others)",
              link: "chute-feed-waste-others",
            });
          }
          if (userSubModules.includes("drop_test")) {
            blowroomsubmenu.push({ menu: "Drop Test", link: "drop-test" });
          }

          setDashboardSubtitle([{ menutitle: "Blowroom" }, ...blowroomsubmenu]);
          break;
        }

        case "Carding": {
          let cardingsubmenu = [];

          if (userSubModules.includes("carding_machine_settings")) {
            cardingsubmenu.push({
              menu: "Carding Machine Setting",
              link: "carding-machine-setting",
            });
          }
          if (userSubModules.includes("individual_carding_waste")) {
            cardingsubmenu.push({
              menu: "Individual Carding Waste",
              link: "individual-carding-waste",
            });
          }
          if (userSubModules.includes("auto_leveller_on/off")) {
            cardingsubmenu.push({
              menu: "Auto Leveller ON/Off",
              link: "auto-leveller-on/off",
            });
          }

          setDashboardSubtitle([{ menutitle: "Carding" }, ...cardingsubmenu]);
          break;
        }

        case "Autoconer": {
          let autoconersubmenu = [];

          if (userSubModules.includes("autoconer_machine_settings")) {
            autoconersubmenu.push({
              menu: "Autoconer Machine Setting",
              link: "autoconer-machine-setting",
            });
          }
          if (userSubModules.includes("splice_strength")) {
            autoconersubmenu.push({
              menu: "Splice Strength",
              link: "splice-strength-checking",
            });
          }
          if (userSubModules.includes("paper_cone_identification")) {
            autoconersubmenu.push({
              menu: "Paper Cone Identification",
              link: "paper-cone-identification",
            });
          }
          if (userSubModules.includes("ln_checking")) {
            autoconersubmenu.push({ menu: "LN checking", link: "ln-checking" });
          }
          if (userSubModules.includes("rewinding_study")) {
            autoconersubmenu.push({
              menu: "Rewinding Study",
              link: "rewinding-study",
            });
          }
          if (userSubModules.includes("trail")) {
            autoconersubmenu.push({ menu: "Trail", link: "trail" });
          }
          if (userSubModules.includes("u_percent_trail")) {
            autoconersubmenu.push({ menu: "U%", link: "u-percent-trail" });
          }
          setDashboardSubtitle([
            { menutitle: "Autoconer" },
            ...autoconersubmenu,
          ]);
          break;
        }

        case "Spinning": {
          let spinningsubmenu = [];

          if (userSubModules.includes("spinning_machine_settings")) {
            spinningsubmenu.push({
              menu: "Spinning Machine Setting",
              link: "spinning-machine-setting",
            });
          }
          if (userSubModules.includes("count_change")) {
            spinningsubmenu.push({
              menu: "Count Change",
              link: "count-change-record",
            });
          }
          if (userSubModules.includes("cot_checking")) {
            spinningsubmenu.push({
              menu: "Cot Checking",
              link: "cot-checking",
            });
          }
          if (userSubModules.includes("hariness")) {
            spinningsubmenu.push({ menu: "Hariness", link: "hariness" });
          }
          if (userSubModules.includes("apron_spacer_condenser")) {
            spinningsubmenu.push({
              menu: "Apron, Spacer & Condenser",
              link: "apron",
            });
          }
          setDashboardSubtitle([{ menutitle: "Spinning" }, ...spinningsubmenu]);
          break;
        }

        case "Lap": {
          let lapsubmenu = [];

          if (userSubModules.includes("sliver_lap")) {
            lapsubmenu.push({ menu: "Sliver Lap", link: "sliver-lap" });
          }
          if (userSubModules.includes("ribbon_lap")) {
            lapsubmenu.push({ menu: "Ribbon Lap", link: "ribbon-lap" });
          }
          if (userSubModules.includes("structure_lap")) {
            lapsubmenu.push({ menu: "Structure Lap", link: "structure-lap" });
          }

          setDashboardSubtitle([{ menutitle: "Lap" }, ...lapsubmenu]);
          break;
        }

        case "Finisher Drawing": {
          let finishersubmenu = [];

          if (userSubModules.includes("finisher_drawing_machine_settings")) {
            finishersubmenu.push({
              menu: "Drawing Machine Setting",
              link: "drawing-machine-setting",
            });
          }
          if (userSubModules.includes("finisher_drawing_yard")) {
            finishersubmenu.push({
              menu: "1 & 1/2 Yard",
              link: "finisher-drawing-yards",
            });
          }
          setDashboardSubtitle([
            { menutitle: "Finisher Drawing" },
            ...finishersubmenu,
          ]);
          break;
        }

        case "Simplex": {
          let simplexsubmenu = [];

          if (userSubModules.includes("simplex_checking_machine_settings")) {
            simplexsubmenu.push({
              menu: "Simplex Machine Setting",
              link: "simplex-machine-setting",
            });
          }
          if (userSubModules.includes("simplex_checking")) {
            simplexsubmenu.push({
              menu: "Simplex Checking",
              link: "simplex-checking-draft",
            });
          }
          setDashboardSubtitle([{ menutitle: "Simplex" }, ...simplexsubmenu]);
          break;
        }

        case "General": {
          let generalsubmenu = [];
          if (userSubModules.includes("general_machine_settings")) {
            generalsubmenu.push({
              menu: "General Machine Settings",
              link: "general-machine-setting",
            });
          }
          setDashboardSubtitle([{ menutitle: "General" }, ...generalsubmenu]);
          break;
        }

        case "Others": {
          let otherssubmenu = [];
          if (userSubModules.includes("others_machine_settings")) {
            otherssubmenu.push({
              menu: "Others Machine Settings",
              link: "other-machine-setting",
            });
          }
          setDashboardSubtitle([{ menutitle: "Others" }, ...otherssubmenu]);
          break;
        }

        //this is for manager
        case "Machine Setting": {
          let machinesettingcorrectionsubmenu = [];
          if (userSubModules.includes("blow_room_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Blowroom Setting ",
              link: "blowroom-machine-setting-correction",
            });
          }
          if (userSubModules.includes("carding_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Carding Setting ",
              link: "carding-machine-setting-correction",
            });
          }
          if (userSubModules.includes("autoconer_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Autoconer Setting ",
              link: "autoconer-machine-setting-correction",
            });
          }
          if (userSubModules.includes("spinning_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Spinning Setting ",
              link: "spinning-machine-setting-correction",
            });
          }
          if (userSubModules.includes("finisher_drawing_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Drawing Setting ",
              link: "drawing-machine-setting-correction",
            });
          }
          if (userSubModules.includes("simplex_checking_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Simplex Setting ",
              link: "simplex-machine-setting-correction",
            });
          }

          if (userSubModules.includes("general_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "General Setting ",
              link: "general-machine-setting-correction",
            });
          }

          if (userSubModules.includes("others_machine_settings")) {
            machinesettingcorrectionsubmenu.push({
              menu: "Others Setting ",
              link: "others-machine-setting-correction",
            });
          }

          setDashboardSubtitle([
            { menutitle: "Machine Settings" },
            ...machinesettingcorrectionsubmenu,
          ]);
          break;
        }

        //this is for employee or sub manager
        case "Machine Settings": {
          let machinesettingsubmenu = [];

          if (userSubModules.includes("blow_room_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Blowroom Setting ",
              link: "blowroom-machine-setting",
            });
          }
          if (userSubModules.includes("carding_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Carding Setting ",
              link: "carding-machine-setting",
            });
          }
          if (userSubModules.includes("autoconer_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Autoconer Setting ",
              link: "autoconer-machine-setting",
            });
          }
          if (userSubModules.includes("spinning_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Spinning Setting ",
              link: "spinning-machine-setting",
            });
          }
          if (userSubModules.includes("finisher_drawing_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Drawing Setting ",
              link: "drawing-machine-setting",
            });
          }
          if (userSubModules.includes("simplex_checking_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Simplex Setting ",
              link: "simplex-machine-setting",
            });
          }

          if (userSubModules.includes("general_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "General Setting ",
              link: "general-machine-setting",
            });
          }

          if (userSubModules.includes("others_machine_settings")) {
            machinesettingsubmenu.push({
              menu: "Others Setting ",
              link: "other-machine-setting",
            });
          }

          setDashboardSubtitle([
            { menutitle: "Machine Settings" },
            ...machinesettingsubmenu,
          ]);
          break;
        }

        //this is for Task sheets for Employee and Sub Manager
        case "Task Sheets":
          setDashboardSubtitle([
            { menutitle: "Task Sheets" },
            { menu: "Task Sheets", link: "task-sheets-complaints" },
          ]);
          break;

        //this is for Task sheets Manager
        case "Task Sheets Respond":
          setDashboardSubtitle([
            { menutitle: "Task Sheets" },
            { menu: "Task Sheets", link: "task-sheets-resolve" },
          ]);
          break;

        //this is for sastra
        case "Sasta":
          setDashboardSubtitle([
            { menutitle: "Sasta Reports" },
            { menu: "Sastra Reports", link: "sasta-reports" },
          ]);
          break;
        case "Norms":
          setDashboardSubtitle([
            { menutitle: "Norms" },
            { menu: "Blowroom Norms", link: "blowroom-norms" },
          ]);
          break;
        case "Wrapping":
          setDashboardSubtitle([
            { menutitle: "Wrapping" },
            { menu: "Wrapping", link: "wrapping-auto" },
          ]);
          break;
        case "Parameter Master":
          setDashboardSubtitle([
            { menutitle: "Parameter Master" },
            { menu: "Parameter Master", link: "parameter-master" },
          ]);
          break;
        case "User":
          setDashboardSubtitle([
            { menutitle: "User" },
            { menu: "Admin User", link: "admin-user" },
            { menu: "Television User", link: "television-user" },
          ]);
          break;
        case "Notifications":
          setDashboardSubtitle([
            { menutitle: "Notifications" },
            {
              menu: "Blowroom notifications",
              link: "manager-blowroom-notifications",
            },
            {
              menu: "Autoconer notifications",
              link: "manager-autoconer-notifications",
            },
            {
              menu: "Spinning notifications",
              link: "manager-spinning-notifications",
            },
            {
              menu: "Finisher Drawing notifications",
              link: "manager-finisher-drawing-notifications",
            },
            {
              menu: "Sastra notifications",
              link: "manager-sasta-notifications",
            },
          ]);
          break;
        case "Reports":
          const reportSubmenu = [];
          //blowroom
          if (userModulesData.includes("blow_room")) {
            reportSubmenu.push({ menu: "Blowroom Report" });

            if (userSubModules.includes("lap_feed")) {
              reportSubmenu.push({
                menureport: "Lap Feed Waste Report",
                link: "lap-feed-report",
              });
            }
            if (userSubModules.includes("chute_feed_cotton")) {
              reportSubmenu.push({
                menureport: "Chute Feed waste (Cotton) Report",
                link: "chute-feed-cotton-report",
              });
            }
            if (userSubModules.includes("chute_feed_others")) {
              reportSubmenu.push({
                menureport: "Chute Feed waste (Others) Report",
                link: "chute-feed-others-report",
              });
            }
            if (userSubModules.includes("drop_test")) {
              reportSubmenu.push({
                menureport: "Drop Test Report",
                link: "drop-test-report",
              });
            }
          }

          //carding
          if (userModulesData.includes("carding")) {
            reportSubmenu.push({ menu: "Carding Report" });
            if (userSubModules.includes("individual_carding_waste")) {
              reportSubmenu.push({
                menureport: "Individual Carding Waste Report",
                link: "individual-carding-waste-report",
              });
            }
            if (userSubModules.includes("auto_leveller_on/off")) {
              reportSubmenu.push({
                menureport: "Auto Leveller ON/Off Report",
                link: "auto-leveller-on-off-report",
              });
            }
          }

          //autoconer
          if (userModulesData.includes("autoconer")) {
            reportSubmenu.push({ menu: "Autoconer Report" });

            if (userSubModules.includes("splice_strength")) {
              reportSubmenu.push({
                menureport: "Splice Strength Report",
                link: "splice-strength-report",
              });
            }
            if (userSubModules.includes("paper_cone_identification")) {
              reportSubmenu.push({
                menureport: "Paper Cone Identification Report",
                link: "paper-cone-identification-report",
              });
            }
            if (userSubModules.includes("ln_checking")) {
              reportSubmenu.push({
                menureport: "LN Checking Report",
                link: "ln-checking-report",
              });
            }
            if (userSubModules.includes("rewinding_study")) {
              reportSubmenu.push({
                menureport: "Rewinding Study Report",
                link: "rewinding-study-report",
              });
            }
            if (userSubModules.includes("trail")) {
              reportSubmenu.push({
                menureport: "Trail Report",
                link: "trail-report",
              });
            }
            if (userSubModules.includes("u_percent_trail")) {
              reportSubmenu.push({
                menureport: "U% Trail Report",
                link: "u-percent-trail-report",
              });
            }
          }

          //spinning
          if (userModulesData.includes("spinning")) {
            reportSubmenu.push({ menu: "Spinning Report" });

            if (userSubModules.includes("count_change")) {
              reportSubmenu.push({
                menureport: "Count Change Report",
                link: "count-change-report",
              });
            }
            if (userSubModules.includes("cot_checking")) {
              reportSubmenu.push({
                menureport: "Cot Checking Report",
                link: "cot-checking-report",
              });
            }
            if (userSubModules.includes("hariness")) {
              reportSubmenu.push({
                menureport: "Hariness Report",
                link: "hariness-report",
              });
            }
            if (userSubModules.includes("apron_spacer_condenser")) {
              reportSubmenu.push({
                menureport: "Apron, Spacer & Condenser Report",
                link: "apron-report",
              });
            }
          }

          //lap
          if (userModulesData.includes("lap")) {
            reportSubmenu.push({ menu: "Lap Report" });
            if (userSubModules.includes("sliver_lap")) {
              reportSubmenu.push({
                menureport: "Sliver Lap Report",
                link: "sliver-lap-report",
              });
            }
            if (userSubModules.includes("ribbon_lap")) {
              reportSubmenu.push({
                menureport: "Ribbon Lap Report",
                link: "ribbon-lap-report",
              });
            }
            if (userSubModules.includes("structure_lap")) {
              reportSubmenu.push({
                menureport: "Structure Lap Report",
                link: "structure-lap-report",
              });
            }
          }

          //finisher
          if (userModulesData.includes("finisher_drawing")) {
            reportSubmenu.push({ menu: "Finisher Drawing Report" });
            if (userSubModules.includes("finisher_drawing_yard")) {
              reportSubmenu.push({
                menureport: "1 & 1/2 Yard Report",
                link: "finisher-drawing-report",
              });
            }
          }

          //simplex
          if (userModulesData.includes("simplex_checking")) {
            reportSubmenu.push({ menu: "Simplex Report" });
            if (userSubModules.includes("simplex_checking")) {
              reportSubmenu.push({
                menureport: "Simplex Checking Report",
                link: "simplex-checking-report",
              });
            }
          }
          setDashboardSubtitle(reportSubmenu);
          break;
        default:
          setDashboardSubtitle([]);
          break;
      }
    } else {
      setDashboardSubtitle([]);
    }
  };

  const handleMenuItemClick = (event, menuItemId) => {
    // setAnchorEl(event.currentTarget);
    setActiveMenuItem(menuItemId);
    setOpenModal(true);
  };

  const handleClose = () => {
    // setAnchorEl(null);
    setActiveMenuItem(null);
    setOpenModal(false);
  };

  return (
    <ContainerWrapper
      container
      item
      xs={12}
      alignContent={"flex-start"}
      rowGap={3}
    >
      <Grid container item xs={12}>
        <p style={{ margin: 0, fontSize: "40px" }}>
          <span style={{ fontWeight: 700 }}>Welcome back </span>
          <span style={{ fontWeight: 300 }}>to</span>
        </p>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: "3%" }}>
        <p style={{ margin: 0, fontSize: "32px", fontWeight: 300 }}>
          Quality control
        </p>
      </Grid>

      <Grid container spacing={3}>
        {dashboardMenu.map((menu, index) => (
          <Grid item md={4} sm={4} xs={12} key={index}>
            <Link
              onClick={(event) => handleMenuItemClick(event, menu.link)}
              style={{ textDecoration: "none" }}
            >
              <DashboardCard menu={menu.menu} />
            </Link>
            {/* 
            {activeMenuItem === menu.link && menu.menu == "Reports" &&  (
              <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
                <Menu>
                  {dashboardSubtitle.map((sub, subIndex) => (
                    <SubMenu key={subIndex} label={sub.menu}>
                      <MenuItem onClick={handleClose}>
                        <Link to={`/${sub.link}`}>
                          {sub.menureport}
                        </Link>
                      </MenuItem>
                    </SubMenu>
                  ))}
                </Menu>
              </Dialog>
            )}
       */}

            {activeMenuItem === menu.link && menu.menu === "Reports" && (
              <Dialog
                open={openModal}
                onClose={handleCloseModal}
                fullWidth
                PaperProps={{
                  sx: { overflow: "hidden", border: "2px solid #ec0000" },
                }}
                scroll={"body"}
              >
                <Grid
                  container
                  spacing={3}
                  style={{ padding: "0px 0px 20px 0px" }}
                >
                  {dashboardSubtitle?.map((sub, subIndex) => (
                    <Grid item xs={12} key={subIndex}>
                      {sub.menu && (
                        <h2
                          style={{
                            overflow: "hidden",
                            textAlign: "center",
                            display: "block",
                            width: "100%",
                            backgroundColor: "#ec0000",
                            color: "white",
                            padding: "10px 0px",
                          }}
                        >
                          {sub.menu}
                        </h2>
                      )}
                      {sub.link && (
                        <Link to={`/${sub.link}`} onClick={handleClose}>
                          <MenuItem
                            onClick={handleClose}
                            style={{
                              overflow: "hidden",
                              textAlign: "center",
                              display: "block",
                              width: "100%",
                              textDecoration: "none",
                            }}
                          >
                            {sub.menureport}
                          </MenuItem>
                        </Link>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Dialog>
            )}

            {activeMenuItem === menu.link && menu.menu !== "Reports" && (
              <Dialog
                open={openModal}
                onClose={handleCloseModal}
                fullWidth
                scroll={"body"}
                PaperProps={{
                  sx: { overflow: "hidden", border: "2px solid #ec0000" },
                }}
              >
                <Grid
                  container
                  spacing={3}
                  style={{ padding: "0px 0px 20px 0px" }}
                >
                  {dashboardSubtitle.map((sub, subIndex) => (
                    <Grid item xs={12} key={subIndex}>
                      {sub.menutitle && (
                        <h2
                          style={{
                            overflow: "hidden",
                            textAlign: "center",
                            display: "block",
                            width: "100%",
                            backgroundColor: "#ec0000",
                            color: "white",
                            padding: "10px 0px",
                          }}
                        >
                          {sub.menutitle}
                        </h2>
                      )}
                      {sub.link && (
                        <Link to={`/${sub.link}`} onClick={handleClose}>
                          <MenuItem
                            onClick={handleClose}
                            style={{
                              overflow: "hidden",
                              textAlign: "center",
                              display: "block",
                              width: "100%",
                              textDecoration: "none",
                            }}
                          >
                            {sub.menu}
                          </MenuItem>
                        </Link>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Dialog>
            )}
          </Grid>
        ))}
      </Grid>
    </ContainerWrapper>
  );
}

export default Dashboard;
