import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  IconButton,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Dialog,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckOverflow from "../../utils/CheckOverflow";
import TaskSheetReportAndResolve from "../TaskSheetReportAndResolve";
import { useQuery } from "react-query";
import { getReportIssueData } from "./mutation";
import dayjs from "dayjs";
import Loader from "../../components/Loader";
import { getUsers } from "../Blowroom/mutation/auth/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";

function TaskSheet() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterSlNo, setFilterSlNo] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  const [isForm, setIsform] = useState(false);
  const [isRaiseReport, setIsRaiseReport] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const {
    data: reportIssueData,
    isLoading: isReportDataLoading,
    refetch,
  } = useQuery(
    "task-sheets-report-issue",
    async () => {
      if (!isRaiseReport) {
        setIsLoadingData(true);
      }

      try {
        const response = await getReportIssueData();
        return response;
      } catch (error) {
        throw error;
      } finally {
        if (!isRaiseReport) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  const { data: users, isLoading } = useQuery("users", getUsers, {
    refetchOnMount: true,
  });

  //this is for filter the manager users only
  const filteredManagers =
    users?.filter((item) => item.role === "MANAGER") || [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    if (isNaN(event.target.value)) {
      alert("Please enter a number only");
      return;
    }

    setFilterSlNo(event.target.value);
    setPage(0);
  };

  const handleResetFilter = () => {
    setFilterSlNo("");
    setPage(0);
  };

  const handleStatusFilterChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
      setPage(0);
    }
  };

  //this is for pretend the rerender issue when we give checkoverflow params
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const newFilteredData =
      reportIssueData?.data?.filter((row) => {
        const matchesFilterTerm =
          !filterSlNo || row.slNo.toString() === filterSlNo;

        const matchesStatusFilter =
          statusFilter === "All" || row.status === statusFilter;

        return matchesFilterTerm && matchesStatusFilter;
      }) || [];

    setFilteredData(newFilteredData);
  }, [filterSlNo, reportIssueData?.data, statusFilter]);

  //handle overflow text
  const [isOverflowDraft, addDraftRef] = CheckOverflow(filteredData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflowDraft[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  const handleReport = () => {
    setIsform(true);
    setIsRaiseReport(true);
  };

  const goBack = () => {
    setIsform(false);
    setIsRaiseReport(false);
  };

  /**handle view the employee/sub manager's issued data */
  const [openDialog, setOpenDialog] = useState(false);
  const [showIssuedData, setShowIssuedData] = useState({});
  const handleViewDetails = (rowData) => {
    setOpenDialog(true);
    setShowIssuedData(rowData);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setShowIssuedData({});
  };

  if (isReportDataLoading || isLoading || isLoadingData) {
    return <Loader />;
  }

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: "36px",
        "@media (max-width: 600px)": {
          width: "100%",
          marginLeft: "0",
          marginTop: "20px",
        },
      }}
      alignContent={"flex-start"}
      rowGap={4}
    >
      {isForm ? (
        <Grid container item xs={12}>
          {" "}
          <TaskSheetReportAndResolve
            goBack={goBack}
            isRaiseReport={isRaiseReport}
            filteredManagers={filteredManagers}
          />
        </Grid>
      ) : (
        <Grid container item xs={12} rowGap={4}>
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item sm={6} xs={9}>
              <TextField
                fullWidth
                placeholder="Enter Sl No to filter"
                value={filterSlNo}
                onChange={handleFilterChange}
                InputProps={{
                  endAdornment: filterSlNo && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleResetFilter}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                // fullWidth
                sx={{
                  mt: { md: 0, sm: 0, xs: 2 },
                  fontSize: { md: "18px", xs: "14" },
                }}
                style={{ textTransform: "none" }}
                onClick={handleReport}
              >
                Report an issue
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={6}
            sm={6}
            xs={8}
            style={{ overflowX: "auto" }}
          >
            <ToggleButtonGroup
              value={statusFilter}
              exclusive
              onChange={handleStatusFilterChange}
              aria-label="status filter"
              s
              fullWidth
            >
              <ToggleButton value="All" aria-label="all">
                All
              </ToggleButton>
              <ToggleButton value="PENDING" aria-label="pending">
                Pending
              </ToggleButton>
              <ToggleButton value="RESOLVED" aria-label="resolved">
                Resolved
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid container item md={12} sm={12} xs={9}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: "80px", fontWeight: "600" }}>
                      Sl No
                    </TableCell>
                    <TableCell style={{ maxWidth: "80px", fontWeight: "600" }}>
                      Date
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Description
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Department
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Reporting Manager
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      Action / Status
                    </TableCell>
                    <TableCell style={{ maxWidth: "20px", fontWeight: "600" }}>
                      View
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{ maxWidth: "80px" }}>
                            <p className="overflowContain"> {row.slNo}</p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "80px" }}>
                            <p className="overflowContain">
                              {" "}
                              {dayjs(row.date).format("DD-MM-YYYY")}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "150px" }}>
                            <p
                              // className="overflowContain"
                              ref={addDraftRef(`description-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `description-${row.id}`,
                                  row.description
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                              style={{
                                color: "#e03131",
                                fontWeight: "bold",
                                textAlign: "justify",
                                wordWrap: "break-word", // Breaks long words to fit within the container
                                whiteSpace: "normal", // Allow wrapping on multiple lines
                              }}
                            >
                              {row.description}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`department-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `department-${row.id}`,
                                  row?.department
                                    ?.map((item) => item)
                                    .join(", ")
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {row?.department?.map((item) => item).join(", ")}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`reportingManager-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `reportingManager-${row.id}`,
                                  row?.reportingManager
                                    ?.map((item) => item?.name)
                                    .join(", ")
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {row?.reportingManager
                                ?.map((item) => item?.name)
                                .join(", ")}
                            </p>
                          </TableCell>

                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`status-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `status-${row.id}`,
                                  row.status
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                              style={{
                                color: `${
                                  row.status === "PENDING"
                                    ? "#f08c00"
                                    : "#2f9344"
                                }`,
                              }}
                            >
                              {row.status}
                            </p>
                          </TableCell>

                          <TableCell sx={{ maxWidth: "20px" }}>
                            <VisibilityIcon
                              sx={{
                                fontSize: "30px",
                                color: "#00897B",
                                cursor: "pointer",
                              }}
                              onClick={() => handleViewDetails(row)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography align="center">
                          No data available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Popover
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <p style={{ padding: "15px" }}>{popoverContent}</p>
              </Popover>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={filteredData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

          <Dialog
            open={openDialog}
            onClose={handleClose}
            maxWidth="lg"
            PaperProps={{
              sx: {
                width: "100%", // 80% of the viewport width
                height: "100%", // 80% of the viewport height
                padding: "20px",
              },
            }}
          >
            <Grid container item xs={12} alignContent={"flex-start"} rowGap={2}>
              <Grid container item xs={12} justifyContent={"flex-end"}>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>

              <Grid container item xs={12} rowGap={3}>
                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Sl No :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.slNo}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Hall :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.hall}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Date :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {dayjs(showIssuedData?.date).format("DD-MM-YYYY")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Department :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.department
                        ?.map((dept) => dept)
                        .join(", ")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Reporting Manager :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.reportingManager
                        ?.map((manager) => manager?.name)
                        .join(", ")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Description :{" "}
                    <span style={{ fontWeight: "400", color: "#e03131" }}>
                      {showIssuedData?.description}{" "}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Status :{" "}
                    <span
                      style={{
                        fontWeight: "400",
                        color:
                          showIssuedData?.status === "RESOLVED"
                            ? "#2f9344"
                            : "#f08c00",
                      }}
                    >
                      {showIssuedData?.status}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>Solution : </p>
                </Grid>

                <Grid container item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "700" }}>
                            Name
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            Solution
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            Solution Date
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            Seeked Date
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            Image
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showIssuedData?.reportingManagerSolution?.map(
                          (manager, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ fontWeight: "400" }}>
                                {manager?.name}
                              </TableCell>
                              <TableCell
                                style={{ fontWeight: "400", maxWidth: "150px" }}
                              >
                                {manager?.solution ? (
                                  <p
                                    style={{
                                      textAlign: "justify",
                                      wordWrap: "break-word", // Breaks long words to fit within the container
                                      whiteSpace: "normal", // Allow wrapping on multiple lines
                                    }}
                                  >
                                    {manager?.solution}
                                  </p>
                                ) : (
                                  <p>NA</p>
                                )}
                              </TableCell>

                              <TableCell style={{ fontWeight: "400" }}>
                                {manager?.solutionDate ? (
                                  <p>
                                    {dayjs(manager?.solutionDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                ) : (
                                  <p>NA</p>
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: "400" }}>
                                {manager?.seekedDate ? (
                                  <p>
                                    {dayjs(manager?.seekedDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                ) : (
                                  <p>NA</p>
                                )}
                              </TableCell>
                              <TableCell>
                                {manager?.image ? (
                                  <img
                                    src={manager?.image}
                                    style={{
                                      width: "100px", // Adjusted to fit in the table cell
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                    alt="issue"
                                  />
                                ) : (
                                  "NA"
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
}

export default TaskSheet;
