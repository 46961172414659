import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

const OthersMasterForm = ({ others, setOthers }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setOthers((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      {/*  */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Yarn Conditioing</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="yarnConditioning"
                  variant="outlined"
                  value={others.yarnConditioning}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default OthersMasterForm;
