import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import { getStandardsMasterData } from "../GeneralMachine/mutation";
import {
  getAllSimplexSubmittedData,
  managerUpdateSimplexData,
  saveSimplexDraftsData,
  submitSimplexData,
  updateSimplexDraftsData,
} from "./mutation";
import { handleToastMsg } from "../../../components/Toast";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import "./simplexmachinecss.css";
import CheckOverflow from "../../../utils/CheckOverflow";
import { MACHINE_SETTINGS_SIMPLEX_NO } from "../../../config/masterdata";
const AddSimplexRecord = ({
  selectedDataToEdit,
  goBack,
  viewMode,
  editMode,
  isManager,
  isTelevision,
  setPauseInterval,
}) => {
  const queryClient = useQueryClient();
  const [partyCode, setPartyCode] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query

  const today = dayjs().format("DD-MM-YYYY");
  const params = { partyCode, key: "simplex" };

  const [apiStandardsData, setApiStandardsData] = useState({});

  const { data: allSubmittedData } = useQuery(
    "all-simplex-data",
    getAllSimplexSubmittedData
  );

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { isLoading, isError, refetch } = useQuery(
    "all-standard-simplex-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  const submitMutation = useMutation(submitSimplexData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-simplex-data");
      queryClient.invalidateQueries("all-simplex-drafts-data");
      handleToastMsg("Data sumitted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const saveMutation = useMutation(saveSimplexDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-simplex-drafts-data");
      handleToastMsg("Draft data saved successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const updateMutation = useMutation(updateSimplexDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-simplex-drafts-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const managerUpdateMutation = useMutation(managerUpdateSimplexData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-simplex-data");
      queryClient.invalidateQueries("all-notification-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const [formData, setFormData] = useState({
    partyCode: "",
    simplexNo: { value: "", isOutOfNorms: false },
    simplexHank: { value: "", isOutOfNorms: false },
    tpiTm: { value: "", isOutOfNorms: false },
    speed: { value: "", isOutOfNorms: false },
    bottomRollSetting: { value: { x: "", y: "", z: "" }, isOutOfNorms: false },
    topRollSetting: { value: { x: "", y: "", z: "" }, isOutOfNorms: false },
    bd: { value: "", isOutOfNorms: false },
    spacer: { value: "", isOutOfNorms: false },
    topArmPressure: { value: "", isOutOfNorms: false },
    condensorBack: { value: "", isOutOfNorms: false },
    condensorMiddle: { value: "", isOutOfNorms: false },
    condensorFront: { value: "", isOutOfNorms: false },
    feedback: "",
  });

  const [applied, setApplied] = useState(false);
  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState("");

  useEffect(() => {
    if (isManager) {
      //this is for showing the date select
      setApplied(true);
      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        bd: {
          ...prev.bd,
          ...(selectedDataToEdit?.bd?.isOutOfNorms === false && {
            value: selectedDataToEdit?.bd?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.bd?.isOutOfNorms,
        },
        bottomRollSetting: {
          ...prev.bottomRollSetting,
          ...(selectedDataToEdit?.bottomRollSetting?.isOutOfNorms === false && {
            value: {
              x: selectedDataToEdit?.bottomRollSetting?.value?.x,
              y: selectedDataToEdit?.bottomRollSetting?.value?.y,
              z: selectedDataToEdit?.bottomRollSetting?.value?.z,
            },
          }),
          isOutOfNorms: selectedDataToEdit?.bottomRollSetting?.isOutOfNorms,
        },
        topRollSetting: {
          ...prev.topRollSetting,
          ...(selectedDataToEdit?.topRollSetting?.isOutOfNorms === false && {
            value: {
              x: selectedDataToEdit?.topRollSetting?.value?.x,
              y: selectedDataToEdit?.topRollSetting?.value?.y,
              z: selectedDataToEdit?.topRollSetting?.value?.z,
            },
          }),
          isOutOfNorms: selectedDataToEdit?.topRollSetting?.isOutOfNorms,
        },
        condensorBack: {
          ...prev.condensorBack,
          ...(selectedDataToEdit?.condensorBack?.isOutOfNorms === false && {
            value: selectedDataToEdit?.condensorBack?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.condensorBack?.isOutOfNorms,
        },
        condensorFront: {
          ...prev.condensorFront,
          ...(selectedDataToEdit?.condensorFront?.isOutOfNorms === false && {
            value: selectedDataToEdit?.condensorFront?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.condensorFront?.isOutOfNorms,
        },
        condensorMiddle: {
          ...prev.condensorMiddle,
          ...(selectedDataToEdit?.condensorMiddle?.isOutOfNorms === false && {
            value: selectedDataToEdit?.condensorMiddle?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.condensorMiddle?.isOutOfNorms,
        },
        simplexHank: {
          ...prev.simplexHank,
          ...(selectedDataToEdit?.simplexHank?.isOutOfNorms === false && {
            value: selectedDataToEdit?.simplexHank?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.simplexHank?.isOutOfNorms,
        },
        simplexNo: {
          ...prev.simplexNo,
          ...(selectedDataToEdit?.simplexNo?.isOutOfNorms === false && {
            value: selectedDataToEdit?.simplexNo?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.simplexNo?.isOutOfNorms,
        },
        spacer: {
          ...prev.spacer,
          ...(selectedDataToEdit?.spacer?.isOutOfNorms === false && {
            value: selectedDataToEdit?.spacer?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.spacer?.isOutOfNorms,
        },
        speed: {
          ...prev.speed,
          ...(selectedDataToEdit?.speed?.isOutOfNorms === false && {
            value: selectedDataToEdit?.speed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.speed?.isOutOfNorms,
        },
        topArmPressure: {
          ...prev.topArmPressure,
          ...(selectedDataToEdit?.topArmPressure?.isOutOfNorms === false && {
            value: selectedDataToEdit?.topArmPressure?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.topArmPressure?.isOutOfNorms,
        },
        tpiTm: {
          ...prev.tpiTm,
          ...(selectedDataToEdit?.tpiTm?.isOutOfNorms === false && {
            value: selectedDataToEdit?.tpiTm?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.tpiTm?.isOutOfNorms,
        },
      }));
    }

    if ((editMode || viewMode) && selectedDataToEdit) {
      //this is for showing the date select
      setApplied(true);

      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //this all other data fetch

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        bd: {
          ...prev.bd,
          value: selectedDataToEdit?.bd?.value,
          isOutOfNorms: selectedDataToEdit?.bd?.isOutOfNorms,
        },
        bottomRollSetting: {
          ...prev.bottomRollSetting,
          value: {
            x: selectedDataToEdit?.bottomRollSetting?.value?.x,
            y: selectedDataToEdit?.bottomRollSetting?.value?.y,
            z: selectedDataToEdit?.bottomRollSetting?.value?.z,
          },
          isOutOfNorms: selectedDataToEdit?.bottomRollSetting?.isOutOfNorms,
        },
        topRollSetting: {
          ...prev.topRollSetting,
          value: {
            x: selectedDataToEdit?.topRollSetting?.value?.x,
            y: selectedDataToEdit?.topRollSetting?.value?.y,
            z: selectedDataToEdit?.topRollSetting?.value?.z,
          },
          isOutOfNorms: selectedDataToEdit?.topRollSetting?.isOutOfNorms,
        },
        condensorBack: {
          ...prev.condensorBack,
          value: selectedDataToEdit?.condensorBack?.value,
          isOutOfNorms: selectedDataToEdit?.condensorBack?.isOutOfNorms,
        },
        condensorFront: {
          ...prev.condensorFront,
          value: selectedDataToEdit?.condensorFront?.value,
          isOutOfNorms: selectedDataToEdit?.condensorFront?.isOutOfNorms,
        },
        condensorMiddle: {
          ...prev.condensorMiddle,
          value: selectedDataToEdit?.condensorMiddle?.value,
          isOutOfNorms: selectedDataToEdit?.condensorMiddle?.isOutOfNorms,
        },
        simplexHank: {
          ...prev.simplexHank,
          value: selectedDataToEdit?.simplexHank?.value,
          isOutOfNorms: selectedDataToEdit?.simplexHank?.isOutOfNorms,
        },
        simplexNo: {
          ...prev.simplexNo,
          value: selectedDataToEdit?.simplexNo?.value,
          isOutOfNorms: selectedDataToEdit?.simplexNo?.isOutOfNorms,
        },
        spacer: {
          ...prev.spacer,
          value: selectedDataToEdit?.spacer?.value,
          isOutOfNorms: selectedDataToEdit?.spacer?.isOutOfNorms,
        },
        speed: {
          ...prev.speed,
          value: selectedDataToEdit?.speed?.value,
          isOutOfNorms: selectedDataToEdit?.speed?.isOutOfNorms,
        },
        topArmPressure: {
          ...prev.topArmPressure,
          value: selectedDataToEdit?.topArmPressure?.value,
          isOutOfNorms: selectedDataToEdit?.topArmPressure?.isOutOfNorms,
        },
        tpiTm: {
          ...prev.tpiTm,
          value: selectedDataToEdit?.tpiTm?.value,
          isOutOfNorms: selectedDataToEdit?.tpiTm?.isOutOfNorms,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, selectedDataToEdit]);

  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
    setFormData((prev) => ({ ...prev, partyCode: event.target.value }));
  };

  const handleFeedback = (event) => {
    setFormData((prev) => ({ ...prev, feedback: event.target.value.toUpperCase(), }));
  };

  const checkXandYandZNoisOutOfNormsForBottom =
    apiStandardsData?.data?.data?.["bottomRollSetting"]?.x ===
      formData?.bottomRollSetting?.value?.x &&
    apiStandardsData?.data?.data?.["bottomRollSetting"]?.y ===
      formData?.bottomRollSetting?.value?.y &&
    apiStandardsData?.data?.data?.["bottomRollSetting"]?.z ===
      formData?.bottomRollSetting?.value?.z;

  const checkXandYandZNoisOutOfNormsForTop =
    apiStandardsData?.data?.data?.["topRollSetting"]?.x ===
      formData?.topRollSetting?.value?.x &&
    apiStandardsData?.data?.data?.["topRollSetting"]?.y ===
      formData?.topRollSetting?.value?.y &&
    apiStandardsData?.data?.data?.["topRollSetting"]?.z ===
      formData?.topRollSetting?.value?.z;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    if (name === "bottomRollSettingX") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.x === value &&
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.y ===
          formData?.bottomRollSetting?.value?.y &&
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.z ===
          formData?.bottomRollSetting?.value?.z
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        bottomRollSetting: {
          ...prevState.bottomRollSetting,
          value: { ...prevState.bottomRollSetting.value, x: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name === "bottomRollSettingY") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.y === value &&
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.x ===
          formData?.bottomRollSetting?.value?.x &&
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.z ===
          formData?.bottomRollSetting?.value?.z
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        bottomRollSetting: {
          ...prevState.bottomRollSetting,
          value: { ...prevState.bottomRollSetting.value, y: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name === "bottomRollSettingZ") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.z === value &&
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.x ===
          formData?.bottomRollSetting?.value?.x &&
        apiStandardsData?.data?.data?.["bottomRollSetting"]?.y ===
          formData?.bottomRollSetting?.value?.y
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        bottomRollSetting: {
          ...prevState.bottomRollSetting,
          value: { ...prevState.bottomRollSetting.value, z: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name === "topRollSettingX") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["topRollSetting"]?.x === value &&
        apiStandardsData?.data?.data?.["topRollSetting"]?.y ===
          formData?.topRollSetting?.value?.y &&
        apiStandardsData?.data?.data?.["topRollSetting"]?.z ===
          formData?.topRollSetting?.value?.z
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        topRollSetting: {
          ...prevState.topRollSetting,
          value: { ...prevState.topRollSetting.value, x: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name === "topRollSettingY") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["topRollSetting"]?.y === value &&
        apiStandardsData?.data?.data?.["topRollSetting"]?.x ===
          formData?.topRollSetting?.value?.x &&
        apiStandardsData?.data?.data?.["topRollSetting"]?.z ===
          formData?.topRollSetting?.value?.z
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        topRollSetting: {
          ...prevState.topRollSetting,
          value: { ...prevState.topRollSetting.value, y: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name === "topRollSettingZ") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["topRollSetting"]?.z === value &&
        apiStandardsData?.data?.data?.["topRollSetting"]?.x ===
          formData?.topRollSetting?.value?.x &&
        apiStandardsData?.data?.data?.["topRollSetting"]?.y ===
          formData?.topRollSetting?.value?.y
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        topRollSetting: {
          ...prevState.topRollSetting,
          value: { ...prevState.topRollSetting.value, z: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name) {
      // const [field, subField] = name;
      if (name === "simplexNo") {
        const parsedValue = value === "" ? "" : parseFloat(value);
        const isOutOfNorms = false;

        setFormData((prevState) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            value: parsedValue,
            isOutOfNorms: isOutOfNorms,
          },
        }));
      } else {
        const isOutOfNorms =
          apiStandardsData?.data?.data?.[name] === value ? false : true;

        setFormData((prevState) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
            isOutOfNorms: isOutOfNorms,
          },
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = selectedDataToEdit
      ? { ...formData, simplexMasterDraftId: selectedDataToEdit?.id }
      : { ...formData };

    const params = {
      payload: { ...formData, isOutOfNorms: false },
      id: selectedDataToEdit?.id,
    };

    if (isManager) {
      managerUpdateMutation.mutate(params);
    } else {
      submitMutation.mutate(payload);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (editMode) {
      const params = {
        payload: { ...formData, variety: apiStandardsData?.data?.variety },
        id: selectedDataToEdit?.id,
      };
      updateMutation.mutate(params);
    } else {
      saveMutation.mutate({
        ...formData,
        variety: apiStandardsData?.data?.variety,
      });
    }
  };

  const handleApply = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false); // Disable the query
    setSelectedDates("");
    setApiStandardsData({});
  };

  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedData?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedData]);

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const filteredDataFromApi =
    allSubmittedData?.data?.filter(
      (item) => item.partyCode === partyCode && selectedDates === item.date
    ) || [];

  const enableSubmit =
    formData?.bd?.value !== "" &&
    formData?.bottomRollSetting?.value?.x !== "" &&
    formData?.bottomRollSetting?.value?.y !== "" &&
    formData?.bottomRollSetting?.value?.z !== "" &&
    formData?.topRollSetting?.value?.x !== "" &&
    formData?.topRollSetting?.value?.y !== "" &&
    formData?.topRollSetting?.value?.z !== "" &&
    formData?.condensorBack?.value !== "" &&
    formData?.condensorFront?.value !== "" &&
    formData?.condensorMiddle?.value !== "" &&
    formData?.simplexHank?.value !== "" &&
    formData?.simplexNo?.value !== "" &&
    formData?.spacer?.value !== "" &&
    formData?.speed?.value !== "" &&
    formData?.topArmPressure?.value !== "" &&
    formData?.tpiTm?.value !== "";

  //all outOfNorms shomustuld be false
  const isManagerEnableSubmit =
    !formData?.bd?.isOutOfNorms &&
    !formData?.bottomRollSetting?.isOutOfNorms &&
    !formData?.topRollSetting?.isOutOfNorms &&
    !formData?.condensorBack?.isOutOfNorms &&
    !formData?.condensorFront?.isOutOfNorms &&
    !formData?.condensorMiddle?.isOutOfNorms &&
    !formData?.simplexHank?.isOutOfNorms &&
    !formData?.simplexNo?.isOutOfNorms &&
    !formData?.spacer?.isOutOfNorms &&
    !formData?.speed?.isOutOfNorms &&
    !formData?.topArmPressure?.isOutOfNorms &&
    !formData?.tpiTm?.isOutOfNorms;

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    apiStandardsData?.data ? apiStandardsData?.data : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    goBack();
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">Add Simplex Record</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <p>Party Code: </p>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              disabled={viewMode || editMode || isManager}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handleApply}
            color="primary"
            style={{
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {applied && partyCode && (
            <Select
              value={selectedDates}
              onChange={handleDateChange}
              label="Select Date"
              fullWidth
            >
              {allDates?.map((date) => (
                <MenuItem key={date} value={date}>
                  {dayjs(date).format("DD-MM-YYYY")}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Particulars</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Previous Data
                </Grid>
                <Grid item xs={12}>
                  {selectedDates && dayjs(selectedDates).format("DD-MM-YYYY")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Actual Data
                </Grid>
                <Grid item xs={12}>
                  {isManager
                    ? dayjs(selectedDataToEdit?.date).format("DD-MM-YYYY")
                    : today}
                </Grid>
              </TableCell>
              {isManager && (
                <TableCell>
                  <Grid item xs={12}>
                    Correction Data
                  </Grid>
                  <Grid item xs={12}>
                    {today}
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Simplex No.</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.simplexNo}
              </TableCell>
              <TableCell>
                <p>{filteredDataFromApi[0]?.simplexNo?.value}</p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p>{selectedDataToEdit?.simplexNo?.value}</p>
                </TableCell>
              )}
              <TableCell>
                <FormControl variant="outlined" style={{ width: "200px" }}>
                  <Select
                    fullWidth
                    name="simplexNo"
                    value={formData?.simplexNo?.value || ""}
                    onChange={handleChange}
                    disabled={viewMode || isManager}
                  >
                    {MACHINE_SETTINGS_SIMPLEX_NO?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simplex Hank</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.simplexHank}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.simplexHank?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.simplexHank?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.simplexHank?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.simplexHank?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="simplexHank"
                  variant="outlined"
                  value={formData?.simplexHank?.value}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.simplexHank?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.simplexHank ===
                        formData?.simplexHank?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TPI/TM</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.tpiTm}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.tpiTm?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.tpiTm?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.tpiTm?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.tpiTm?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="tpiTm"
                  variant="outlined"
                  value={formData?.tpiTm?.value}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.tpiTm?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.tpiTm ===
                        formData?.tpiTm?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.speed}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.speed?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.speed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.speed?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.speed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="speed"
                  variant="outlined"
                  value={formData?.speed?.value}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.speed?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.speed ===
                        formData?.speed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom roll setting </TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    !isLoading &&
                    apiStandardsData?.data?.data &&
                    `${apiStandardsData?.data?.data?.bottomRollSetting?.x} * ${apiStandardsData?.data?.data?.bottomRollSetting?.y} * ${apiStandardsData?.data?.data?.bottomRollSetting?.z}`}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.bottomRollSetting
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi.length > 0 &&
                    `${filteredDataFromApi[0]?.bottomRollSetting?.value?.x} * ${filteredDataFromApi[0]?.bottomRollSetting?.value?.y} * ${filteredDataFromApi[0]?.bottomRollSetting?.value?.z}`}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.bottomRollSetting?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {`${selectedDataToEdit?.bottomRollSetting?.value?.x} * ${selectedDataToEdit?.bottomRollSetting?.value?.y} * ${selectedDataToEdit?.bottomRollSetting?.value?.z}`}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="bottomRollSettingX"
                    variant="outlined"
                    value={formData?.bottomRollSetting?.value?.x}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.bottomRollSetting?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYandZNoisOutOfNormsForBottom
                          ? "green"
                          : "red",
                        width: "100px",
                      },
                    }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    name="bottomRollSettingY"
                    variant="outlined"
                    value={formData?.bottomRollSetting?.value?.y}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.bottomRollSetting?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYandZNoisOutOfNormsForBottom
                          ? "green"
                          : "red",
                        width: "100px",
                      },
                    }}
                  />
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    name="bottomRollSettingZ"
                    variant="outlined"
                    value={formData?.bottomRollSetting?.value?.z}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.bottomRollSetting?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYandZNoisOutOfNormsForBottom
                          ? "green"
                          : "red",
                        width: "100px",
                      },
                    }}
                  />
                </Grid>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Top roll setting</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    !isLoading &&
                    apiStandardsData?.data?.data &&
                    `${apiStandardsData?.data?.data?.topRollSetting?.x} * ${apiStandardsData?.data?.data?.topRollSetting?.y} * ${apiStandardsData?.data?.data?.topRollSetting?.z}`}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.topRollSetting?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi.length > 0 &&
                    `${filteredDataFromApi[0]?.topRollSetting?.value?.x} * ${filteredDataFromApi[0]?.topRollSetting?.value?.y} * ${filteredDataFromApi[0]?.topRollSetting?.value?.z}`}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.topRollSetting?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {`${selectedDataToEdit?.topRollSetting?.value?.x} * ${selectedDataToEdit?.topRollSetting?.value?.y} * ${selectedDataToEdit?.topRollSetting?.value?.z}`}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="topRollSettingX"
                    variant="outlined"
                    value={formData?.topRollSetting?.value?.x}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.topRollSetting?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYandZNoisOutOfNormsForTop
                          ? "green"
                          : "red",
                        width: "100px",
                      },
                    }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    name="topRollSettingY"
                    variant="outlined"
                    value={formData?.topRollSetting?.value?.y}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.topRollSetting?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYandZNoisOutOfNormsForTop
                          ? "green"
                          : "red",
                        width: "100px",
                      },
                    }}
                  />
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    name="topRollSettingZ"
                    variant="outlined"
                    value={formData?.topRollSetting?.value?.z}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.topRollSetting?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYandZNoisOutOfNormsForTop
                          ? "green"
                          : "red",
                        width: "100px",
                      },
                    }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError && partyCode && apiStandardsData?.data?.data?.bd}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.bd?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.bd?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.bd?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.bd?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="bd"
                  variant="outlined"
                  value={formData?.bd?.value}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.bd?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.bd === formData?.bd?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Spacer</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.spacer}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.spacer?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.spacer?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.spacer?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.spacer?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="spacer"
                  variant="outlined"
                  value={formData?.spacer?.value}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.spacer?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.spacer ===
                        formData?.spacer?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Top Arm Pressure</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.topArmPressure}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.topArmPressure?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.topArmPressure?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.topArmPressure?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.topArmPressure?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="topArmPressure"
                  variant="outlined"
                  value={formData?.topArmPressure?.value}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.topArmPressure?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.topArmPressure ===
                        formData?.topArmPressure?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Condensor back</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.condensorBack}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.condensorBack?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.condensorBack?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.condensorBack?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.condensorBack?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="condensorBack"
                  variant="outlined"
                  value={formData?.condensorBack?.value}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.condensorBack?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.condensorBack ===
                        formData?.condensorBack?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Condensor Middle</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.condensorMiddle}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.condensorMiddle?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.condensorMiddle?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.condensorMiddle?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.condensorMiddle?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="condensorMiddle"
                  variant="outlined"
                  value={formData?.condensorMiddle?.value}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.condensorMiddle?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.condensorMiddle ===
                        formData?.condensorMiddle?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Condensor Front</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.condensorFront}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.condensorFront?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.condensorFront?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.condensorFront?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.condensorFront?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="condensorFront"
                  variant="outlined"
                  value={formData?.condensorFront?.value}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.condensorFront?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.condensorFront ===
                        formData?.condensorFront?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {!isTelevision && (
        <>
          <TextField
            fullWidth
            name="feedback"
            value={formData.feedback}
            onChange={handleFeedback}
            label="Feedback"
            id="outlined-basic"
            variant="outlined"
            style={{ marginTop: "3%" }}
          />
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: "2%" }}
          >
            {!isManager && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "2%",
                }}
                disabled={
                  !partyCode ||
                  enableSubmit ||
                  updateMutation.isLoading ||
                  saveMutation.isLoading
                }
                onClick={handleSave}
              >
                {updateMutation.isLoading || saveMutation.isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save and Edit Later"
                )}
              </Button>
            )}
            <Button
              variant="contained"
              style={{
                background: "#181E72",
                color: "white",
                textTransform: "none",
              }}
              disabled={
                isManager
                  ? !isManagerEnableSubmit
                  : !enableSubmit ||
                    !partyCode ||
                    viewMode ||
                    managerUpdateMutation.isLoading ||
                    submitMutation.isLoading
              }
              onClick={handleSubmit}
            >
              {managerUpdateMutation.isLoading || submitMutation.isLoading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </>
      )}

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default AddSimplexRecord;
