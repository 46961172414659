import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getAllSpinningSubmittedData } from "./mutation";
import { FormControl, MenuItem, Popover, Select } from "@mui/material";
import dayjs from "dayjs";
import CheckOverflow from "../../../utils/CheckOverflow";
import { handleToastMsg } from "../../../components/Toast";
import { getStandardsMasterData } from "../GeneralMachine/mutation";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";

const SpinningPreviousEntries = ({ goBack }) => {
  
  const queryClient = useQueryClient();
  
  const [partyCode, setPartyCode] = useState("");

  const {
    data: allSubmittedData,
    isLoading,
    refetch,
  } = useQuery("all-spinning-data", getAllSpinningSubmittedData);

  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [applied, setApplied] = useState(false);
  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedData?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedData]);

  //this is for get api for the standard data
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  //this is for showing the variety(only) in the ui
  const params = { partyCode, key: "spinning" };
  const [apiStandardsData, setApiStandardsData] = useState({});

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { refetch: standardRefetch } = useQuery(
    "all-submitted-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      standardRefetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, standardRefetch]);

  //handle party code
  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
  };

  const handlePartyFilter = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false);
    setSelectedDates([]);
    setApplied(false);
    setApiStandardsData({});
  };

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const removeDate = (dateToRemove) => {
    setSelectedDates((prevDates) =>
      prevDates?.filter((date) => date !== dateToRemove)
    );
  };

  const filteredDataFromApi =
    allSubmittedData?.data?.filter(
      (item) =>
        item.partyCode === partyCode && selectedDates?.includes(item.date)
    ) || [];

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    filteredDataFromApi.length > 0 ? filteredDataFromApi : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    //this method for to clear that data for that particular key
    queryClient.setQueryData("all-submitted-data", {});
    goBack();
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid container justifyContent={"center"} xs={12} alignItems="center">
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">Spinning Previous Records</Typography>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <label for="partyCode">Party Code: </label>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              // style={{ marginRight: "16px" }}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handlePartyFilter}
            color="primary"
            style={{
              // background: "rgb(165, 58, 189)",
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {partyCode && applied && (
            <FormControl fullWidth variant="outlined">
              <Select
                labelId="select-date-label"
                multiple
                value={selectedDates}
                onChange={handleDateChange}
                renderValue={(selected) =>
                  selected
                    .map((date) => dayjs(date).format("DD-MM-YYYY"))
                    .join(", ")
                }
                label="Select Dates"
              >
                {allDates?.map((date) => (
                  <MenuItem key={date} value={date}>
                    {dayjs(date).format("DD-MM-YYYY")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Particular</b>
              </TableCell>
              {applied &&
                selectedDates?.map((date, index) => (
                  <TableCell key={index}>
                    <b>{`Previous Data (${dayjs(date).format(
                      "DD-MM-YYYY"
                    )})`}</b>
                    <IconButton onClick={() => removeDate(date)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {applied &&
              [
                "frameNo",
                "bottomRollSetting",
                "topRollSetting",
                "bd",
                "totalDraft",
                "tpiTm",
                "spacer",
                "traveller",
                "speed",
                "lycraDetailsMake",
                "lycraDraft",
                "calLycraPercentage",
                "actLycraPercentage",
              ].map((heading, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {heading.charAt(0).toUpperCase() + heading.slice(1)}
                  </TableCell>
                  {selectedDates?.map((date, i) => {
                    const item =
                      filteredDataFromApi?.find((data) => data.date === date) ||
                      [];

                    let value;
                    let isOutOfNorms;

                    if (Array.isArray(item[heading]?.value)) {
                      value = item[heading]?.value?.join(", ");
                      isOutOfNorms = item[heading]?.isOutOfNorms ?? "N/A";
                    } else if (heading === "bottomRollSetting") {
                      const bottomRollSetting = item?.bottomRollSetting;
                      value = bottomRollSetting
                        ? `${bottomRollSetting?.value?.x ?? "N/A"} * ${
                            bottomRollSetting?.value?.y ?? "N/A"
                          } * ${bottomRollSetting?.value?.z ?? "N/A"}`
                        : "N/A";
                      isOutOfNorms = bottomRollSetting?.isOutOfNorms ?? "N/A";
                    } else if (heading === "topRollSetting") {
                      const topRollSetting = item?.topRollSetting;
                      value = topRollSetting
                        ? `${topRollSetting?.value?.x ?? "N/A"} * ${
                            topRollSetting?.value?.y ?? "N/A"
                          } * ${topRollSetting?.value?.z ?? "N/A"}`
                        : "N/A";
                      isOutOfNorms = topRollSetting?.isOutOfNorms ?? "N/A";
                    } else {
                      value =
                        item?.[heading.replace(/\s+/g, "")]?.value ?? "N/A";
                      isOutOfNorms =
                        item?.[heading.replace(/\s+/g, "")]?.isOutOfNorms ??
                        "N/A";
                    }
                    return (
                      <TableCell key={i}>
                        <p style={{ color: isOutOfNorms ? "red" : "green" }}>
                          {value}
                        </p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default SpinningPreviousEntries;
