import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
} from "@material-ui/core";
import {
  MACHINE_SETTINGS_AUTOCONER_NO,
  MACHINE_SETTINGS_AUTOCONER_PAPERCONE,
} from "../config/masterdata";

const AutoConerMasterForm = ({
  autoconer,
  setAutoconer,
  autoConerPaperCone,
  setAutoConerPaperCone,
}) => {
  const handleSelectChange = (event) => {
    // setAutoConerPaperCone(event.target.value);
    // setAutoconer((prevState) => ({
    //   ...prevState,
    //   paperConeIdentification: autoConerPaperCone,
    // }));
    const { value } = event.target;
    setAutoConerPaperCone(value);
    setAutoconer((prevState) => ({
      ...prevState,
      paperConeIdentification: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setAutoconer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      {/* {autoConerPaperCone.length > 0 && (
        <div>Selected Values: {autoConerPaperCone.join(", ")}</div>
      )} */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Autoconer No</TableCell>
              <TableCell>
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  <Select
                    labelId="single-select-label"
                    value={autoconer.autoconerNo}
                    onChange={handleChange}
                    name="autoconerNo"
                    // label="Select Number"
                  >
                    {MACHINE_SETTINGS_AUTOCONER_NO?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="speed"
                    variant="outlined"
                    value={autoconer.speed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Paper Cone Identification</TableCell>
              <TableCell>
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  {/* <InputLabel id="multiple-select-label">Select Numbers</InputLabel> */}
                  <Select
                    labelId="multiple-select-label"
                    multiple
                    value={autoconer.paperConeIdentification}
                    onChange={handleSelectChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {MACHINE_SETTINGS_AUTOCONER_PAPERCONE?.map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          checked={autoConerPaperCone.indexOf(value) > -1}
                        />
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cone Weight</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="coneWeight"
                    variant="outlined"
                    value={autoconer.coneWeight}
                    onChange={handleChange}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    KG
                  </span>{" "}
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Splicing Nozzle</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="splicingNozzle"
                  variant="outlined"
                  value={autoconer.splicingNozzle}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Splicing Setting</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="splicingSetting"
                  variant="outlined"
                  value={autoconer.splicingSetting}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>"L" lever</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="lLever"
                  variant="outlined"
                  value={autoconer.lLever}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Creadle pressure</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="creadlePressure"
                  variant="outlined"
                  value={autoconer.creadlePressure}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Yarn Conditioing</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="yarnConditioning"
                  variant="outlined"
                  value={autoconer.yarnConditioning}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default AutoConerMasterForm;
