import apiClient from "../../../utils/clients/axios";

// Create a new record in the general master
export const createGeneralMasterRecord = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/general_master`,
    data
  );
  return response;
};

// Get all records from the general master
export const getAllGeneralSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/general_master`);
  return response;
};

// post general drafts records
export const saveGeneralDraftsData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/general_master/createGeneralMasterDraft`,
    payload
  );
  return response;
};

// post general submit all records
export const submitGeneralData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/general_master`,
    payload
  );
  return response;
};

// put general drafts records
export const updateGeneralDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/general_master/updateGeneralMasterDraft/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateGeneralData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/general_master/${id}`,
    payload
  );
  return response;
};

// Get Drafts records from the general master
export const getAllGeneralDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/general_master/getAllGeneralMastersDraft`
  );
  return response;
};

// Get all readings from the general parameter master data
export const getStandardsMasterData = async (params) => {
  const { partyCode, key } = params;
  const response = await apiClient.get(`/api/quality_control/master_data/`, {
    params: { partyCode: partyCode, key: key },
  });
  return response;
};

// Get a specific record from the general master by ID
export const getGeneralMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/general_master/${id}`
  );
  return response;
};

// Update a specific record in the general master by ID
export const updateGeneralMasterRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/general_master/${id}`,
    data
  );
  return response;
};

// Delete a specific record from the general master by ID
export const deleteGeneralMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/general_master/${id}`
  );
  return response;
};

// Delete a specific record from the general master by ID
export const deleteGeneralDraftMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/general_master/deleteGeneralMasterDraft/${id}`
  );
  return response;
};
