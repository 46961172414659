import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import {
  getAllCardingSubmittedData,
  managerUpdateCardingData,
  saveCardingDraftsData,
  submitCardingData,
  updateCardingDraftsData,
} from "./mutation";
import { getStandardsMasterData } from "../GeneralMachine/mutation";
import { handleToastMsg } from "../../../components/Toast";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import CheckOverflow from "../../../utils/CheckOverflow";
import { MACHINE_SETTINGS_CARDING_NO } from "../../../config/masterdata";

const AddCardingRecord = ({
  selectedDataToEdit,
  goBack,
  viewMode,
  editMode,
  isManager,
  isTelevision,
  setPauseInterval,
}) => {
  const queryClient = useQueryClient();
  const [partyCode, setPartyCode] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query

  const [applied, setApplied] = useState(false);
  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState("");

  const today = dayjs().format("DD-MM-YYYY");
  const params = { partyCode, key: "carding" };

  const [apiStandardsData, setApiStandardsData] = useState({});

  const { data: allSubmittedData } = useQuery(
    "all-carding-data",
    getAllCardingSubmittedData
  );

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { isLoading, isError, refetch } = useQuery(
    "all-standard-carding-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  const submitMutation = useMutation(submitCardingData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-carding-data");
      queryClient.invalidateQueries("all-carding-drafts-data");
      handleToastMsg("Data sumitted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const saveMutation = useMutation(saveCardingDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-carding-drafts-data");
      handleToastMsg("Draft data saved successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const updateMutation = useMutation(updateCardingDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-carding-drafts-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const managerUpdateMutation = useMutation(managerUpdateCardingData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-carding-data");
      queryClient.invalidateQueries("all-notification-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const [formData, setFormData] = useState({
    partyCode: "",
    cardingNo: {
      value: [],
      isOutOfNorms: false,
    },
    likerInSpeed: {
      value: "",
      isOutOfNorms: false,
    },
    cylinderSpeed: {
      value: "",
      isOutOfNorms: false,
    },
    flatSpeed: {
      value: "",
      isOutOfNorms: false,
    },
    deliverySpeed: {
      value: "",
      isOutOfNorms: false,
    },
    deliveryHank: {
      value: "",
      isOutOfNorms: false,
    },
    draft: {
      value: "",
      isOutOfNorms: false,
    },
    feedback: "",
  });

  useEffect(() => {
    if (isManager) {
      //this is for showing the date select
      setApplied(true);
      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //only update the partycode
      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        cardingNo: {
          ...prev.cardingNo,
          ...(selectedDataToEdit?.cardingNo?.isOutOfNorms === false && {
            value: selectedDataToEdit?.cardingNo?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.cardingNo?.isOutOfNorms,
        },
        likerInSpeed: {
          ...prev.likerInSpeed,
          ...(selectedDataToEdit?.likerInSpeed?.isOutOfNorms === false && {
            value: selectedDataToEdit?.likerInSpeed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.likerInSpeed?.isOutOfNorms,
        },
        cylinderSpeed: {
          ...prev.cylinderSpeed,
          ...(selectedDataToEdit?.cylinderSpeed?.isOutOfNorms === false && {
            value: selectedDataToEdit?.cylinderSpeed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.cylinderSpeed?.isOutOfNorms,
        },
        flatSpeed: {
          ...prev.flatSpeed,
          ...(selectedDataToEdit?.flatSpeed?.isOutOfNorms === false && {
            value: selectedDataToEdit?.flatSpeed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.flatSpeed?.isOutOfNorms,
        },
        deliverySpeed: {
          ...prev.deliverySpeed,
          ...(selectedDataToEdit?.deliverySpeed?.isOutOfNorms === false && {
            value: selectedDataToEdit?.deliverySpeed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.deliverySpeed?.isOutOfNorms,
        },
        deliveryHank: {
          ...prev.deliveryHank,
          ...(selectedDataToEdit?.deliveryHank?.isOutOfNorms === false && {
            value: selectedDataToEdit?.deliveryHank?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.deliveryHank?.isOutOfNorms,
        },
        draft: {
          ...prev.draft,
          ...(selectedDataToEdit?.draft?.isOutOfNorms === false && {
            value: selectedDataToEdit?.draft?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.draft?.isOutOfNorms,
        },
      }));
    }

    if ((editMode || viewMode) && selectedDataToEdit) {
      //this is for showing the date select
      setApplied(true);

      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //this all other data fetch

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        cardingNo: {
          ...prev.cardingNo,
          value: selectedDataToEdit?.cardingNo?.value,
          isOutOfNorms: selectedDataToEdit?.cardingNo?.isOutOfNorms,
        },
        likerInSpeed: {
          ...prev.likerInSpeed,
          value: selectedDataToEdit?.likerInSpeed?.value,
          isOutOfNorms: selectedDataToEdit?.likerInSpeed?.isOutOfNorms,
        },
        cylinderSpeed: {
          ...prev.cylinderSpeed,
          value: selectedDataToEdit?.cylinderSpeed?.value,
          isOutOfNorms: selectedDataToEdit?.cylinderSpeed?.isOutOfNorms,
        },
        flatSpeed: {
          ...prev.flatSpeed,
          value: selectedDataToEdit?.flatSpeed?.value,
          isOutOfNorms: selectedDataToEdit?.flatSpeed?.isOutOfNorms,
        },
        deliverySpeed: {
          ...prev.deliverySpeed,
          value: selectedDataToEdit?.deliverySpeed?.value,
          isOutOfNorms: selectedDataToEdit?.deliverySpeed?.isOutOfNorms,
        },
        deliveryHank: {
          ...prev.deliveryHank,
          value: selectedDataToEdit?.deliveryHank?.value,
          isOutOfNorms: selectedDataToEdit?.deliveryHank?.isOutOfNorms,
        },
        draft: {
          ...prev.draft,
          value: selectedDataToEdit?.draft?.value,
          isOutOfNorms: selectedDataToEdit?.draft?.isOutOfNorms,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, selectedDataToEdit]);

  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
    setFormData((prev) => ({ ...prev, partyCode: event.target.value }));
  };

  const handleFeedback = (event) => {
    setFormData((prev) => ({ ...prev, feedback: event.target.value.toUpperCase(), }));
  };

  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedData?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedData]);

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    if (name) {
      if (name === "cardingNo") {
        const isOutOfNorms = false;

        setFormData((prevState) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
            isOutOfNorms: isOutOfNorms,
          },
        }));
      } else {
        const isOutOfNorms =
          apiStandardsData?.data?.data?.[name] === value ? false : true;

        setFormData((prevState) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
            isOutOfNorms: isOutOfNorms,
          },
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = selectedDataToEdit
      ? { ...formData, cardingMasterDraftId: selectedDataToEdit?.id }
      : { ...formData };

    const params = {
      payload: { ...formData, isOutOfNorms: false },
      id: selectedDataToEdit?.id,
    };

    if (isManager) {
      managerUpdateMutation.mutate(params);
    } else {
      submitMutation.mutate(payload);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (editMode) {
      const params = {
        payload: { ...formData, variety: apiStandardsData?.data?.variety },
        id: selectedDataToEdit?.id,
      };
      updateMutation.mutate(params);
    } else {
      saveMutation.mutate({
        ...formData,
        variety: apiStandardsData?.data?.variety,
      });
    }
  };

  const handleApply = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false); // Disable the query
    setSelectedDates("");
    setApiStandardsData({});
  };

  const filteredDataFromApi =
    allSubmittedData?.data?.filter(
      (item) => item.partyCode === partyCode && selectedDates === item.date
    ) || [];

  const enableSubmit =
    // formData?.cardingNo?.value?.length !== 0 &&
    formData?.cylinderSpeed?.value !== "" &&
    formData?.deliveryHank?.value !== "" &&
    formData?.deliverySpeed?.value !== "" &&
    formData?.flatSpeed?.value !== "" &&
    formData?.likerInSpeed?.value !== "" &&
    formData?.draft?.value !== "";

  //all outOfNorms shomustuld be false
  const isManagerEnableSubmit =
    !formData?.cardingNo?.isOutOfNorms &&
    !formData?.cylinderSpeed?.isOutOfNorms &&
    !formData?.deliveryHank?.isOutOfNorms &&
    !formData?.deliverySpeed?.isOutOfNorms &&
    !formData?.flatSpeed?.isOutOfNorms &&
    !formData?.likerInSpeed?.isOutOfNorms &&
    !formData?.draft?.isOutOfNorms;

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    apiStandardsData?.data ? apiStandardsData?.data : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    goBack();
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">Add Carding Record</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <p>Party Code: </p>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              disabled={viewMode || editMode || isManager}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handleApply}
            color="primary"
            style={{
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {applied && partyCode && (
            <Select
              value={selectedDates}
              onChange={handleDateChange}
              label="Select Date"
              fullWidth
            >
              {allDates?.map((date) => (
                <MenuItem key={date} value={date}>
                  {dayjs(date).format("DD-MM-YYYY")}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Particulars</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Previous Data
                </Grid>
                <Grid item xs={12}>
                  {selectedDates && dayjs(selectedDates).format("DD-MM-YYYY")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Actual Data
                </Grid>
                <Grid item xs={12}>
                  {isManager
                    ? dayjs(selectedDataToEdit?.date).format("DD-MM-YYYY")
                    : today}
                </Grid>
              </TableCell>
              {isManager && (
                <TableCell>
                  <Grid item xs={12}>
                    Correction Data
                  </Grid>
                  <Grid item xs={12}>
                    {today}
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Carding No</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.cardingNo?.join(", ")}
              </TableCell>
              <TableCell>
                <p>{filteredDataFromApi[0]?.cardingNo?.value?.join(", ")}</p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p>{selectedDataToEdit?.cardingNo?.value?.join(", ")}</p>
                </TableCell>
              )}
              <TableCell>
                <FormControl variant="outlined" style={{ width: "200px" }}>
                  <Select
                    labelId="multiple-select-label"
                    multiple
                    fullWidth
                    name="cardingNo"
                    value={formData?.cardingNo?.value || []}
                    onChange={handleChange}
                    disabled={viewMode || isManager}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {MACHINE_SETTINGS_CARDING_NO?.map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          checked={
                            formData?.cardingNo?.value?.indexOf(value) > -1
                          }
                        />
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lickerin Speed</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.likerInSpeed}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.likerInSpeed?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.likerInSpeed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.likerInSpeed?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.likerInSpeed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="likerInSpeed"
                  variant="outlined"
                  value={formData?.likerInSpeed?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.likerInSpeed?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.likerInSpeed ===
                        formData?.likerInSpeed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cylinder Speed</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.cylinderSpeed}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.cylinderSpeed?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.cylinderSpeed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.cylinderSpeed?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.cylinderSpeed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="cylinderSpeed"
                  variant="outlined"
                  value={formData?.cylinderSpeed?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.cylinderSpeed?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.cylinderSpeed ===
                        formData?.cylinderSpeed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Flat Speed</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.flatSpeed}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.flatSpeed?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.flatSpeed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.flatSpeed?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.flatSpeed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="flatSpeed"
                  variant="outlined"
                  value={formData?.flatSpeed?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.flatSpeed?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.flatSpeed ===
                        formData?.flatSpeed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Speed</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.deliverySpeed}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.deliverySpeed?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.deliverySpeed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.deliverySpeed?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.deliverySpeed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="deliverySpeed"
                  variant="outlined"
                  value={formData?.deliverySpeed?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.deliverySpeed?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.deliverySpeed ===
                        formData?.deliverySpeed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Hank</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.deliveryHank}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.deliveryHank?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.deliveryHank?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.deliveryHank?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.deliveryHank?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="deliveryHank"
                  variant="outlined"
                  value={formData?.deliveryHank?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.deliveryHank?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.deliveryHank ===
                        formData?.deliveryHank?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Draft</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.draft}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.draft?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.draft?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.draft?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.draft?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="draft"
                  variant="outlined"
                  value={formData?.draft?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.draft?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.draft ===
                        formData?.draft?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {!isTelevision && (
        <>
          <TextField
            fullWidth
            name="feedback"
            value={formData.feedback}
            onChange={handleFeedback}
            label="Feedback"
            id="outlined-basic"
            variant="outlined"
            style={{ marginTop: "3%" }}
          />
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: "2%" }}
          >
            {!isManager && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "2%",
                }}
                disabled={
                  !partyCode ||
                  enableSubmit ||
                  updateMutation.isLoading ||
                  saveMutation.isLoading
                }
                onClick={handleSave}
              >
                {updateMutation.isLoading || saveMutation.isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save and Edit Later"
                )}
              </Button>
            )}
            <Button
              variant="contained"
              style={{
                background: "#181E72",
                color: "white",
                textTransform: "none",
              }}
              disabled={
                isManager
                  ? !isManagerEnableSubmit
                  : !enableSubmit ||
                    !partyCode ||
                    viewMode ||
                    managerUpdateMutation.isLoading ||
                    submitMutation.isLoading
              }
              onClick={handleSubmit}
            >
              {managerUpdateMutation.isLoading || submitMutation.isLoading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </>
      )}

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default AddCardingRecord;
