import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { handleToastMsg } from "../../../components/Toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllOthersSubmittedData,
  getAllOthersDraftsData,
  deleteOthersMasterRecord,
  deleteOthersDraftMasterRecord,
} from "./mutation";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog";
import MasterStandardDialog from "../../../components/MasterStandardDialog";
import OtherPreviousEntries from "./OtherPreviousEntries";
import AddOtherRecord from "./AddOtherRecord";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import { Popover, useMediaQuery, useTheme } from "@mui/material";
import CheckOverflow from "../../../utils/CheckOverflow";

function OthersMachineSetting() {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const actionColumnWidth = isMdScreen ? "18vw" : isSmScreen ? "20vw" : "12vw";

  const queryClient = useQueryClient();

  const [activeComponent, setActiveComponent] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState("");
  const [openStdDataDialog, setOpenStdDataDialog] = useState(false);

  const [filterTextSubmittedData, setFilterTextSubmittedData] = useState("");
  const [filterTextDraftsData, setFilterTextDraftsData] = useState("");
  const [selectedDataToEdit, setSelectedDataToEdit] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [deleteType, setDeleteType] = useState("");

  const {
    data: allSubmittedData,
    isLoading: isDataLoading,
    isError: isDataError,
  } = useQuery("all-others-data", getAllOthersSubmittedData);

  const {
    data: allDraftsData,
    isLoading: isDraftLoading,
    isError: isDraftError,
  } = useQuery("all-others-drafts-data", getAllOthersDraftsData);

  const deleteMutation = useMutation(deleteOthersMasterRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-others-data");
      handleToastMsg("Deleted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const deleteMutationDraft = useMutation(deleteOthersDraftMasterRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-others-drafts-data");
      handleToastMsg("Deleted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const handleDraftsFilterChange = (event) => {
    setFilterTextDraftsData(event.target.value);
  };

  const handleSubmittedFilterChange = (event) => {
    setFilterTextSubmittedData(event.target.value);
  };

  const handleAdd = () => {
    setActiveComponent("AddRecord");
    setSelectedDataToEdit({});
    setViewMode(false);
    setEditMode(false);
  };

  const handleEdit = (rowData) => {
    setActiveComponent("AddRecord");
    setSelectedDataToEdit(rowData);
    setViewMode(false);
    setEditMode(true);
  };

  const handleView = (rowData) => {
    setActiveComponent("AddRecord");
    setSelectedDataToEdit(rowData);
    setViewMode(true);
    setEditMode(false);
  };

  //this is for pretend the rerender issue when we give checkoverflow params
  const [submittedFilteredData, setSubmittedFilteredData] = useState([]);
  useEffect(() => {
    setSubmittedFilteredData(
      allSubmittedData?.data?.filter((row) =>
        row?.partyCode
          ?.toLowerCase()
          .includes(filterTextSubmittedData?.toLowerCase())
      ) || []
    );
  }, [allSubmittedData, filterTextSubmittedData]);

  //this is for pretend the rerender issue when we give checkoverflow params
  const [draftFilteredData, setDraftFilteredData] = useState([]);
  useEffect(() => {
    setDraftFilteredData(
      allDraftsData?.data?.filter((row) =>
        row?.partyCode
          ?.toLowerCase()
          .includes(filterTextDraftsData?.toLowerCase())
      ) || []
    );
  }, [allDraftsData, filterTextDraftsData]);

  //this function for checking the text is being overflow or not
  const [isOverflowDraft, addDraftRef] = CheckOverflow(draftFilteredData);
  const [isOverflowSubmitted, addSubmittedRef] = CheckOverflow(
    submittedFilteredData
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflowDraft[id] || isOverflowSubmitted[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  if (isDataLoading || isDraftLoading) {
    return (
      <Grid
        container
        item
        md={12}
        xs={12}
        alignContent="flex-start"
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          marginTop: "1%",
          paddingBottom: "4%",
        }}
      >
        <Grid container item md={12} xs={12} justifyContent="space-between">
          <Grid item md={9}>
            <h3>LIST OF MACHINE SETTING MASTERS</h3>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <h4>Loading...</h4>
        </Grid>
      </Grid>
    );
  }

  if (isDataError || isDraftError) {
    return (
      <Grid
        container
        item
        md={12}
        xs={12}
        alignContent="flex-start"
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          marginTop: "1%",
          paddingBottom: "4%",
        }}
      >
        <Grid container item md={12} xs={12} justifyContent="space-between">
          <Grid item md={9}>
            <h3>LIST OF MACHINE SETTING MASTERS</h3>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <h4>Error Loading Records</h4>
        </Grid>
      </Grid>
    );
  }

  const renderComponent = () => {
    switch (activeComponent) {
      case "PreviousData":
        return <OtherPreviousEntries goBack={() => setActiveComponent(null)} />;
      case "AddRecord":
        return (
          <AddOtherRecord
            selectedDataToEdit={selectedDataToEdit}
            goBack={() => setActiveComponent(null)}
            viewMode={viewMode}
            editMode={editMode}
          />
        );
      default:
        return (
          <Grid container item md={12} xs={12} alignContent="flex-start">
            <Grid
              container
              item
              md={12}
              xs={12}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Grid item md={6} xs={6}>
                <h3>OTHERS - MACHINE SETTINGS</h3>
              </Grid>
              <Grid item md={5} xs={6} container justifyContent="space-between">
                <Button
                  variant="contained"
                  className="view-btn"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={handleOpenStdDataDialog}
                >
                  View Standards
                </Button>
                <Link to="">
                  <Button
                    variant="contained"
                    className="add-btn"
                    color="primary"
                    style={{
                      // backgroundColor: "#87bfed",
                      textTransform: "none",
                    }}
                    onClick={() => setActiveComponent("PreviousData")}
                  >
                    View Previous Entries
                  </Button>
                </Link>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={12}
              style={{ marginTop: "3%" }}
              alignContent="flex-start"
            >
              <Grid container item md={12} xs={12}>
                <Grid
                  container
                  item
                  md={10}
                  xs={8}
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>Drafts Data</h3>
                </Grid>
                <Grid container item md={2} xs={4} justifyContent="flex-end">
                  <TextField
                    label="Filter by Party Code"
                    variant="outlined"
                    fullWidth
                    value={filterTextDraftsData}
                    onChange={handleDraftsFilterChange}
                  />
                </Grid>
              </Grid>
              <TableContainer component={Paper} style={{ marginTop: "4%" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ maxWidth: "300px" }}>Date</TableCell>
                      <TableCell style={{ maxWidth: "200px" }}>
                        Party Code
                      </TableCell>
                      <TableCell style={{ maxWidth: "200px" }}>
                        Variety
                      </TableCell>
                      <TableCell style={{ width: actionColumnWidth }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {draftFilteredData?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell style={{ maxWidth: "300px" }}>
                          {dayjs(row?.date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell style={{ maxWidth: "100px" }}>
                          <p
                            className="overflowContain"
                            ref={addDraftRef(`draftCode-${row.id}`)}
                            onMouseEnter={(e) =>
                              handlePopoverOpen(
                                e,
                                `draftCode-${row.id}`,
                                row.partyCode
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            {row.partyCode}
                          </p>
                        </TableCell>
                        <TableCell style={{ maxWidth: "100px" }}>
                          <p
                            className="overflowContain"
                            ref={addDraftRef(`draftVariety-${row.id}`)}
                            onMouseEnter={(e) =>
                              handlePopoverOpen(
                                e,
                                `draftVariety-${row.id}`,
                                row.variety
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            {row.variety}
                          </p>
                        </TableCell>
                        <TableCell style={{ width: actionColumnWidth }}>
                          <Grid item>
                            <Button
                              variant="contained"
                              className="edit-delete-btn"
                              onClick={() => handleEdit(row)}
                              style={{
                                backgroundColor: "rgba(226, 229, 232, 0)",
                                marginRight: "4%",
                              }}
                            >
                              <ModeEditIcon style={{ color: "green" }} />
                            </Button>
                            <Button
                              variant="contained"
                              className="edit-delete-btn"
                              style={{
                                backgroundColor: "rgba(226, 229, 232, 0)",
                              }}
                              onClick={() =>
                                handleOpenDeleteDialog(row.id, "draft")
                              }
                            >
                              <DeleteIcon
                                style={{ color: "rgb(167, 27, 34)" }}
                              />
                            </Button>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              container
              item
              md={12}
              style={{ marginTop: "3%" }}
              alignContent="flex-start"
            >
              <Grid container item md={12} xs={12}>
                <Grid
                  container
                  item
                  md={10}
                  xs={8}
                  alignItems="center"
                  justifyContent="center"
                >
                  <h3>Submitted Data</h3>
                </Grid>
                <Grid container item md={2} xs={4} justifyContent="flex-end">
                  <TextField
                    label="Filter by Party Code"
                    variant="outlined"
                    fullWidth
                    value={filterTextSubmittedData}
                    onChange={handleSubmittedFilterChange}
                  />
                </Grid>
              </Grid>

              <TableContainer component={Paper} style={{ marginTop: "4%" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ maxWidth: "300px" }}>Date</TableCell>
                      <TableCell style={{ maxWidth: "200px" }}>
                        Party Code
                      </TableCell>
                      <TableCell style={{ maxWidth: "200px" }}>
                        Variety
                      </TableCell>
                      <TableCell style={{ width: actionColumnWidth }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {submittedFilteredData?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell style={{ maxWidth: "300px" }}>
                          {dayjs(row?.date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell style={{ maxWidth: "100px" }}>
                          <p
                            className="overflowContain"
                            ref={addSubmittedRef(`submittedCode-${row.id}`)}
                            onMouseEnter={(e) =>
                              handlePopoverOpen(
                                e,
                                `submittedCode-${row.id}`,
                                row.partyCode
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            {row.partyCode}
                          </p>
                        </TableCell>
                        <TableCell style={{ maxWidth: "100px" }}>
                          <p
                            className="overflowContain"
                            ref={addSubmittedRef(`submittedVariety-${row.id}`)}
                            onMouseEnter={(e) =>
                              handlePopoverOpen(
                                e,
                                `submittedVariety-${row.id}`,
                                row.variety
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            {row.variety}
                          </p>
                        </TableCell>
                        <TableCell style={{ width: actionColumnWidth }}>
                          <Grid item>
                            <Button
                              variant="contained"
                              className="edit-delete-btn"
                              onClick={() => handleView(row)}
                              style={{
                                backgroundColor: "rgba(226, 229, 232, 0)",
                                marginRight: "4%",
                              }}
                            >
                              <VisibilityIcon style={{ color: "green" }} />
                            </Button>
                            <Button
                              variant="contained"
                              className="edit-delete-btn"
                              style={{
                                backgroundColor: "rgba(226, 229, 232, 0)",
                              }}
                              onClick={() =>
                                handleOpenDeleteDialog(row.id, "submittedData")
                              }
                            >
                              <DeleteIcon
                                style={{ color: "rgb(167, 27, 34)" }}
                              />
                            </Button>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <p style={{ padding: "15px" }}>{popoverContent}</p>
            </Popover>

            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "4%" }}
            >
              <Button
                variant="contained"
                style={{ textTransform: "none" }}
                color="primary"
                onClick={() => handleAdd()}
              >
                Add Record
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  const handleOpenDeleteDialog = (id, type) => {
    setSelectedIdToDelete(id);
    setDeleteType(type);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setDeleteType("");
  };

  const handleDeleteConfirmed = () => {
    if (deleteType === "draft") {
      deleteMutationDraft.mutate(selectedIdToDelete);
    } else if (deleteType === "submittedData") {
      deleteMutation.mutate(selectedIdToDelete);
    }
    handleCloseDeleteDialog();
  };

  const handleOpenStdDataDialog = () => {
    setOpenStdDataDialog(true);
  };

  const handleCloseStdDataDialog = () => {
    setOpenStdDataDialog(false);
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "2%",
        paddingBottom: "4%",
      }}
    >
      {renderComponent()}
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
      />
      <MasterStandardDialog
        open={openStdDataDialog}
        onClose={handleCloseStdDataDialog}
        heading="Others Standard Dialog"
      />

      <DialogForLoadingMachine
        open={deleteMutationDraft.isLoading || deleteMutation.isLoading}
      />
    </Grid>
  );
}

export default OthersMachineSetting;
