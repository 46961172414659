import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

const BlowroomMasterForm = ({ blowroom, setBlowroom }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setBlowroom((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>RK Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="rkSpeed"
                    variant="outlined"
                    value={blowroom.rkSpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Grid Bar</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="gridBar"
                  variant="outlined"
                  value={blowroom.gridBar}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>KB Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="kbSpeed"
                    variant="outlined"
                    value={blowroom.kbSpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lap Length</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="lapLength"
                    variant="outlined"
                    value={blowroom.lapLength}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MTS</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lap Weight</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="lapWeight"
                    variant="outlined"
                    value={blowroom.lapWeight}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>KG</span>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default BlowroomMasterForm;
