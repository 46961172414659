import apiClient from "../../../utils/clients/axios";

// Create a new record in the drawing master
export const submitDrawingData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/drawing_master`,
    payload
  );
  return response;
};

// Create a new record in the draft drawing master
export const saveDrawingDraftsData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/drawing_master/createDrawingMasterDraft`,
    payload
  );
  return response;
};

// update a new record in the drawing master
export const updateDrawingDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/drawing_master/updateDrawingMasterDraft/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateDrawingData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/drawing_master/${id}`,
    payload
  );
  return response;
};

// Get all records from the drawing master
export const getAllDrawingSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/drawing_master`);
  return response;
};

// Get all drafts records from the drawing master
export const getAllDrawingDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/drawing_master/getAllDrawingMastersDraft`
  );
  return response;
};

// Get a specific record from the drawing master by ID
export const getDrawingMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/drawing_master/${id}`
  );
  return response;
};

// Update a specific record in the drawing master by ID
export const updateDrawingMasterRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/drawing_master/${id}`,
    data
  );
  return response;
};

// Delete a specific record from the drawing master by ID
export const deleteDrawingMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/drawing_master/${id}`
  );
  return response;
};

// Delete a specific record from the drawing master by ID
export const deleteDrawingDraftMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/drawing_master/deleteDrawingMasterDraft/${id}`
  );
  return response;
};
