import React, { useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Table,
  TableRow,
  TableContainer,
  Paper,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  FormGroup,
  CircularProgress,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-moment";
import * as XLSX from "xlsx";
import apiClient from "../../../utils/clients/axios";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";

function CotCheckingReport() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    varietyEnabled: false,
    cardingMachineNoEnabled: false,
    cardingMachineNo: [],
    cardingLineNoEnabled: false,
    cardingLineNo: null,
    lhs: false,
    rhs: false,
  });

  const [cardingMachineNoOptions] = useState(
    Array.from({ length: 24 }, (_, i) => i + 1)
  );

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //handle for chckbox
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formatDate = (date, endOfDay = false) => {
  //     const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  //     const formattedDate = adjustedDate.toISOString().split("T")[0];
  //     if (endOfDay) {
  //       return formattedDate + 'T23:59:59';
  //     } else {
  //       return formattedDate + 'T00:00:00';
  //     }
  //   };

  //   const formattedFromDate = formData.from_date
  //     ? formatDate(new Date(formData.from_date))
  //     : "";

  //   const formattedToDate = formData.to_date
  //     ? formatDate(new Date(formData.to_date), true)
  //     : "";

  //   //this is for send the data as a single element from the array
  //   const cardingMacinesNo = formData?.cardingMachineNo?.join(",") || "";

  //   try {
  //     const response = await apiClient.get(
  //       `api/quality_control/spinning/cot_checks_report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
  //       {
  //         params: {
  //           ...(formData?.reportType === "consolidated"
  //             ? { consolidated: true }
  //             : {
  //                 machineNo: cardingMacinesNo,
  //                 lhs: formData?.lhs,
  //                 rhs: formData?.rhs,
  //               }),
  //         },
  //       }
  //     );

  //     let headers = new Set();
  //     let dataRows = [];

  //     const flattenObject = (obj, parentKey = '') => {
  //       return Object.entries(obj).reduce((acc, [key, value]) => {
  //         const newKey = parentKey ? `${parentKey}_${key}` : key;
  //         if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
  //           Object.assign(acc, flattenObject(value, newKey));
  //         } else {
  //           acc[newKey] = value;
  //         }
  //         return acc;
  //       }, {});
  //     };

  //     response.data.forEach((item) => {
  //       let row = {};
  //       Object.entries(item).forEach(([key, value]) => {
  //         if (Array.isArray(value)) {
  //           value.forEach((obj, index) => {
  //             const flatObj = flattenObject(obj, `${key}_${index + 1}`);
  //             Object.entries(flatObj).forEach(([subKey, subValue]) => {
  //               headers.add(subKey);
  //               row[subKey] = subValue;
  //             });
  //           });
  //         } else if (typeof value === 'object' && value !== null) {
  //           const flatObj = flattenObject(value, key);
  //           Object.entries(flatObj).forEach(([subKey, subValue]) => {
  //             headers.add(subKey);
  //             row[subKey] = subValue;
  //           });
  //         } else {
  //           headers.add(key);
  //           row[key] = value;
  //         }
  //       });
  //       dataRows.push(row);
  //     });

  //     headers = Array.from(headers);

  //     const priorityHeaders = ["hall",'feedback',"id", 'created_at', 'updated_at',"employeeId"];
  //     headers = headers.filter(header => !priorityHeaders.includes(header)).concat(priorityHeaders);

  //     const worksheetData = [headers];

  //     dataRows.forEach((row) => {
  //       const rowData = headers.map((header) => row[header] || '');
  //       worksheetData.push(rowData);
  //     });

  //     const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //     XLSX.writeFile(workbook, "Cot_checking_report.xlsx");
  //     handleToastMsg("Reports downloaded successfully", "success");
  //   } catch (error) {
  //     handleToastMsg("Data Error Occured", "error");
  //   }
  // };

  const [loading, setLoading] = useState(false);

  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    //this is for send the data as a single element from the array
    const cardingMacinesNo = formData?.cardingMachineNo?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/spinning/cot_checks_report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true }
              : {
                  machineNo: cardingMacinesNo,
                  lhs: formData?.lhs,
                  rhs: formData?.rhs,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      const workbook = XLSX.utils.book_new();
      const worksheetData = [];

      if (formData.reportType === "individual") {
        response?.data?.forEach((item) => {
          if (item.createdAt)
            worksheetData.push([
              "Date",
              dayjs(item.createdAt).format("DD-MM-YYYY"),
            ]);
          if (item.machine_number)
            worksheetData.push(["Machine No", item.machine_number]);
          if (item.lhs) worksheetData.push(["LHS", item.lhs]);
          if (item.rhs) worksheetData.push(["RHS", item.rhs]);
          if (item.remarks) worksheetData.push(["Remarks", item.remarks]);
          worksheetData.push([]);
        });
      } else if (formData.reportType === "consolidated") {
        response?.data?.forEach((item) => {
          worksheetData.push([
            "Date",
            dayjs(item.createdAt).format("DD-MM-YYYY"),
          ]);
          worksheetData.push(["Total LHS", item.total_lhs]);
          worksheetData.push(["Total RHS", item.total_rhs]);
          worksheetData.push(["Hall", item.hall]);
          worksheetData.push(["Feedback", item.feedback]);
          worksheetData.push([]);

          worksheetData.push(["Cot buff records"]);
          worksheetData.push([
            "No of cots",
            "Cot buffing",
            "Remarks",
            "Feedback",
          ]);
          item?.CotBuffs?.forEach((buffs) => {
            worksheetData.push([
              buffs.number_of_cots,
              buffs.cot_buffing,
              buffs.remarks,
              buffs.feedback,
            ]);
          });
          worksheetData.push([]);

          worksheetData.push(["Cot check records"]);
          worksheetData.push(["Machine Number", "LHS", "RHS", "Remarks"]);
          item?.CotCheckRecords?.forEach((sample) => {
            worksheetData.push([
              sample.machine_number,
              sample.lhs,
              sample.rhs,
              sample.remarks,
            ]);
          });
          worksheetData.push([]);
          worksheetData.push([]);
        });
      }

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "COT-CHECKING-REPORTS.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerWrapper container rowGap={2} height={0}>
        <Grid item xs={12}>
          <h2>Cot Checking Report</h2>
        </Grid>
        <Grid item container justifyContent={"space-between"} xs={12} md={8}>
          <Grid item>
            <RadioGroup
              name="reportType"
              value={formData.reportType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="individual"
                control={<Radio />}
                label="Individual Report"
              />
              <FormControlLabel
                value="consolidated"
                control={<Radio />}
                label="Consolidated Report"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container mb={3}>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} alignItems={"center"}>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table>
              <TableRow>
                <TableCell>Carding Machine No</TableCell>
                <TableCell style={{ width: 500 }}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.cardingMachineNo}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event) =>
                        handleInputChange(
                          "cardingMachineNo",
                          event.target.value
                        )
                      }
                    >
                      {cardingMachineNoOptions.map((machineNo) => (
                        <MenuItem key={machineNo} value={machineNo}>
                          <Checkbox
                            checked={
                              formData?.cardingMachineNo?.indexOf(machineNo) >
                              -1
                            }
                          />
                          <ListItemText primary={machineNo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Select Side</TableCell>
                <TableCell style={{ width: 500 }}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.lhs}
                          onChange={handleCheckboxChange}
                          name="lhs"
                          disabled={formData.reportType === "consolidated"}
                        />
                      }
                      label="LHS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.rhs}
                          onChange={handleCheckboxChange}
                          name="rhs"
                          disabled={formData.reportType === "consolidated"}
                        />
                      }
                      label="RHS"
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
          {/* <Grid item xs={3}>
                        <Input
                            value={formData.variety}
                            onChange={(e) => handleFormChange(e, 'variety')}
                            disabled={!formData.varietyEnabled}
                        />
                    </Grid> */}
        </Grid>
        <Grid item container alignItems={"center"}>
          {/* <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"cardingMachineNoEnabled"}
                                    checked={formData.cardingMachineNoEnabled}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Carding Machine No"
                        />
                    </Grid> */}
        </Grid>
        {/* <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"cardingLineNoEnabled"}
                                    checked={formData.cardingLineNoEnabled}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Carding Line No"
                        />
                    </Grid>
                  
                </Grid> */}
        <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Generate"
            )}
          </CustomButton>
        </Grid>
      </ContainerWrapper>
    </form>
  );
}

export default CotCheckingReport;
