import apiClient from "../../utils/clients/axios";

// only when all fields are filled and user clicks on submit button
export const createMasterRecord = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/master_data`,
    payload
  );
  return response;
};

// in case all fields are not filled or user clicks on save button
export const createSavedMasterRecord = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/master_data/createMasterDataDraft`,
    payload
  );
  return response;
};

export const updateSubmittedRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/master_data/${id}`,
    data
  );
  return response;
};

export const updateSavedRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/master_data/updateMasterDataDraft/${id}`,
    data
  );
  return response;
};
