import React, { useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-moment";
import apiClient from "../../../utils/clients/axios";
import { handleToastMsg } from "../../../components/Toast";
import { DEPARTMENTS_TASKSHEETS, HALL_NO } from "../../../config/masterdata";
import dayjs from "dayjs";
import ExcelJS from "exceljs";

function TaskSheetsReport() {
  const [formData, setFormData] = useState({
    from_date: null,
    to_date: null,
    type: "",
    ALL: false,
    PENDING: false,
    RESOLVED: false,
  });

  const [departments, setDepartments] = useState([]);
  const [hallNo, setHallNo] = useState([]);

  const [allDepartments] = useState(["ALL", ...DEPARTMENTS_TASKSHEETS]);

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //handle for chckbox
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Reset all checkboxes
    const newFormData = {
      ...formData,
      ALL: false,
      PENDING: false,
      RESOLVED: false,
    };

    // Set the selected checkbox
    newFormData[name] = checked;

    // Update the type value based on the selected checkbox
    if (checked) {
      newFormData.type = name;
    } else {
      newFormData.type = "";
    }

    setFormData(newFormData);
  };

  //handle submit
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formatDate = (date, endOfDay = false) => {
  //     const adjustedDate = new Date(
  //       date.getTime() - date.getTimezoneOffset() * 60000
  //     );
  //     const formattedDate = adjustedDate.toISOString().split("T")[0];
  //     if (endOfDay) {
  //       return formattedDate + "T23:59:59";
  //     } else {
  //       return formattedDate + "T00:00:00";
  //     }
  //   };

  //   const formattedFromDate = formData.from_date
  //     ? formatDate(new Date(formData.from_date))
  //     : "";

  //   const formattedToDate = formData.to_date
  //     ? formatDate(new Date(formData.to_date), true)
  //     : "";

  //   const departmentForApi = departments?.map((item) => item).join(",") || "";

  //   try {
  //     const response = await apiClient.get(
  //       `api/quality_control/report_issue/report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
  //       {
  //         params: {
  //           type: formData?.type,
  //           department: departmentForApi,
  //         },
  //       }
  //     );

  //     const workbook = XLSX.utils.book_new();
  //     const worksheetData = [];

  //     const generateDynamicHeaders = (maxManagers) => {
  //       const dynamicHeaders = [];
  //       for (let i = 1; i <= maxManagers; i++) {
  //         dynamicHeaders.push(`Solution Manager ${i}`);
  //         dynamicHeaders.push(`Solution Date`);
  //         dynamicHeaders.push(`Seeked Date`);
  //       }
  //       return dynamicHeaders;
  //     };

  //     const extraDynamicHeading = response?.data?.map(
  //       (item) => item?.reportingManager?.length || 0
  //     );

  //     const maximumNoOfManagers = Math.max(...extraDynamicHeading);
  //     const dynamicHeaders = generateDynamicHeaders(maximumNoOfManagers);

  //     worksheetData.push([
  //       "Sl No",
  //       "Issue Date",
  //       "Reported By",
  //       "Department",
  //       "Reporting Manager",
  //       "Description",
  //       "Image",
  //       "Status",
  //       ...dynamicHeaders,
  //     ]); // Add headers

  //     const addDataWithTitleAndHeaders = (record) => {
  //       const extraAddHeading = record?.reportingManagerSolution?.reduce(
  //         (acc, item) => {
  //           const managerNameAndSolution =
  //             item.name && item.solution
  //               ? `${item?.name} : ${item?.solution}`
  //               : "NA";
  //           const managerSolutionDate = item.solutionDate
  //             ? dayjs(item?.solutionDate).format("DD-MM-YYYY")
  //             : "NA";
  //           const managerSeekedDate = item?.seekedDate
  //             ? dayjs(item?.seekedDate).format("DD-MM-YYYY")
  //             : "NA";

  //           acc.push(managerNameAndSolution);
  //           acc.push(managerSolutionDate);
  //           acc.push(managerSeekedDate);

  //           return acc;
  //         },
  //         []
  //       );

  //       //this is for to show different whether the manager is assign or not
  //       let updatedOne = [];
  //       if (maximumNoOfManagers > record?.reportingManagerSolution?.length) {
  //         const count =
  //           maximumNoOfManagers - record?.reportingManagerSolution?.length;

  //         for (let i = 0; i < count; i++) {
  //           updatedOne.push("-");
  //           updatedOne.push("-");
  //           updatedOne.push("-");
  //         }
  //       }

  //       const managersRespondsHeading =
  //         maximumNoOfManagers === record?.reportingManagerSolution?.length
  //           ? extraAddHeading
  //           : [...extraAddHeading, ...updatedOne];

  //       const formattedDate = dayjs(record?.date).format("DD-MM-YYYY");

  //       worksheetData.push([
  //         record?.slNo,
  //         formattedDate,
  //         record?.reportedBy,
  //         record?.department?.map((item) => item).join(", "),
  //         record?.reportingManager?.map((item) => item?.name).join(", "),
  //         record?.description,
  //         record?.img,
  //         record?.status,
  //         ...managersRespondsHeading,
  //       ]);
  //       worksheetData.push([]); // Add an extra row for spacing after all records for the same date
  //     };

  //     response?.data?.map((item) => {
  //       return addDataWithTitleAndHeaders(item);
  //     });

  //     const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Consolidated Data");

  //     const excelBuffer = XLSX.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });
  //     const blob = new Blob([excelBuffer], {
  //       type: "application/octet-stream",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "TASK-SHEETS.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     handleToastMsg("Data Error Occurred", "error");
  //   }
  // };

  const [loading, setLoading] = useState(false);

  //this method is used for show image in the excel sheets
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formatDate = (date, endOfDay = false) => {
  //     const adjustedDate = new Date(
  //       date.getTime() - date.getTimezoneOffset() * 60000
  //     );
  //     const formattedDate = adjustedDate.toISOString().split("T")[0];
  //     if (endOfDay) {
  //       return formattedDate + "T23:59:59";
  //     } else {
  //       return formattedDate + "T00:00:00";
  //     }
  //   };

  //   const formattedFromDate = formData.from_date
  //     ? formatDate(new Date(formData.from_date))
  //     : "";

  //   const formattedToDate = formData.to_date
  //     ? formatDate(new Date(formData.to_date), true)
  //     : "";

  //   const departmentForApi = departments?.map((item) => item).join(",") || "";
  //   const hallForApi = hallNo?.map((item) => item).join(",") || "";

  //   try {
  //     setLoading(true);

  //     const response = await apiClient.get(
  //       `api/quality_control/report_issue/report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
  //       {
  //         params: {
  //           type: formData?.type,
  //           department: departmentForApi,
  //           hallNo: hallForApi,
  //         },
  //       }
  //     );

  //     if (response?.data?.length === 0) {
  //       handleToastMsg("No data available", "info");
  //       return;
  //     }

  //     const workbook = new ExcelJS.Workbook();
  //     const sheet = workbook.addWorksheet("Consolidated Data");

  //     const generateDynamicHeaders = (maxManagers) => {
  //       const dynamicHeaders = [];
  //       for (let i = 1; i <= maxManagers; i++) {
  //         dynamicHeaders.push(
  //           `Solution Manager ${i}`,
  //           "Solution Date",
  //           "Seeked Date",
  //           "Solved Image"
  //         );
  //       }
  //       return dynamicHeaders;
  //     };

  //     const extraDynamicHeading = response?.data?.map(
  //       (item) => item?.reportingManager?.length || 0
  //     );
  //     const maximumNoOfManagers = Math.max(...extraDynamicHeading);
  //     const dynamicHeaders = generateDynamicHeaders(maximumNoOfManagers);

  //     const headers = [
  //       "Sl No",
  //       "Hall",
  //       "Issue Date",
  //       "Reported By",
  //       "Department",
  //       "Reporting Manager",
  //       "Description",
  //       "Image",
  //       "Status",
  //       ...dynamicHeaders,
  //     ];

  //     sheet.addRow(headers);

  //     const addDataWithTitleAndHeaders = (record, rowIndex) => {
  //       const extraAddHeading = record?.reportingManagerSolution?.reduce(
  //         (acc, item, index) => {
  //           const managerNameAndSolution =
  //             item.name && item.solution
  //               ? `${item?.name} : ${item?.solution}`
  //               : "NA";
  //           const managerSolutionDate = item.solutionDate
  //             ? dayjs(item?.solutionDate).format("DD-MM-YYYY")
  //             : "NA";
  //           const managerSeekedDate = item?.seekedDate
  //             ? dayjs(item?.seekedDate).format("DD-MM-YYYY")
  //             : "NA";
  //           // const managerImage = item?.image ? item?.image : "NA";

  //           acc.push(managerNameAndSolution);
  //           acc.push(managerSolutionDate);
  //           acc.push(managerSeekedDate);
  //           acc.push("");

  //           return acc;
  //         },
  //         []
  //       );

  //       let updatedOne = [];
  //       if (maximumNoOfManagers > record?.reportingManagerSolution?.length) {
  //         const count =
  //           maximumNoOfManagers - record?.reportingManagerSolution?.length;

  //         for (let i = 0; i < count; i++) {
  //           updatedOne.push("-", "-", "-", "-");
  //         }
  //       }

  //       const managersRespondsHeading =
  //         maximumNoOfManagers === record?.reportingManagerSolution?.length
  //           ? extraAddHeading
  //           : [...extraAddHeading, ...updatedOne];

  //       const formattedDate = dayjs(record?.created_at).format(
  //         "DD-MM-YYYY, hh:mm A"
  //       );

  //       const rowData = [
  //         record?.slNo,
  //         record?.hall,
  //         formattedDate,
  //         record?.reportedBy,
  //         record?.department?.map((item) => item).join(", "),
  //         record?.reportingManager?.map((item) => item?.name).join(", "),
  //         record?.description,
  //         "", // if there is no image
  //         record?.status,
  //         ...managersRespondsHeading,
  //       ];

  //       sheet.addRow(rowData);

  //       //this is for adding manager's responding image dynamically
  //       record?.reportingManagerSolution?.reduce(async (acc, item, index) => {
  //         const managerImage = item?.image ? item?.image : "NA";
  //         const currentColumn = 9 + (index + 1) * 4; // Dynamically calculate the column for each manager's image

  //         if (managerImage !== "NA") {
  //           const imageId = workbook.addImage({
  //             filename: `/solvedImage/${managerImage}`,
  //             extension: "png", // Adjust based on the actual image type
  //           });
  //           console.log(imageId);

  //           sheet.addImage(imageId, {
  //             tl: { col: currentColumn - 1, row: rowIndex }, // Adjust based on calculated column and rowIndex
  //             ext: { width: 100, height: 90 },
  //           });

  //           // Adjust column width and row height to fit the image
  //           sheet.getColumn(currentColumn).width = 20; // Adjust the column width dynamically
  //           sheet.getRow(rowIndex + 1).height = 100; // Adjust the row height to fit the image
  //         } else if (managerImage === "NA") {
  //           // Insert text "No Image" if there is no image
  //           const cell = sheet.getCell(
  //             `${String.fromCharCode(64 + currentColumn)}${rowIndex + 1}`
  //           ); // Calculate the cell dynamically

  //           cell.value = "NA";
  //         }

  //         return acc;
  //       }, []);

  //       //this is for adding reported image
  //       if (record?.image) {
  //         const imageId = workbook.addImage({
  //           filename: `/image/${record?.image}`,
  //           extension: "png", // Adjust based on the actual image type
  //         });

  //         sheet.addImage(imageId, {
  //           tl: { col: 7, row: rowIndex }, // Adjust the column and row indices
  //           ext: { width: 100, height: 90 },
  //         });

  //         // Adjust column width and row height to fit the image
  //         sheet.getColumn(8).width = 20; // Adjust the column width to fit the image
  //         sheet.getRow(rowIndex + 1).height = 100; // Adjust the row height to fit the image
  //       } else {
  //         // Insert text "No Image" if there is no image
  //         const cell = sheet.getCell(`H${rowIndex + 1}`); // Assuming column G is the image column
  //         cell.value = "No Image";
  //       }
  //     };

  //     response?.data?.forEach((item, index) => {
  //       addDataWithTitleAndHeaders(item, index + 1); // Starting from row 2 to account for the header row
  //     });

  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], { type: "application/octet-stream" });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "TASK-SHEETS-REPORTS.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     handleToastMsg("Reports downloaded successfully", "success");
  //   } catch (error) {
  //     handleToastMsg("Data Error Occurred", "error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      return endOfDay
        ? formattedDate + "T23:59:59"
        : formattedDate + "T00:00:00";
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";
    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    const departmentForApi = departments?.map((item) => item).join(",") || "";
    const hallForApi = hallNo?.map((item) => item).join(",") || "";

    try {
      setLoading(true);

      const response = await apiClient.get(
        `api/quality_control/report_issue/report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            type: formData?.type,
            department: departmentForApi,
            hallNo: hallForApi,
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Consolidated Data");

      const generateDynamicHeaders = (maxManagers) => {
        const dynamicHeaders = [];
        for (let i = 1; i <= maxManagers; i++) {
          dynamicHeaders.push(
            `Solution Manager ${i}`,
            "Solution Date",
            "Seeked Date",
            "Solved Image"
          );
        }
        return dynamicHeaders;
      };

      const extraDynamicHeading = response?.data?.map(
        (item) => item?.reportingManager?.length || 0
      );
      const maximumNoOfManagers = Math.max(...extraDynamicHeading);
      const dynamicHeaders = generateDynamicHeaders(maximumNoOfManagers);

      const headers = [
        "Sl No",
        "Hall",
        "Issue Date",
        "Reported By",
        "Department",
        "Reporting Manager",
        "Description",
        "Image",
        "Status",
        ...dynamicHeaders,
      ];

      sheet.addRow(headers);

      const addDataWithTitleAndHeaders = async (record, rowIndex) => {
        const extraAddHeading = record?.reportingManagerSolution?.reduce(
          (acc, item, index) => {
            const managerNameAndSolution =
              item.name && item.solution
                ? `${item?.name} : ${item?.solution}`
                : "NA";

            //this is for showing time also in the reports for solution date only
            const managerSolutionDate = item.solutionDate
              ? dayjs(item?.solutionDate).format("DD-MM-YYYY, hh:mm A")
              : "NA";

            const managerSeekedDate = item?.seekedDate
              ? dayjs(item?.seekedDate).format("DD-MM-YYYY")
              : "NA";

            acc.push(managerNameAndSolution);
            acc.push(managerSolutionDate);
            acc.push(managerSeekedDate);
            acc.push("");

            return acc;
          },
          []
        );

        let updatedOne = [];
        if (maximumNoOfManagers > record?.reportingManagerSolution?.length) {
          const count =
            maximumNoOfManagers - record?.reportingManagerSolution?.length;

          for (let i = 0; i < count; i++) {
            updatedOne.push("-", "-", "-", "-");
          }
        }

        const managersRespondsHeading =
          maximumNoOfManagers === record?.reportingManagerSolution?.length
            ? extraAddHeading
            : [...extraAddHeading, ...updatedOne];

        const formattedDate = dayjs(record?.created_at).format(
          "DD-MM-YYYY, hh:mm A"
        );

        const rowData = [
          record?.slNo,
          record?.hall,
          formattedDate,
          record?.reportedBy,
          record?.department?.map((item) => item).join(", "),
          record?.reportingManager?.map((item) => item?.name).join(", "),
          record?.description,
          "", // if there is no image
          record?.status,
          ...managersRespondsHeading,
        ];

        sheet.addRow(rowData);

        // Function to convert image URL to Base64
        const urlToBase64 = async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();

          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
        };

        // Adding manager's responding images dynamically
        for (let i = 0; i < record?.reportingManagerSolution?.length; i++) {
          const item = record.reportingManagerSolution[i];
          const managerImage = item?.image ? item?.image : "NA";
          const currentColumn = 9 + (i + 1) * 4; // Dynamically calculate the column

          if (managerImage !== "NA") {
            const base64Image = await urlToBase64(managerImage);

            // console.log(base64Image);

            const imageId = workbook.addImage({
              base64: base64Image.split(",")[1], // Removing the data:image/png;base64, part
              extension: "png", // Adjust based on the actual image type
            });

            sheet.addImage(imageId, {
              tl: { col: currentColumn - 1, row: rowIndex },
              ext: { width: 100, height: 90 },
            });

            sheet.getColumn(currentColumn).width = 20;
            sheet.getRow(rowIndex + 1).height = 100;
          } else {
            const cell = sheet.getCell(
              `${String.fromCharCode(64 + currentColumn)}${rowIndex + 1}`
            );
            cell.value = "NA";
          }
        }

        // Adding reported image
        if (record?.image) {
          const base64Image = await urlToBase64(record?.image);
          const imageId = workbook.addImage({
            base64: base64Image.split(",")[1],
            extension: "png", // Adjust based on the actual image type
          });

          sheet.addImage(imageId, {
            tl: { col: 7, row: rowIndex },
            ext: { width: 100, height: 90 },
          });

          sheet.getColumn(8).width = 20;
          sheet.getRow(rowIndex + 1).height = 100;
        } else {
          const cell = sheet.getCell(`H${rowIndex + 1}`);
          cell.value = "No Image";
        }
      };

      for (let i = 0; i < response?.data?.length; i++) {
        await addDataWithTitleAndHeaders(response?.data[i], i + 1);
      }

      // response?.data?.forEach(async (item, index) => {
      //   return await addDataWithTitleAndHeaders(item, index + 1); // Starting from row 2 to account for the header row
      // });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "TASK-SHEETS-REPORTS.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerWrapper container rowGap={2} height={0}>
        <Grid item xs={12}>
          <h2>Task Sheets Report</h2>
        </Grid>

        <Grid item container mb={3}>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={12} alignItems={"center"}>
          <Table>
            <TableRow>
              <TableCell>Select Hall</TableCell>
              <TableCell>
                <FormControl style={{ width: "200px" }}>
                  <InputLabel id="select-hall">Select Hall</InputLabel>
                  <Select
                    multiple
                    label="Select Hall"
                    value={hallNo}
                    onChange={(e) => setHallNo(e.target.value)}
                    variant="outlined"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {HALL_NO?.map((hall) => (
                      <MenuItem key={hall} value={hall}>
                        <Checkbox checked={hallNo?.indexOf(hall) > -1} />
                        <ListItemText primary={hall} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Select Departments</TableCell>
              <TableCell>
                <FormControl style={{ width: "200px" }}>
                  <InputLabel id="select-department">
                    Select Department
                  </InputLabel>
                  <Select
                    multiple
                    label="Select Department"
                    value={departments}
                    onChange={(e) => setDepartments(e.target.value)}
                    variant="outlined"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {allDepartments?.map((dept) => (
                      <MenuItem key={dept} value={dept}>
                        <Checkbox checked={departments?.indexOf(dept) > -1} />
                        <ListItemText primary={dept} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Select Type</TableCell>
              <TableCell>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.ALL}
                        onChange={handleCheckboxChange}
                        name="ALL"
                      />
                    }
                    label="ALL"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.PENDING}
                        onChange={handleCheckboxChange}
                        name="PENDING"
                      />
                    }
                    label="PENDING"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.RESOLVED}
                        onChange={handleCheckboxChange}
                        name="RESOLVED"
                      />
                    }
                    label="RESOLVED"
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        <Grid item container alignItems={"center"}></Grid>

        <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Generate"
            )}
          </CustomButton>
        </Grid>
      </ContainerWrapper>
    </form>
  );
}

export default TaskSheetsReport;
