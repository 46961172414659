import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import ManagerResponse from "./container/Blowroom/Manager/Notifications/ManagerResponse";
import AdminResponse from "./container/Blowroom/Admin/Notifications/AdminResponse";
import AdminNotification from "./container/Blowroom/Admin/Notifications/AdminNotification";
import CottonWeighted from "./container/Blowroom/CottonWeighted";
import Wrapping from "./container/Carding/Wrapping";
import Neps from "./container/Carding/Neps";
import OnnFF from "./container/Carding/OnnOff";
import RewindingStudyReport from "./container/Reports/AutoconerReports/RewindingStudyReport";
import LNCheckingReport from "./container/Reports/AutoconerReports/LNCheckingReport";
import PaperConeIdentificationReport from "./container/Reports/AutoconerReports/PaperConeIdentificationReport";
import SpliceStrengthReport from "./container/Reports/AutoconerReports/SpliceStrengthReport";
import TrailReport from "./container/Reports/AutoconerReports/TrailReport";
import UPercentTrailReport from "./container/Reports/AutoconerReports/UTrailReports";

import Login from "./container/Auth/Login";
import RequireAuth from "./components/RequireAuth";
import SpliceStrengthChecking from "./container/AutoConer/SpliceStrengthChecking";
import PaperConeIdentification from "./container/AutoConer/PaperConeIdentification";
import LNChecking from "./container/AutoConer/LNChecking";
import RewindingStudy from "./container/AutoConer/RewindingStudy";
import Trail from "./container/AutoConer/Trail";
import UPercentTrail from "./container/AutoConer/Utrail";
import Cuts from "./container/AutoConer/Cuts";
import AlarmCops from "./container/AutoConer/AlarmCops";
import CountChange from "./container/Spinning/Count-Change";
import CotChecking from "./container/Spinning/Cot Checking";
import Hariness from "./container/Spinning/Hariness";
import Apron from "./container/Spinning/Apron";
import Record from "./container/Spinning/Record";
import CountChangeRecord from "./container/Spinning/Count-Change-Record";
import SliverLap from "./container/Lap/SliverLap";
import LapFeedWaste from "./container/Blowroom/LapFeedWaste";
import ChuteFeedWasteCotton from "./container/Blowroom/ChuteFeedWaste(Cotton)";
import ChuteFeedWasteOthers from "./container/Blowroom/ChuteFeedWaste(Others)";
import DropTest from "./container/Blowroom/DropTest";
import IndividualCardingWaste from "./container/Carding/IndividualCardingWaste";
import AutoLevellerOnOff from "./container/Carding/AutoLevellerOnOff";
import BlowroomNorms from "./container/Blowroom/Manager/Blowroom/Norms";
import Registration from "./container/Auth/Registration";
import HarinessDrafts from "./container/Spinning/HarinessDrafts";
import ApronDrafts from "./container/Spinning/ApronDrafts";
import CotCheckingDrafts from "./container/Spinning/CotCheckingDrafts";
import RibbonLap from "./container/Lap/RibbonLap";
import WrappingAutomatic from "./container/WrappingAutomatic";
import RibbonLapReports from "./container/Reports/LapReports/RibbonLapReports";
import SliverLapReports from "./container/Reports/LapReports/SliverLapReports";
import AutoLevellerOnOffReport from "./container/Reports/CardingReports/AutoLevellerOnOffReport";
import IndividualCardingWasteReport from "./container/Reports/CardingReports/IndividualCardingWasteReport";
import FinisherDrawing from "./container/FinisherDrawing/Yards";
import StructureLap from "./container/Lap/StructureLap";
import YardReport from "./container/Reports/FinisherReports/YardReport";
import SimplexCheckingDrafts from "./container/Simplex/SimplexCheckingDrafts";
import SimplexCheckings from "./container/Simplex/SimplexCheckings";
import ManagerBlowroomNotification from "./container/Blowroom/Manager/Notifications/ManagerNotification";
import LapFeedReport from "./container/Reports/BlowroomReports/LapFeedReport";
import ChuteFeedCottonReport from "./container/Reports/BlowroomReports/ChuteFeedReportCotton";
import ChuteFeedOthersReport from "./container/Reports/BlowroomReports/ChuteFeedReportOthers";
import DropTestReport from "./container/Reports/BlowroomReports/DropTestReport";
import Dashboard from "./container/Dashboard";
import StructureLapReports from "./container/Reports/LapReports/StructureLapReports";
import ParameterMasters from "./container/ParameterMasters";
import AddParameterMaster from "./container/AddParameterMaster";
import SavedParameterMaster from "./container/SavedParameterMaster";
import ManagerFinisherDrawingNotification from "./container/Blowroom/Manager/Notifications/ManagerFinisherDrawingNotification";
import SimplexReports from "./container/Reports/SimplexReports";
import MdNotification from "./container/Blowroom/Manager/Notifications/MdNotification";
import MdResponse from "./container/Blowroom/Manager/Notifications/MdResponse";
import Unauthorized from "./components/Unauthorized";
import ManagerSpinningNotification from "./container/Blowroom/Manager/Notifications/ManagerSpinningNotification";
import ManagerSastaNotification from "./container/Blowroom/Manager/Notifications/ManagerSastaNotification";
import SastaReports from "./container/SastaReports/index";
// import AddMachineSetting from "./container/AddMachineSetting";
import BlowroomMachineSetting from "./container/MachineSetting/BlowroomMachine/BlowroomMachineSetting";
import GeneralMachineSetting from "./container/MachineSetting/GeneralMachine/GeneralMachineSetting";
import CardingMachineSetting from "./container/MachineSetting/CardingMachine/CardingMachineSetting";
import DrawingMachineSetting from "./container/MachineSetting/DrawingMachine/DrawingMachineSetting";
import SimplexMachineSetting from "./container/MachineSetting/SimplexMachine/SimplexMachineSetting";
import SpinningMachineSetting from "./container/MachineSetting/SpinningMachine/SpinningMachineSetting";
import AutoconerMachineSetting from "./container/MachineSetting/AutoconerMachine/AutoconerMachineSetting";
import OthersMachineSetting from "./container/MachineSetting/OthersMachine/OthersMachineSetting";
import GeneralSettingManager from "./container/ManagerMachineSettingCorrection/GeneralCorrection/GeneralSettingManager";
import BlowroomSettingManager from "./container/ManagerMachineSettingCorrection/BlowroomCorrection/BlowroomSettingManager";
import CardingSettingManager from "./container/ManagerMachineSettingCorrection/CardingCorrection/CardingSettingManager";
import DrawingSettingManager from "./container/ManagerMachineSettingCorrection/DrawingCorrection/DrawingSettingManager";
import SimplexSettingManager from "./container/ManagerMachineSettingCorrection/SimplexCorrection/SimplexSettingManager";
import SpinningSettingManager from "./container/ManagerMachineSettingCorrection/SpinningCorrection/SpinningSettingManager";
import AutoconerSettingManager from "./container/ManagerMachineSettingCorrection/AutoconerCorrection/AutoconerSettingManager";
import OtherSettingManager from "./container/ManagerMachineSettingCorrection/OtherCorrection/OtherSettingManager";
import MachineSettingsReports from "./container/Reports/MachineSettingsReports/MachineSettingsReports";
import TelevisionUserRegistration from "./container/Auth/TelevisionUserRegistration/TelevisionUserRegistration";
import Television from "./container/Television/Television";
import ManagerAutoconerNotification from "./container/Blowroom/Autoconer/Manager/Notifications/ManageAutoNotification";
import TaskSheet from "./container/TaskSheet";
import TaskSheetManager from "./container/TaskSheetManager";
import TaskSheetReportAndResolve from "./container/TaskSheetReportAndResolve";
import TaskSheetsReport from "./container/Reports/TaskSheetsReport";
import ApronReport from "./container/Reports/SpinningReports/ApronReport";
import CountChangeReport from "./container/Reports/SpinningReports/CountChangeReport";
import CotCheckingReport from "./container/Reports/SpinningReports/CotCheckingReport";
import HarinessReport from "./container/Reports/SpinningReports/HarinessReport";
import MdTaskSheets from "./container/Blowroom/Manager/Notifications/MdTaskSheets";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Registration />} />

        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route element={<Layout />}>
          {/**--------------- SECTION 1 ( DASHBOARD ) ---------------- */}
          {/**Dashboard access for all */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  "EMPLOYEE",
                  "SUB_MANAGER",
                  "ADMIN",
                  "MANAGER",
                  "MANAGING_DIRECTOR",
                ]}
              />
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
          </Route>

          {/**--------------- SECTION 2 ( DATA ENTRY ) ---------------- */}
          {/**Blowroom access for both Employee and Sub Manager */}

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["lap_feed"]}
              />
            }
          >
            <Route path="lap-feed-waste" element={<LapFeedWaste />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["chute_feed_cotton"]}
              />
            }
          >
            <Route path="chute-feed-waste" element={<ChuteFeedWasteCotton />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["chute_feed_others"]}
              />
            }
          >
            <Route
              path="chute-feed-waste-others"
              element={<ChuteFeedWasteOthers />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["drop_test"]}
              />
            }
          >
            <Route path="drop-test" element={<DropTest />} />
          </Route>

          {/**Carding access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["carding"]}
                requiredSubModules={["individual_carding_waste"]}
              />
            }
          >
            <Route
              path="individual-carding-waste"
              element={<IndividualCardingWaste />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["carding"]}
                requiredSubModules={["auto_leveller_on/off"]}
              />
            }
          >
            <Route
              path="auto-leveller-on/off"
              element={<AutoLevellerOnOff />}
            />
          </Route>

          {/** Autoconer access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["splice_strength"]}
              />
            }
          >
            <Route
              path="splice-strength-checking"
              element={<SpliceStrengthChecking />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["paper_cone_identification"]}
              />
            }
          >
            <Route
              path="paper-cone-identification"
              element={<PaperConeIdentification />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["ln_checking"]}
              />
            }
          >
            <Route path="ln-checking" element={<LNChecking />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["rewinding_study"]}
              />
            }
          >
            <Route path="rewinding-study" element={<RewindingStudy />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["trail"]}
              />
            }
          >
            <Route path="trail" element={<Trail />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["u_percent_trail"]}
              />
            }
          >
            <Route path="u-percent-trail" element={<UPercentTrail />} />
          </Route>

          {/** Spinning access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["count_change"]}
              />
            }
          >
            <Route path="count-change-record" element={<CountChangeRecord />} />
            <Route path="count-change" element={<CountChange />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["cot_checking"]}
              />
            }
          >
            <Route path="cot-checking-submit" element={<CotChecking />} />
            <Route path="cot-checking" element={<CotCheckingDrafts />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["hariness"]}
              />
            }
          >
            <Route path="hariness-submit" element={<Hariness />} />
            <Route path="hariness" element={<HarinessDrafts />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["apron_spacer_condenser"]}
              />
            }
          >
            <Route path="apron-submit" element={<Apron />} />
            <Route path="apron" element={<ApronDrafts />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={[
                  "cot_checking",
                  "hariness",
                  "apron_spacer_condenser",
                ]}
              />
            }
          >
            <Route path="record" element={<Record />} />
          </Route>

          {/**Lap access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["lap"]}
                requiredSubModules={["sliver_lap"]}
              />
            }
          >
            <Route path="sliver-lap" element={<SliverLap />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["lap"]}
                requiredSubModules={["ribbon_lap"]}
              />
            }
          >
            <Route path="ribbon-lap" element={<RibbonLap />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["lap"]}
                requiredSubModules={["structure_lap"]}
              />
            }
          >
            <Route path="structure-lap" element={<StructureLap />} />
          </Route>

          {/** Finisher Drawing access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["finisher_drawing"]}
                requiredSubModules={["finisher_drawing_yard"]}
              />
            }
          >
            <Route
              path="finisher-drawing-yards"
              element={<FinisherDrawing />}
            />
          </Route>

          {/** Simplex Checking access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["simplex_checking"]}
                requiredSubModules={["simplex_checking"]}
              />
            }
          >
            <Route
              path="simplex-checking-draft"
              element={<SimplexCheckingDrafts />}
            />
            <Route path="simplex-checking" element={<SimplexCheckings />} />
          </Route>

          {/**--------------- SECTION 3 ( MACHINE SETTINGS ) ---------------- */}
          {/** Blowroom Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["blow_room_machine_settings"]}
              />
            }
          >
            <Route
              path="blowroom-machine-setting"
              element={<BlowroomMachineSetting />}
            />
          </Route>

          {/** Carding Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["carding"]}
                requiredSubModules={["carding_machine_settings"]}
              />
            }
          >
            <Route
              path="carding-machine-setting"
              element={<CardingMachineSetting />}
            />
          </Route>

          {/** Autoconor Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["autoconer_machine_settings"]}
              />
            }
          >
            <Route
              path="autoconer-machine-setting"
              element={<AutoconerMachineSetting />}
            />
          </Route>

          {/** Spinning Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["spinning_machine_settings"]}
              />
            }
          >
            <Route
              path="spinning-machine-setting"
              element={<SpinningMachineSetting />}
            />
          </Route>

          {/** Finisher Drawing Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["finisher_drawing"]}
                requiredSubModules={["finisher_drawing_machine_settings"]}
              />
            }
          >
            <Route
              path="drawing-machine-setting"
              element={<DrawingMachineSetting />}
            />
          </Route>

          {/** Simplex Checking Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["simplex_checking"]}
                requiredSubModules={["simplex_checking_machine_settings"]}
              />
            }
          >
            <Route
              path="simplex-machine-setting"
              element={<SimplexMachineSetting />}
            />
          </Route>

          {/**General Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["general"]}
                requiredSubModules={["general_machine_settings"]}
              />
            }
          >
            <Route
              path="general-machine-setting"
              element={<GeneralMachineSetting />}
            />
          </Route>

          {/**Others Machine settings access for both Employee and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["others"]}
                requiredSubModules={["others_machine_settings"]}
              />
            }
          >
            <Route
              path="other-machine-setting"
              element={<OthersMachineSetting />}
            />
          </Route>

          {/**--------------- SECTION 4 ( TASK SHEETS ) ---------------- */}
          {/** For Employee and Sub Manager  */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER"]}
                requiredModules={["task_sheets"]}
              />
            }
          >
            <Route path="task-sheets-complaints" element={<TaskSheet />} />
          </Route>

          {/** For Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["task_sheets"]}
              />
            }
          >
            <Route path="task-sheets-resolve" element={<TaskSheetManager />} />
          </Route>

          {/** For Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["EMPLOYEE", "SUB_MANAGER", "MANAGER"]}
                requiredModules={["task_sheets"]}
              />
            }
          >
            <Route
              path="task-sheets-report-reslove"
              element={<TaskSheetReportAndResolve />}
            />
          </Route>

          {/**--------------- SECTION ( PREVUIOSLY WE HAD ) ---------------- */}
          {/** Others (previous)  */}
          <Route
            element={<RequireAuth allowedRoles={["EMPLOYEE", "SUB_MANAGER"]} />}
          >
            {/** change this later */}
            <Route path="cuts" element={<Cuts />} />
            <Route path="alarm-cops" element={<AlarmCops />} />
          </Route>

          {/**--------------- SECTION 5 ( REPORTS ) ---------------- */}
          {/**Machine settings and Task sheets Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]} />
            }
          >
            <Route
              path="machine-settings-report"
              element={<MachineSettingsReports />}
            />
          </Route>

          <Route
            element={
              <RequireAuth allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]} />
            }
          >
            <Route path="task-sheets-report" element={<TaskSheetsReport />} />
          </Route>

          {/**Blowroom Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["lap_feed"]}
              />
            }
          >
            <Route path="lap-feed-report" element={<LapFeedReport />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["chute_feed_cotton"]}
              />
            }
          >
            <Route
              path="chute-feed-cotton-report"
              element={<ChuteFeedCottonReport />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["chute_feed_others"]}
              />
            }
          >
            <Route
              path="chute-feed-others-report"
              element={<ChuteFeedOthersReport />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["drop_test"]}
              />
            }
          >
            <Route path="drop-test-report" element={<DropTestReport />} />
          </Route>

          {/**Carding Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["carding"]}
                requiredSubModules={["individual_carding_waste"]}
              />
            }
          >
            <Route
              path="individual-carding-waste-report"
              element={<IndividualCardingWasteReport />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["carding"]}
                requiredSubModules={["auto_leveller_on/off"]}
              />
            }
          >
            <Route
              path="auto-leveller-on-off-report"
              element={<AutoLevellerOnOffReport />}
            />
          </Route>

          {/**Autoconor Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["splice_strength"]}
              />
            }
          >
            <Route
              path="splice-strength-report"
              element={<SpliceStrengthReport />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["paper_cone_identification"]}
              />
            }
          >
            <Route
              path="paper-cone-identification-report"
              element={<PaperConeIdentificationReport />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["ln_checking"]}
              />
            }
          >
            <Route path="ln-checking-report" element={<LNCheckingReport />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["rewinding_study"]}
              />
            }
          >
            <Route
              path="rewinding-study-report"
              element={<RewindingStudyReport />}
            />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["trail"]}
              />
            }
          >
            <Route path="trail-report" element={<TrailReport />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["u_percent_trail"]}
              />
            }
          >
            <Route
              path="u-percent-trail-report"
              element={<UPercentTrailReport />}
            />
          </Route>

          {/**Spinning Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["count_change"]}
              />
            }
          >
            <Route path="count-change-report" element={<CountChangeReport />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["cot_checking"]}
              />
            }
          >
            <Route path="cot-checking-report" element={<CotCheckingReport />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["hariness"]}
              />
            }
          >
            <Route path="hariness-report" element={<HarinessReport />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["apron_spacer_condenser"]}
              />
            }
          >
            <Route path="apron-report" element={<ApronReport />} />
          </Route>

          {/**Lap Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["lap"]}
                requiredSubModules={["sliver_lap"]}
              />
            }
          >
            <Route path="sliver-lap-report" element={<SliverLapReports />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["lap"]}
                requiredSubModules={["ribbon_lap"]}
              />
            }
          >
            <Route path="ribbon-lap-report" element={<RibbonLapReports />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["lap"]}
                requiredSubModules={["structure_lap"]}
              />
            }
          >
            <Route
              path="structure-lap-report"
              element={<StructureLapReports />}
            />
          </Route>

          {/**Finisher Drawing Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["finisher_drawing"]}
                requiredSubModules={["finisher_drawing_yard"]}
              />
            }
          >
            <Route path="finisher-drawing-report" element={<YardReport />} />
          </Route>

          {/**Simplex Checking Reports access for Admin and Manager and Sub Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["simplex_checking"]}
                requiredSubModules={["simplex_checking"]}
              />
            }
          >
            <Route
              path="simplex-checking-report"
              element={<SimplexReports />}
            />
          </Route>

          {/**--------------- SECTION 6 ( NORMS ) ---------------- */}
          {/**Norms access to admin,manager,sub manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]}
                requiredModules={["blow_room"]}
              />
            }
          >
            <Route path="blowroom-norms" element={<BlowroomNorms />} />
          </Route>

          {/**--------------- SECTION 7 ( MANAGER NOTIFICATIONS & RESPONSE && SASTA ) ---------------- */}
          {/**Blowroom Notifications access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["blow_room"]}
              />
            }
          >
            <Route
              path="manager-blowroom-notifications"
              element={<ManagerBlowroomNotification />}
            />
          </Route>

          {/**Autoconor Notifications access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["autoconer"]}
              />
            }
          >
            <Route
              path="manager-autoconer-notifications"
              element={<ManagerAutoconerNotification />}
            />
          </Route>

          {/**Spinning Notifications access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["spinning"]}
              />
            }
          >
            <Route
              path="manager-spinning-notifications"
              element={<ManagerSpinningNotification />}
            />
          </Route>

          {/**Finisher Drawing Notifications access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["finisher_drawing"]}
              />
            }
          >
            <Route
              path="manager-finisher-drawing-notifications"
              element={<ManagerFinisherDrawingNotification />}
            />
          </Route>

          {/**Response page for Notifications access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={[
                  "blow_room",
                  "autoconer",
                  "spinning",
                  "finisher_drawing",
                ]}
              />
            }
          >
            <Route path="manager-response" element={<ManagerResponse />} />
          </Route>

          {/**Sasta Notifications and Reports access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER", "SUB_MANAGER"]}
                requiredModules={["sasta"]}
              />
            }
          >
            <Route
              path="/manager-sasta-notifications"
              element={<ManagerSastaNotification />}
            />

            <Route path="/sasta-reports" element={<SastaReports />} />
          </Route>

          {/**--------------- SECTION 9 ( MANAGER MACHINE SETTINGS ) ---------------- */}
          {/** Blowroom Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["blow_room"]}
                requiredSubModules={["blow_room_machine_settings"]}
              />
            }
          >
            <Route
              path="blowroom-machine-setting-correction"
              element={<BlowroomSettingManager />}
            />
          </Route>

          {/** Carding Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["carding"]}
                requiredSubModules={["carding_machine_settings"]}
              />
            }
          >
            <Route
              path="carding-machine-setting-correction"
              element={<CardingSettingManager />}
            />
          </Route>

          {/** Autoconor Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["autoconer"]}
                requiredSubModules={["autoconer_machine_settings"]}
              />
            }
          >
            <Route
              path="autoconer-machine-setting-correction"
              element={<AutoconerSettingManager />}
            />
          </Route>

          {/** Spinning Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["spinning"]}
                requiredSubModules={["spinning_machine_settings"]}
              />
            }
          >
            <Route
              path="spinning-machine-setting-correction"
              element={<SpinningSettingManager />}
            />
          </Route>

          {/** Finisher Drawing Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["finisher_drawing"]}
                requiredSubModules={["finisher_drawing_machine_settings"]}
              />
            }
          >
            <Route
              path="drawing-machine-setting-correction"
              element={<DrawingSettingManager />}
            />
          </Route>

          {/** Simplex Checking Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["simplex_checking"]}
                requiredSubModules={["simplex_checking_machine_settings"]}
              />
            }
          >
            <Route
              path="simplex-machine-setting-correction"
              element={<SimplexSettingManager />}
            />
          </Route>

          {/** General Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["general"]}
                requiredSubModules={["general_machine_settings"]}
              />
            }
          >
            <Route
              path="general-machine-setting-correction"
              element={<GeneralSettingManager />}
            />
          </Route>

          {/**Others Machine settings access for Manager */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["MANAGER"]}
                requiredModules={["others"]}
                requiredSubModules={["others_machine_settings"]}
              />
            }
          >
            <Route
              path="others-machine-setting-correction"
              element={<OtherSettingManager />}
            />
          </Route>

          {/**--------------- SECTION 8 ( OTHERS ) ---------------- */}
          <Route element={<RequireAuth allowedRoles={["MANAGER"]} />}>
            {/* <Route
              path="/manager-spinning-respond"
              element={<SpinningRespond />}
            />
            <Route
              path="manager-cotchecking-respond"
              element={<CotCheckingRespond />}
            />
            <Route
              path="manager-hariness-respond"
              element={<HarinessRespond />}
            />
            <Route path="manager-asc-respond" element={<ASCRespond />} /> */}
          </Route>

          {/**--------------- SECTION 9 ( TELEVISION ) ---------------- */}
          <Route element={<RequireAuth allowedRoles={["TELEVISION"]} />}>
            <Route path="television" element={<Television />} />
          </Route>

          {/**--------------- SECTION 10 ( ADMIN ) ---------------- */}
          <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
            <Route path="wrapping-auto" element={<WrappingAutomatic />} />

            <Route path="admin-user" element={<Registration />} />

            <Route
              path="television-user"
              element={<TelevisionUserRegistration />}
            />

            <Route path="parameter-master" element={<ParameterMasters />} />
            <Route
              path="add-parameter-master"
              element={<AddParameterMaster />}
            />
            <Route
              path="saved-parameter-master"
              element={<SavedParameterMaster />}
            />
          </Route>

          {/**--------------- SECTION 9 ( MD ) ---------------- */}
          <Route element={<RequireAuth allowedRoles={["MANAGING_DIRECTOR"]} />}>
            <Route path="md-notifications" element={<MdNotification />} />
            <Route path="md-response" element={<MdResponse />} />

            <Route path="md-task-sheets" element={<MdTaskSheets />} />

            <Route path="admin-notifications" element={<AdminNotification />} />
            <Route path="admin-response" element={<AdminResponse />} />
          </Route>

          {/**--------------- SECTION END ( OTHERS ) ---------------- */}
          <Route
            element={
              <RequireAuth allowedRoles={["ADMIN", "MANAGER", "SUB_MANAGER"]} />
            }
          >
            {/* <Route
              path="sub-manager-notifications"
              element={<SubManagerNotification />}
            />
            <Route
              path="sub-manager-autoconer-notifications"
              element={<ManageAutoNotifications />}
            />
            <Route
              path="sub-manager-response"
              element={<SubManagerResponse />}
            /> */}
          </Route>

          <Route path="cotton-weighted" element={<CottonWeighted />} />
          <Route path="wrapping" element={<Wrapping />} />
          <Route path="neps" element={<Neps />} />
          <Route path="onn-off" element={<OnnFF />} />
        </Route>

        {/* <Route
          path="/login"
          element={
            <LayoutWithoutSidebar>
              <Route path="/login" element={<Login />} />
            </LayoutWithoutSidebar>
          }
        /> */}
        {/* <Route path="/users">
          <Users />
        </Route>
        <Route path="/">
          <Home />
        </Route> */}
      </Routes>

      <div className="customToast">
        <ToastContainer
          position="bottom-center"
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          //autoClose={7500}
        />
      </div>
    </Router>
  );
}

export default App;
