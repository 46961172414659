import apiClient from "../../../utils/clients/axios";

// Create a new record in the autoconer master
export const submitAutoconerData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer_master`,
    data
  );
  return response;
};

// Create a new record in the autoconer master
export const saveAutoconerDraftsData = async (data) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer_master/createAutoconerMasterDraft`,
    data
  );
  return response;
};



// update a new record in the carding master
export const updateAutoconerDraftsData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/autoconer_master/updateAutoconerMasterDraft/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateAutoconerData = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/autoconer_master/${id}`,
    payload
  );
  return response;
};

// Get all records from the autoconer master
export const getAllAutoconorSubmittedData = async () => {
  const response = await apiClient.get(`/api/quality_control/autoconer_master`);
  return response;
};

// Get all draft records from the autoconer master
export const getAllAutoconorDraftsData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer_master/getAllAutoconerMastersDraft`
  );
  return response;
};

// Delete a specific record from the autoconor master by ID
export const deleteAutoconorMasterData = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/autoconer_master/${id}`
  );
  return response;
};

// Delete a specific draft record from the autoconor master by ID
export const deleteAutoconorDraftMasterData = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/autoconer_master/deleteAutoconerMasterDraft/${id}`
  );
  return response;
};

// Get a specific record from the autoconer master by ID
export const getAutoconerMasterRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer_master/${id}`
  );
  return response;
};

// Update a specific record in the autoconer master by ID
export const updateAutoconerMasterRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/autoconer_master/${id}`,
    data
  );
  return response;
};

// Delete a specific record from the autoconer master by ID
export const deleteAutoconerMasterRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/autoconer_master/${id}`
  );
  return response;
};
