import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Webcam from "react-webcam";
import { handleToastMsg } from "../../components/Toast";
import CloseIcon from "@mui/icons-material/Close";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import { DEPARTMENTS_TASKSHEETS, HALL_NO } from "../../config/masterdata";
import { useMutation, useQueryClient } from "react-query";
import {
  createTaskSheetsIssueData,
  updateTaskSheetsIssueData,
} from "./mutation";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

function TaskSheetReportAndResolve({
  isManager,
  isSeek,
  isResolve,
  goBack,
  isRaiseReport,
  showIssueData,
  filteredManagers,
  isTelevision,
}) {
  const queryClient = useQueryClient();

  const [selectedDate, setSelectedDate] = useState(null);
  const today = dayjs();

  //this is for manager resolve
  const [solution, setSolution] = useState("");

  const handleGoBack = () => {
    goBack();
  };

  // this all for raise an report
  const [departments, setDepartments] = useState([]);
  const [reportingManager, setReportingManager] = useState([]);
  const [hallNo, setHallNo] = useState("");
  const [issue, setIssue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [openCamera, setOpenCamera] = useState(false);
  const [facingMode, setFacingMode] = useState("environment");

  const handleToggleCamera = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === "user" ? "environment" : "user"
    );
  };

  const handleManagerChange = (event) => {
    const { value } = event.target;

    const selectedIds = typeof value === "string" ? value.split(",") : value;

    const selectedManagers = selectedIds
      ?.map((id) => {
        const manager = filteredManagers?.find((mgr) => mgr.id === id);
        return manager ? { id: manager.id, name: manager.name } : null;
      })
      .filter(Boolean);

    setReportingManager(selectedManagers);
  };

  const toggleManagerSelection = (manager) => {
    setReportingManager((prev) => {
      const isSelected = prev.some((m) => m.id === manager.id);
      if (isSelected) {
        return prev.filter((m) => m.id !== manager.id);
      }
      return [...prev, { id: manager.id, name: manager.name }];
    });
  };

  const webcamRef = useRef(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenCamera(false);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   if (
  //     file &&
  //     (file.type === "image/jpeg" || file.type === "image/png") &&
  //     file.size <= 1048576
  //   ) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //       setSelectedFile(reader.result);
  //     };
  //     setOpenDialog(false);
  //   } else {
  //     handleToastMsg("Please select a JPG or PNG file less than 1MB.", "info");
  //   }
  // };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      if (file.size <= 1048576) {
        // If the file is already less than 1MB, process it directly
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setSelectedFile(reader.result);
        };
        setOpenDialog(false);
      } else {
        // Compress the image if it's larger than 1MB
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.src = reader.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Resize the image if necessary
            const maxWidth = 1000; // Max width for the resized image
            const maxHeight = 1000; // Max height for the resized image
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height = Math.round((height *= maxWidth / width));
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width = Math.round((width *= maxHeight / height));
                height = maxHeight;
              }
            }

            // Set canvas dimensions to match resized dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // Compress the image
            canvas.toBlob(
              (blob) => {
                if (blob.size <= 1048576) {
                  // If the compressed image is less than 1MB, process it
                  const compressedReader = new FileReader();
                  compressedReader.readAsDataURL(blob);
                  compressedReader.onloadend = () => {
                    setSelectedFile(compressedReader.result);
                  };
                  setOpenDialog(false);
                } else {
                  handleToastMsg(
                    "Image compression failed. Please choose a smaller image.",
                    "info"
                  );
                }
              },
              file.type,
              0.7 // Adjust quality (1.0 = original quality, 0.7 = 70% quality)
            );
          };
        };

        reader.readAsDataURL(file);
      }
    } else {
      handleToastMsg("Please select a JPG or PNG file.", "info");
    }
  };

  const handleCameraClick = () => {
    setOpenCamera(true);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        if (blob.size <= 1048576) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            setSelectedFile(reader.result);
          };
          setOpenCamera(false);
          setOpenDialog(false);
        } else {
          handleToastMsg("Please capture an image less than 1MB.", "info");
        }
      });
  }, [webcamRef]);

  const resetForm = () => {
    goBack();
  };

  const { mutate, isLoading: isReportCreateLoading } = useMutation(
    createTaskSheetsIssueData
  );
  const { mutate: updateReportMutation, isLoading: isReportUpdateLoading } =
    useMutation(updateTaskSheetsIssueData);
  //handle report issue submit

  const checkForAllFields =
    departments.length > 0 &&
    reportingManager.length > 0 &&
    hallNo !== "" &&
    issue !== "";

  const reportingManagerSolutions = reportingManager?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      solution: null,
      solutionDate: null,
      seekedDate: null,
      image: null,
    };
  });

  const handleSubmitReportIssue = () => {
    const payload = {
      date: today.format("YYYY-MM-DD"),
      department: departments,
      reportingManager: reportingManager,
      reportingManagerSolution: reportingManagerSolutions,
      description: issue,
      ...(selectedFile && { image: selectedFile }),
      // image: selectedFile ? selectedFile : null,
      status: "PENDING",
      hall: hallNo,
    };

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data submitted successfully", "success");
        queryClient.invalidateQueries("task-sheets-report-issue");
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occurred", "error");
      },
    });
  };

  const managerId = parseInt(localStorage.getItem("id"), 10);

  const managerIdFilter = showIssueData?.reportingManager?.filter(
    (item) => parseInt(item.id, 10) === managerId
  );

  const otherManagerResponse =
    showIssueData?.reportingManagerSolution?.filter(
      (item) => parseInt(item.id, 10) !== managerId
    ) || [];

  const existManagerResponse =
    showIssueData?.reportingManagerSolution?.filter(
      (item) => parseInt(item.id, 10) === managerId
    ) || [];

  // console.log("selectedFile", selectedFile);
  //handle update the issue reports data (seek or resolve)
  const handleSeekOrResolve = () => {
    const params = {
      payload: {
        ...showIssueData,
        ...(selectedDate && {
          reportingManagerSolution:
            otherManagerResponse?.length > 0
              ? [
                  ...otherManagerResponse,
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    solution:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solution
                        : null,
                    solutionDate:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solutionDate
                        : null,
                    seekedDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                    image: existManagerResponse[0]?.image || selectedFile,
                  },
                ]
              : [
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    solution:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solution
                        : null,
                    solutionDate:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solutionDate
                        : null,
                    seekedDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                    image: existManagerResponse[0]?.image || selectedFile,
                  },
                ],
        }),
        ...(solution && {
          reportingManagerSolution:
            otherManagerResponse.length > 0
              ? [
                  ...otherManagerResponse,
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    seekedDate:
                      existManagerResponse.length > 0
                        ? existManagerResponse[0]?.seekedDate
                        : null,
                    solutionDate: today.format("YYYY-MM-DD HH:mm:ss"), //this is for showing time also in the reports for solution date only
                    solution: solution,
                    image: existManagerResponse[0]?.image || selectedFile,
                  },
                ]
              : [
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    seekedDate:
                      existManagerResponse.length > 0
                        ? existManagerResponse[0]?.seekedDate
                        : null,
                    solutionDate: today.format("YYYY-MM-DD HH:mm:ss"), //this is for showing time also in the reports for solution date only
                    solution: solution,
                    image: existManagerResponse[0]?.image || selectedFile,
                  },
                ],
        }),
      },
      id: showIssueData?.id,
    };

    updateReportMutation(params, {
      onSuccess: () => {
        handleToastMsg("Data updates successfully", "success");
        queryClient.invalidateQueries("task-sheets-report-issue");
        resetForm();
      },
      onError: () => {
        handleToastMsg("Data Error Occurred", "error");
      },
    });
  };

  const [openNew, setOpenNew] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpenNew(true);
  };

  const handleClose = () => {
    setOpenNew(false);
  };

  //this is for checking the seeked date or not

  const isManagerResponse = showIssueData?.reportingManagerSolution?.filter(
    (item) => parseInt(item.id, 10) === managerId
  );

  //this is for checking to show the date select (DatePicker)
  const showSeekDateSelect =
    isManagerResponse?.length > 0
      ? showIssueData?.reportingManagerSolution === null ||
        (isManagerResponse[0]?.seekedDate === null &&
          isManagerResponse[0]?.solution === null)
      : true;

  //this is for checking to show the seeked date if there is in the data thats why we have default false
  const showSeekedDate =
    isManagerResponse?.length > 0
      ? isManagerResponse[0]?.seekedDate !== null
      : false;

  return (
    <Grid container item xs={12} alignContent={"flex-start"} rowGap={3}>
      <Grid
        container
        item
        xs={12}
        sx={{
          mt: {
            xs: "15px",
            sm: "0px",
          },
        }}
      >
        <Button
          onClick={handleGoBack}
          style={{
            color: "black",
            textTransform: "none",
            outline: "1px solid grey",
          }}
        >
          <ArrowBackIcon /> <span style={{ paddingLeft: "10px" }}>Back</span>
        </Button>
      </Grid>

      {isManager && (
        <>
          <Grid container item xs={12} rowGap={3}>
            <Grid container item xs={12} alignItems={"center"}>
              <Grid item xs={12} sm={6} md={6}>
                <span style={{ fontWeight: "600" }}>Sl No :</span>{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.slNo}
                </span>
              </Grid>
              <Grid
                sx={{
                  "@media (max-width: 600px)": {
                    marginTop: "20px",
                  },
                }}
                item
                xs={12}
                sm={6}
                md={6}
              >
                <span style={{ fontWeight: "600" }}>Department :</span>{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.department
                    ?.map((item) => item)
                    .join(", ")}{" "}
                </span>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems={"center"}>
              {" "}
              <Grid item xs={12} sm={6} md={6}>
                <span style={{ fontWeight: "600" }}> Date :</span>
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {dayjs(showIssueData?.date).format("DD-MM-YYYY")}
                </span>
              </Grid>
              <Grid
                sx={{
                  "@media (max-width: 600px)": {
                    marginTop: "20px",
                  },
                }}
                item
                xs={12}
                sm={6}
                md={6}
              >
                <span style={{ fontWeight: "600" }}>Reported By :</span>
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.reportedBy}
                </span>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems={"center"}>
              {" "}
              <Grid item xs={12} sm={6} md={6}>
                <span style={{ fontWeight: "600" }}> Hall No : </span>
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.hall}{" "}
                </span>
              </Grid>
              {isSeek && !isTelevision && showSeekDateSelect && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  alignItems={"center"}
                >
                  <Grid
                    sx={{
                      "@media (max-width: 600px)": {
                        marginTop: "20px",
                      },
                    }}
                    item
                    xs={12}
                    sm={3.5}
                    md={2}
                  >
                    <span style={{ fontWeight: "600" }}> Seek Date : </span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ marginTop: "10px" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        label="Date"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        inputFormat="DD/MM/YYYY"
                        shouldDisableDate={(date) =>
                          date.isBefore(dayjs(), "day")
                        }
                        sx={{
                          "@media (max-width: 600px)": {
                            width: "100%",
                          },
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
              {showSeekedDate && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  alignItems={"center"}
                >
                  <Grid
                    sx={{
                      "@media (max-width: 600px)": {
                        marginTop: "20px",
                      },
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <span style={{ fontWeight: "600" }}>Seeked Date :</span>
                    <span style={{ marginLeft: "5px" }}>
                      {dayjs(isManagerResponse[0]?.seekedDate).format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} rowGap={3}>
            <Grid item xs={12}>
              <span style={{ fontWeight: "600" }}>Description of issue</span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                value={showIssueData?.description}
                disabled={isManager}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid container item xs={12} sm={12} md={6} rowGap={3}>
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={6}
                rowGap={2}
                alignContent={"flex-start"}
              >
                <Grid item xs={12}>
                  <span style={{ fontWeight: "600" }}>Issue image</span>
                </Grid>
                {showIssueData?.image ? (
                  <Grid item xs={12}>
                    {/* <img
                  src={showIssueData?.image}
                  alt="Issued"
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "cover",
                    cursor: "pointer",
                    
                  }}
                  onClick={() => handleClickOpen(showIssueData?.image)}
                /> */}
                    <img
                      src={showIssueData?.image}
                      alt="Issued"
                      style={{
                        height: window.innerWidth <= 600 ? "200px" : "200px",
                        width: window.innerWidth <= 600 ? "100%" : "200px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickOpen(showIssueData?.image)}
                    />
                  </Grid>
                ) : (
                  <p>No image preview</p>
                )}
              </Grid>

              {isResolve && !isTelevision && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    rowGap={2}
                    alignContent={"flex-start"}
                  >
                    <Grid item xs={12}>
                      <span style={{ fontWeight: "600" }}>Resolve image</span>
                    </Grid>
                    {selectedFile ? (
                      <>
                        {" "}
                        <Grid item>
                          <img
                            src={selectedFile}
                            alt="Uploaded"
                            style={{
                              height: "200px",
                              width: "200px",
                              objectFit: "cover",
                            }}
                          />
                        </Grid>{" "}
                        <Grid item style={{ marginLeft: "10px" }}>
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setSelectedFile(null)}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        style={{ textTransform: "none" }}
                        onClick={handleOpenDialog}
                        fullWidth
                      >
                        Upload
                      </Button>
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container item xs={12} sm={12} md={6}>
              {isResolve && !isTelevision && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    rowGap={2}
                    alignContent={"flex-start"}
                  >
                    <Grid
                      sx={{
                        "@media (max-width: 600px)": {
                          marginTop: "20px",
                        },
                      }}
                      item
                      xs={12}
                    >
                      <span style={{ fontWeight: "600" }}>Solution</span>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={4}
                        value={solution}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setSolution(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="primary"
              style={{
                // width: "200px",
                width: window.innerWidth <= 600 ? "100%" : "200px",
                textTransform: "none",
                background: `${isResolve && "#28A745"}`,
              }}
              disabled={
                isReportUpdateLoading ||
                (selectedDate === null && solution === "")
              }
              onClick={() => handleSeekOrResolve()}
            >
              {isReportUpdateLoading ? (
                <CircularProgress size={25} />
              ) : isManager && isSeek ? (
                "Seek"
              ) : (
                isManager && isResolve && "Resolve"
              )}
            </Button>
          </Grid>
        </>
      )}

      {/**this is for employee or sub manager raise report */}
      {isRaiseReport && (
        <>
          <Grid container item xs={12} rowGap={3}>
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid container item xs={12} sm={6} md={6} alignItems={"center"}>
                <Grid
                  container
                  style={{ fontWeight: "600" }}
                  item
                  xs={12}
                  sm={3}
                  md={3.2}
                >
                  Department :
                </Grid>
                <Grid container item xs={12} sm={6} md={6}>
                  <FormControl
                    sx={{
                      width: "200px",
                      marginLeft: "30px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginLeft: "0",
                        marginTop: "20px",
                      },
                    }}
                  >
                    <InputLabel id="select-department">
                      Select Department
                    </InputLabel>
                    <Select
                      labelId="select-department"
                      label="Select Department"
                      multiple
                      value={departments}
                      onChange={(e) => setDepartments(e.target.value)}
                      variant="outlined"
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {DEPARTMENTS_TASKSHEETS?.map((dept) => (
                        <MenuItem key={dept} value={dept}>
                          <Checkbox checked={departments?.indexOf(dept) > -1} />
                          <ListItemText primary={dept} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  "@media (max-width: 600px)": {
                    marginTop: "20px",
                  },
                }}
                item
                xs={12}
                sm={6}
                md={6}
                alignItems={"center"}
              >
                <span style={{ fontWeight: "600" }}> Date :</span>{" "}
                <p style={{ marginLeft: "20px" }}>
                  {today.format("DD-MM-YYYY")}
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {" "}
              <Grid container item xs={12} sm={6} md={6} alignItems={"center"}>
                <Grid
                  style={{ fontWeight: "600" }}
                  container
                  item
                  xs={12}
                  sm={3}
                  md={3.2}
                >
                  Reporting Manager :{" "}
                </Grid>
                <Grid container item xs={12} sm={6} md={6}>
                  <FormControl
                    sx={{
                      width: "200px",
                      marginLeft: "30px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginLeft: "0",
                        marginTop: "20px",
                      },
                    }}
                  >
                    <InputLabel id="select-manager">Select Manager</InputLabel>
                    <Select
                      labelId="select-manager"
                      label="Select Manager"
                      multiple
                      value={reportingManager?.map((manager) => manager.id)}
                      onChange={handleManagerChange}
                      variant="outlined"
                      renderValue={(selected) =>
                        selected
                          .map((id) => {
                            const manager = filteredManagers?.find(
                              (mgr) => mgr.id === id
                            );
                            return manager ? manager.name : "";
                          })
                          .join(", ")
                      }
                    >
                      {/* {filteredManagers?.length > 0 &&
                        filteredManagers?.map((manager) => (
                          <MenuItem
                            key={manager.id}
                            value={manager.id}
                            name={manager.name}
                          >
                            <Checkbox
                              checked={
                                reportingManager?.indexOf(manager.id) > -1
                              }
                            />
                            <ListItemText primary={manager.name} />
                          </MenuItem>
                        ))} */}

                      {filteredManagers?.length > 0 &&
                        filteredManagers?.map((manager) => (
                          <MenuItem
                            key={manager.id}
                            value={manager.id}
                            onClick={() => toggleManagerSelection(manager)}
                          >
                            <Checkbox
                              checked={reportingManager?.some(
                                (mgr) => mgr.id === manager.id
                              )}
                            />
                            <ListItemText primary={manager.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={6} md={6} alignItems={"center"}>
                <Grid
                  sx={{
                    fontWeight: "600",
                    "@media (max-width: 600px)": {
                      marginTop: "20px",
                    },
                  }}
                  container
                  item
                  xs={12}
                  sm={1.5}
                  md={1.5}
                >
                  Hall No :{" "}
                </Grid>
                <Grid container item xs={12} sm={6} md={6}>
                  <FormControl
                    sx={{
                      width: "200px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginTop: "20px",
                      },
                    }}
                  >
                    <InputLabel id="select-hall">Select Hall no</InputLabel>
                    <Select
                      labelId="select-hall"
                      label="Select Hall no"
                      value={hallNo}
                      onChange={(e) => setHallNo(e.target.value)}
                      variant="outlined"
                    >
                      {HALL_NO?.map((hall) => (
                        <MenuItem key={hall} value={hall}>
                          {hall}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} rowGap={2}>
            <Grid item style={{ fontWeight: "600" }} xs={12}>
              Description of issue :
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                value={issue}
                variant="outlined"
                fullWidth
                onChange={(e) => setIssue(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} alignContent={"flex-start"}>
            <Grid container item xs={12} sm={6} md={6} rowGap={2}>
              <Grid style={{ fontWeight: "600" }} item xs={12}>
                Upload Image
              </Grid>
              <Grid container item xs={12} md={6}>
                {selectedFile ? (
                  <>
                    {" "}
                    <Grid item>
                      <img
                        src={selectedFile}
                        alt="Uploaded"
                        style={{
                          height: "200px",
                          width: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>{" "}
                    <Grid item style={{ marginLeft: "10px" }}>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedFile(null)}
                      />
                    </Grid>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={handleOpenDialog}
                    fullWidth
                  >
                    Upload
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: window.innerWidth <= 600 ? "100%" : "200px",
                textTransform: "none",
              }}
              disabled={isReportCreateLoading || !checkForAllFields}
              onClick={handleSubmitReportIssue}
            >
              {isReportCreateLoading ? (
                <CircularProgress size={25} />
              ) : (
                "Report issue"
              )}
            </Button>
          </Grid>
        </>
      )}

      {/**this dialog box for showing the image to the manager */}
      <Dialog open={openNew} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="custom-scrollbar">
          <Grid>
            <img
              src={selectedImage}
              alt="issue"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      {/**this dialog box for select the image when raise an report */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Grid container item xs={12}>
            <Grid container item xs={6}>
              {" "}
              Upload Image{" "}
            </Grid>
            <Grid container item xs={6} justifyContent={"flex-end"}>
              {" "}
              <Button
                onClick={handleCloseDialog}
                variant="outlined"
                color="primary"
                style={{
                  textTransform: "none",
                  background: "#e03131",
                  color: "#FFF",
                }}
                // startIcon={<CloseIcon />}
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!openCamera ? (
            <>
              <Button
                variant="contained"
                component="label"
                style={{ textTransform: "none" }}
                fullWidth
              >
                Select Image File
                <input
                  type="file"
                  hidden
                  accept="image/jpeg, image/png"
                  onChange={handleFileChange}
                />
              </Button>
              <Button
                variant="contained"
                fullWidth
                style={{ marginTop: "20px", textTransform: "none" }}
                onClick={handleCameraClick}
              >
                Open Camera
              </Button>
            </>
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              videoConstraints={{
                facingMode: facingMode,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {openCamera && (
            <>
              <Button
                onClick={capture}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                startIcon={<LinkedCameraIcon />}
              >
                Capture
              </Button>

              <Button
                onClick={handleToggleCamera}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                startIcon={<CameraswitchIcon />}
              >
                Switch Camera
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default TaskSheetReportAndResolve;
