import apiClient from "../../utils/clients/axios";
import { useQuery } from "react-query";

// get all records to populate the table
export const getAllSubmittedRecords = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/master_data`,
    payload
  );
  return response;
};

// delete a record from the table
export const deleteSubmittedRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/master_data/${id}`
  );
  return response;
};

// edit a record in the table
export const updateSubmittedRecord = async (id, data) => {
  const response = await apiClient.put(
    `/api/quality_control/master_data/${id}`,
    data
  );
  return response;
};

// Function to fetch submitted record by ID
const getSubmittedRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/master_data/${id}`
  );
  return response; // Return response data, not response object
};

// Custom hook for fetching submitted record by ID
export const useSubmittedRecordById = (id) => {
  return useQuery(["submittedRecord", id], () => getSubmittedRecordById(id), {
    enabled: !!id, // Query is enabled only if there's a valid ID
  });
};
