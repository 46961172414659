import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import Paper from "@mui/material/Paper";
import {
  getAllSavedRecords,
  deleteSavedRecord,
  useSavedRecordById,
} from "./mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../components/Toast";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import "./styles.css";
import { Popover, useMediaQuery, useTheme } from "@mui/material";
import DialogForLoadingMachine from "../../components/DialogForLoadingMachine";
import CheckOverflow from "../../utils/CheckOverflow";

function SavedParameterMaster() {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const actionColumnWidth = isMdScreen ? "18vw" : isSmScreen ? "20vw" : "12vw";

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedIdToEdit, setSelectedIdToEdit] = useState("");
  const [selectedIdToDelete, setSelectedIdToDelete] = useState("");
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [filterText, setFilterText] = useState("");

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data: savedRecords,
    isLoading: isSavedRecordsLoading,
    isError: isSavedRecordsError,
  } = useQuery("all-saved-parameters", getAllSavedRecords, {
    staleTime: 0, // Data will be considered stale immediately
    refetchOnWindowFocus: true, // Refetch on window focus
    refetchOnMount: true, // Refetch on mount
  });

  useEffect(() => {
    queryClient.invalidateQueries("all-saved-parameters");
  }, [queryClient]);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedIdToDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const mutation = useMutation(deleteSavedRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-saved-parameters");
      handleToastMsg("Deleted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const {
    data: savedRecordById,
    isLoading: isSavedLoading,
    isError: isSavedError,
  } = useSavedRecordById(selectedIdToEdit);
  const handleEdit = (id) => {
    setSelectedIdToEdit(id);
  };

  useEffect(() => {
    if (
      selectedIdToEdit &&
      !isSavedLoading &&
      !isSavedError &&
      savedRecordById
    ) {
      setShouldNavigate(true);
    }
  }, [selectedIdToEdit, isSavedLoading, isSavedError, savedRecordById]);

  useEffect(() => {
    if (shouldNavigate) {
      // Extract only the serializable data needed
      const editData = {
        id: savedRecordById.data.id,
        partyName: savedRecordById.data.partyName,
        partyCode: savedRecordById.data.partyCode,
        variety: savedRecordById.data.variety,
        general: savedRecordById.data.general,
        blowroom: savedRecordById.data.blowroom,
        carding: savedRecordById.data.carding,
        drawing: savedRecordById.data.drawing,
        simplex: savedRecordById.data.simplex,
        spinning: savedRecordById.data.spinning,
        autoconer: savedRecordById.data.autoconer,
        others: savedRecordById.data.others,
      };
      navigate("/add-parameter-master", {
        state: { editData },
      });
      setShouldNavigate(false); // Reset navigation flag
    }
  }, [shouldNavigate, navigate, savedRecordById]);

  const handleDeleteConfirmed = () => {
    // delete selectedIdToEdit
    mutation.mutate(selectedIdToDelete);
    handleCloseDeleteDialog();
  };

  //this is for pretend the rerender issue when we give checkoverflow params
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(
      savedRecords?.data?.filter(
        (row) =>
          row?.partyName?.toLowerCase().includes(filterText.toLowerCase()) ||
          row?.partyCode?.toLowerCase().includes(filterText.toLowerCase())
      ) || []
    );
  }, [savedRecords, filterText]);

  //this function for checking the text is being overflow or not
  const [isOverflow, addRef] = CheckOverflow(filteredData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  if (isSavedRecordsLoading) {
    return (
      <Grid
        container
        item
        md={12}
        xs={12}
        alignContent="flex-start"
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          marginTop: "1%",
          paddingBottom: "4%",
        }}
      >
        <Grid
          container
          item
          md={12}
          xs={12}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Grid item container alignContent="center" md={10}>
            <Grid item container style={{ paddingRight: "2%" }}>
              <Link to="/parameter-master">
                <ArrowBackIcon />
              </Link>
            </Grid>
            <Grid item container>
              <h3>LIST OF MACHINE SETTING MASTERS - SAVED FORMS</h3>
            </Grid>
          </Grid>
          <Grid item container md={2} justifyContent="flex-end">
            <Link to="/add-parameter-master">
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                style={{
                  // backgroundColor: "#87bfed",
                  textTransform: "none",
                }}
              >
                Add Record
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <h4>Loading...</h4>
        </Grid>
      </Grid>
    );
  }

  if (isSavedRecordsError) {
    return (
      <Grid
        container
        item
        md={12}
        xs={12}
        alignContent="flex-start"
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          marginTop: "1%",
          paddingBottom: "4%",
        }}
      >
        <Grid container item md={12} xs={12} justifyContent="space-between">
          <Grid item container alignContent="center" md={10}>
            <Grid item style={{ paddingRight: "2%" }}>
              <Link to="/parameter-master">
                <ArrowBackIcon />
              </Link>
            </Grid>
            <Grid item>
              <h3>LIST OF MACHINE SETTING MASTERS - SAVED FORMS</h3>
            </Grid>
          </Grid>
          <Grid item container md={2} justifyContent="flex-end">
            <Link to="/add-parameter-master">
              <Button
                variant="contained"
                color="primary"
                className="add-btn"
                style={{
                  // backgroundColor: "#87bfed",
                  textTransform: "none",
                }}
              >
                Add Record
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <h4>Error Loading Records</h4>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid
        container
        item
        md={12}
        xs={12}
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Grid item container alignContent="center" md={10}>
          <Grid item style={{ paddingRight: "2%" }}>
            <Link to="/parameter-master">
              <ArrowBackIcon />
            </Link>
          </Grid>
          <Grid item>
            <h3>LIST OF MACHINE SETTING MASTERS - SAVED FORMS</h3>
          </Grid>
        </Grid>
        <Grid item container md={2} justifyContent="flex-end">
          <Link to="/add-parameter-master">
            <Button
              variant="contained"
              color="primary"
              className="add-btn"
              style={{
                // backgroundColor: "#87bfed",
                textTransform: "none",
              }}
            >
              Add Record
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent="flex-end"
        style={{ marginTop: "3%" }}
      >
        <Grid container item md={4} xs={7}>
          {savedRecords?.data?.length > 0 && (
            <TextField
              label="Filter by Party Name / Party Code"
              variant="outlined"
              fullWidth
              value={filterText}
              onChange={handleFilterChange}
              style={{ marginBottom: "20px" }}
            />
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: "1%" }}>
        {savedRecords?.data?.length === 0 ? (
          <Grid item container xs={12} justifyContent="center">
            <h4>No records found</h4>
          </Grid>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "80px" }}>S. No.</TableCell>
                <TableCell style={{ maxWidth: "100px" }}>Party Name</TableCell>
                <TableCell style={{ maxWidth: "100px" }}>Party Code</TableCell>
                <TableCell style={{ maxWidth: "100px" }}>Variety</TableCell>
                <TableCell style={{ width: actionColumnWidth }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell style={{ width: "80px" }}>{index + 1}</TableCell>
                  <TableCell style={{ maxWidth: "100px" }}>
                    <p
                      className="overflowContain"
                      ref={addRef(`name-${row.id}`)}
                      onMouseEnter={(e) =>
                        handlePopoverOpen(e, `name-${row.id}`, row.partyName)
                      }
                      onMouseLeave={handlePopoverClose}
                    >
                      {row.partyName}
                    </p>
                  </TableCell>
                  <TableCell style={{ maxWidth: "100px" }}>
                    <p
                      className="overflowContain"
                      ref={addRef(`code-${row.id}`)}
                      onMouseEnter={(e) =>
                        handlePopoverOpen(e, `code-${row.id}`, row.partyCode)
                      }
                      onMouseLeave={handlePopoverClose}
                    >
                      {row.partyCode}
                    </p>
                  </TableCell>
                  <TableCell style={{ maxWidth: "100px" }}>
                    <p
                      className="overflowContain"
                      ref={addRef(`variety-${row.id}`)}
                      onMouseEnter={(e) =>
                        handlePopoverOpen(e, `variety-${row.id}`, row.variety)
                      }
                      onMouseLeave={handlePopoverClose}
                    >
                      {row.variety}
                    </p>
                  </TableCell>
                  <TableCell style={{ width: actionColumnWidth }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        className="edit-delete-btn"
                        onClick={() => handleEdit(row.id)}
                        style={{
                          backgroundColor: "rgba(226, 229, 232, 0)",
                          marginRight: "4%",
                        }}
                      >
                        <ModeEditIcon style={{ color: "green" }} />
                      </Button>
                      <Button
                        variant="contained"
                        className="edit-delete-btn"
                        style={{ backgroundColor: "rgba(226, 229, 232, 0)" }}
                        onClick={() => handleOpenDeleteDialog(row.id)}
                      >
                        <DeleteIcon style={{ color: "rgb(167, 27, 34)" }} />
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Popover
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <p style={{ padding: "15px" }}>{popoverContent}</p>
      </Popover>

      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
      />

      <DialogForLoadingMachine open={mutation.isLoading} />
    </Grid>
  );
}

export default SavedParameterMaster;
