import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import {
  getAllGeneralSubmittedData,
  getStandardsMasterData,
  managerUpdateGeneralData,
  saveGeneralDraftsData,
  submitGeneralData,
  updateGeneralDraftsData,
} from "./mutation";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import { CircularProgress, MenuItem, Popover, Select } from "@mui/material";
import { handleToastMsg } from "../../../components/Toast";
import CheckOverflow from "../../../utils/CheckOverflow";

const AddGeneralRecord = ({
  selectedDataToEdit,
  goBack,
  viewMode,
  editMode,
  isManager,
  isTelevision,
  setPauseInterval,
}) => {
  const queryClient = useQueryClient();
  const [partyCode, setPartyCode] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query

  const today = dayjs().format("DD-MM-YYYY");
  const params = { partyCode, key: "general" };

  const [apiStandardsData, setApiStandardsData] = useState({});

  const { data: allSubmittedData } = useQuery(
    "all-general-data",
    getAllGeneralSubmittedData
  );

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { isLoading, isError, refetch } = useQuery(
    "all-standard-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  const submitMutation = useMutation(submitGeneralData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-general-data");
      queryClient.invalidateQueries("all-drafts-data");
      handleToastMsg("Data sumitted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const saveMutation = useMutation(saveGeneralDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drafts-data");
      handleToastMsg("Draft data saved successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const updateMutation = useMutation(updateGeneralDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drafts-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const managerUpdateMutation = useMutation(managerUpdateGeneralData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-general-data");
      queryClient.invalidateQueries("all-notification-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const [formData, setFormData] = useState({
    partyCode: "",
    fiberMake: {
      value: "",
      isOutOfNorms: false,
    },
    denierXCutLength: {
      value: { x: "", y: "" },
      isOutOfNorms: false,
    },
    mergeNo: {
      value: "",
      isOutOfNorms: false,
    },
    feedback: "",
  });

  const [applied, setApplied] = useState(false);
  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState("");

  useEffect(() => {
    if (isManager) {
      //this is for showing the date select
      setApplied(true);
      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        fiberMake: {
          ...prev.fiberMake,
          ...(selectedDataToEdit?.fiberMake?.isOutOfNorms === false && {
            value: selectedDataToEdit?.fiberMake?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.fiberMake?.isOutOfNorms,
        },
        denierXCutLength: {
          ...prev.denierXCutLength,
          ...(selectedDataToEdit?.denierXCutLength?.isOutOfNorms === false && {
            value: {
              x: selectedDataToEdit?.denierXCutLength?.value?.x,
              y: selectedDataToEdit?.denierXCutLength?.value?.y,
            },
          }),
          isOutOfNorms: selectedDataToEdit?.denierXCutLength?.isOutOfNorms,
        },
        mergeNo: {
          ...prev.mergeNo,
          ...(selectedDataToEdit?.mergeNo?.isOutOfNorms === false && {
            value: selectedDataToEdit?.mergeNo?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.mergeNo?.isOutOfNorms,
        },
      }));
    }

    if ((editMode || viewMode) && selectedDataToEdit) {
      //this is for showing the date select
      setApplied(true);

      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //this all other data fetch

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        fiberMake: {
          ...prev.fiberMake,
          value: selectedDataToEdit?.fiberMake?.value,
          isOutOfNorms: selectedDataToEdit?.fiberMake?.isOutOfNorms,
        },
        denierXCutLength: {
          ...prev.denierXCutLength,
          value: {
            x: selectedDataToEdit?.denierXCutLength?.value?.x,
            y: selectedDataToEdit?.denierXCutLength?.value?.y,
          },
          isOutOfNorms: selectedDataToEdit?.denierXCutLength?.isOutOfNorms,
        },
        mergeNo: {
          ...prev.mergeNo,
          value: selectedDataToEdit?.mergeNo?.value,
          isOutOfNorms: selectedDataToEdit?.mergeNo?.isOutOfNorms,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, selectedDataToEdit]);

  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
    setFormData((prev) => ({ ...prev, partyCode: event.target.value }));
  };

  const handleFeedback = (event) => {
    setFormData((prev) => ({ ...prev, feedback: event.target.value.toUpperCase(), }));
  };

  const checkXandYNoisOutOfNorms =
    apiStandardsData?.data?.data?.["denierXCutLength"]?.x ===
      formData?.denierXCutLength?.value?.x &&
    apiStandardsData?.data?.data?.["denierXCutLength"]?.y ===
      formData?.denierXCutLength?.value?.y;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    if (name === "denierXCutLengthX") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["denierXCutLength"]?.x === value &&
        apiStandardsData?.data?.data?.["denierXCutLength"]?.y ===
          formData?.denierXCutLength?.value?.y
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        denierXCutLength: {
          ...prevState.denierXCutLength,
          value: { ...prevState.denierXCutLength.value, x: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name === "denierXCutLengthY") {
      const isOutOfNorms =
        apiStandardsData?.data?.data?.["denierXCutLength"]?.y === value &&
        apiStandardsData?.data?.data?.["denierXCutLength"]?.x ===
          formData?.denierXCutLength?.value?.x
          ? false
          : true;

      setFormData((prevState) => ({
        ...prevState,
        denierXCutLength: {
          ...prevState.denierXCutLength,
          value: { ...prevState.denierXCutLength.value, y: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (name) {
      // const [field, subField] = name;
      const isOutOfNorms =
        apiStandardsData?.data?.data?.[name] === value ? false : true;

      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value,
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = selectedDataToEdit
      ? { ...formData, generalMasterDraftId: selectedDataToEdit?.id }
      : { ...formData };

    const params = {
      payload: { ...formData, isOutOfNorms: false },
      id: selectedDataToEdit?.id,
    };

    if (isManager) {
      managerUpdateMutation.mutate(params);
    } else {
      submitMutation.mutate(payload);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (editMode) {
      const params = {
        payload: { ...formData, variety: apiStandardsData?.data?.variety },
        id: selectedDataToEdit?.id,
      };
      updateMutation.mutate(params);
    } else {
      saveMutation.mutate({
        ...formData,
        variety: apiStandardsData?.data?.variety,
      });
    }
  };

  const handleApply = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false); // Disable the query
    setSelectedDates("");
    setApiStandardsData({});
  };

  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedData?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedData]);

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const filteredDataFromApi =
    allSubmittedData?.data?.filter(
      (item) => item.partyCode === partyCode && selectedDates === item.date
    ) || [];

  const enableSubmit =
    formData?.fiberMake?.value !== "" &&
    formData?.denierXCutLength?.value?.x !== "" &&
    formData?.denierXCutLength?.value?.y !== "" &&
    formData?.mergeNo?.value !== "";

  //all outOfNorms shomustuld be false
  const isManagerEnableSubmit =
    !formData?.fiberMake?.isOutOfNorms &&
    !formData?.denierXCutLength?.isOutOfNorms &&
    !formData?.mergeNo?.isOutOfNorms;

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    apiStandardsData?.data ? apiStandardsData?.data : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    goBack();
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">Add General Record</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <p>Party Code: </p>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              disabled={viewMode || editMode || isManager}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handleApply}
            color="primary"
            style={{
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {applied && partyCode && (
            <Select
              value={selectedDates}
              onChange={handleDateChange}
              label="Select Date"
              fullWidth
            >
              {allDates?.map((date) => (
                <MenuItem key={date} value={date}>
                  {dayjs(date).format("DD-MM-YYYY")}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Particulars</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Previous Data
                </Grid>
                <Grid item xs={12}>
                  {selectedDates && dayjs(selectedDates).format("DD-MM-YYYY")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Actual Data
                </Grid>
                <Grid item xs={12}>
                  {isManager
                    ? dayjs(selectedDataToEdit?.date).format("DD-MM-YYYY")
                    : today}
                </Grid>
              </TableCell>
              {isManager && (
                <TableCell>
                  <Grid item xs={12}>
                    Correction Data
                  </Grid>
                  <Grid item xs={12}>
                    {today}
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Fiber Make</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.fiberMake}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.fiberMake?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.fiberMake?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.fiberMake?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.fiberMake?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  name="fiberMake"
                  variant="outlined"
                  value={formData?.fiberMake?.value}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.fiberMake?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.fiberMake ===
                        formData?.fiberMake?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Denier X Cut Length</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    !isLoading &&
                    apiStandardsData?.data?.data &&
                    `${apiStandardsData?.data?.data?.denierXCutLength?.x} * ${apiStandardsData?.data?.data?.denierXCutLength?.y}`}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.denierXCutLength
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi.length > 0 &&
                    `${filteredDataFromApi[0]?.denierXCutLength?.value?.x} * ${filteredDataFromApi[0]?.denierXCutLength?.value?.y}`}
                </p>
              </TableCell>

              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.denierXCutLength?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {`${selectedDataToEdit?.denierXCutLength?.value?.x} * ${selectedDataToEdit?.denierXCutLength?.value?.y}`}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="denierXCutLengthX"
                    variant="outlined"
                    value={formData?.denierXCutLength?.value?.x}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.denierXCutLength?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYNoisOutOfNorms ? "green" : "red",
                        width: "200px",
                      },
                    }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    name="denierXCutLengthY"
                    variant="outlined"
                    value={formData?.denierXCutLength?.value?.y}
                    disabled={
                      viewMode ||
                      (isManager &&
                        !selectedDataToEdit?.denierXCutLength?.isOutOfNorms)
                    }
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        color: checkXandYNoisOutOfNorms ? "green" : "red",
                        width: "200px",
                      },
                    }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Merge No</TableCell>
              <TableCell>
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.mergeNo}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.mergeNo?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.mergeNo?.value}
                </p>
              </TableCell>

              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.mergeNo?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.mergeNo?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="mergeNo"
                  variant="outlined"
                  value={formData.mergeNo.value}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.mergeNo?.isOutOfNorms)
                  }
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.mergeNo ===
                        formData?.mergeNo?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {!isTelevision && (
        <>
          <TextField
            fullWidth
            name="feedback"
            value={formData.feedback}
            onChange={handleFeedback}
            label="Feedback"
            id="outlined-basic"
            variant="outlined"
            style={{ marginTop: "3%" }}
          />
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: "2%" }}
          >
            {!isManager && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "2%",
                }}
                disabled={
                  !partyCode ||
                  enableSubmit ||
                  updateMutation.isLoading ||
                  saveMutation.isLoading
                }
                onClick={handleSave}
              >
                {updateMutation.isLoading || saveMutation.isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save and Edit Later"
                )}
              </Button>
            )}
            <Button
              variant="contained"
              style={{
                background: "#181E72",
                color: "white",
                textTransform: "none",
              }}
              disabled={
                isManager
                  ? !isManagerEnableSubmit
                  : !enableSubmit ||
                    !partyCode ||
                    viewMode ||
                    managerUpdateMutation.isLoading ||
                    submitMutation.isLoading
              }
              onClick={handleSubmit}
            >
              {managerUpdateMutation.isLoading || submitMutation.isLoading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </>
      )}

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default AddGeneralRecord;
