import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import {
  getAllDrawingSubmittedData,
  managerUpdateDrawingData,
  saveDrawingDraftsData,
  submitDrawingData,
  updateDrawingDraftsData,
} from "./mutation";
import { getStandardsMasterData } from "../GeneralMachine/mutation";
import { handleToastMsg } from "../../../components/Toast";
import { CircularProgress, MenuItem, Popover, Select } from "@mui/material";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import CheckOverflow from "../../../utils/CheckOverflow";

const AddDrawingRecord = ({
  selectedDataToEdit,
  goBack,
  viewMode,
  editMode,
  isManager,
  isTelevision,
  setPauseInterval,
}) => {
  const queryClient = useQueryClient();
  const [partyCode, setPartyCode] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query

  const [applied, setApplied] = useState(false);
  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState("");

  const today = dayjs().format("DD-MM-YYYY");
  const params = { partyCode, key: "drawing" };

  const [apiStandardsData, setApiStandardsData] = useState({});

  const { data: allSubmittedData } = useQuery(
    "all-drawing-data",
    getAllDrawingSubmittedData
  );

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { isLoading, isError, refetch } = useQuery(
    "all-standard-drawing-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  const submitMutation = useMutation(submitDrawingData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drawing-data");
      queryClient.invalidateQueries("all-drawing-drafts-data");
      handleToastMsg("Data sumitted successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const saveMutation = useMutation(saveDrawingDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drawing-drafts-data");
      handleToastMsg("Draft data saved successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const updateMutation = useMutation(updateDrawingDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drawing-drafts-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const managerUpdateMutation = useMutation(managerUpdateDrawingData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drawing-data");
      queryClient.invalidateQueries("all-notification-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const [formData, setFormData] = useState({
    partyCode: "",
    breakerDrawing: {
      value: "",
      isOutOfNorms: false,
    },
    breakerNoOfEnds: {
      value: "",
      isOutOfNorms: false,
    },
    breakerBottomRollSetting: {
      value: "",
      isOutOfNorms: false,
    },
    bdBreaker: {
      value: "",
      isOutOfNorms: false,
    },
    breakerDrawingHank: {
      value: "",
      isOutOfNorms: false,
    },
    breakerSpeed: {
      value: "",
      isOutOfNorms: false,
    },
    finisherDrawing: {
      value: "",
      isOutOfNorms: false,
    },
    finisherNoOfEnds: {
      value: "",
      isOutOfNorms: false,
    },
    finisherBottomRollSetting: {
      value: "",
      isOutOfNorms: false,
    },
    bdFinisher: {
      value: "",
      isOutOfNorms: false,
    },
    finisherDeliveryHank: {
      value: "",
      isOutOfNorms: false,
    },
    finisherDeliverySpeed: {
      value: "",
      isOutOfNorms: false,
    },
    feedback: "",
  });

  useEffect(() => {
    if (isManager) {
      //this is for showing the date select
      setApplied(true);
      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //only update the partycode
      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        breakerDrawing: {
          ...prev.breakerDrawing,
          ...(selectedDataToEdit?.breakerDrawing?.isOutOfNorms === false && {
            value: selectedDataToEdit?.breakerDrawing?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.breakerDrawing?.isOutOfNorms,
        },
        breakerNoOfEnds: {
          ...prev.breakerNoOfEnds,
          ...(selectedDataToEdit?.breakerNoOfEnds?.isOutOfNorms === false && {
            value: selectedDataToEdit?.breakerNoOfEnds?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.breakerNoOfEnds?.isOutOfNorms,
        },
        breakerBottomRollSetting: {
          ...prev.breakerBottomRollSetting,
          ...(selectedDataToEdit?.breakerBottomRollSetting?.isOutOfNorms ===
            false && {
            value: selectedDataToEdit?.breakerBottomRollSetting?.value,
          }),
          isOutOfNorms:
            selectedDataToEdit?.breakerBottomRollSetting?.isOutOfNorms,
        },
        bdBreaker: {
          ...prev.bdBreaker,
          ...(selectedDataToEdit?.bdBreaker?.isOutOfNorms === false && {
            value: selectedDataToEdit?.bdBreaker?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.bdBreaker?.isOutOfNorms,
        },
        breakerDrawingHank: {
          ...prev.breakerDrawingHank,
          ...(selectedDataToEdit?.breakerDrawingHank?.isOutOfNorms ===
            false && {
            value: selectedDataToEdit?.breakerDrawingHank?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.breakerDrawingHank?.isOutOfNorms,
        },
        breakerSpeed: {
          ...prev.breakerSpeed,
          ...(selectedDataToEdit?.breakerSpeed?.isOutOfNorms === false && {
            value: selectedDataToEdit?.breakerSpeed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.breakerSpeed?.isOutOfNorms,
        },
        finisherDrawing: {
          ...prev.finisherDrawing,
          ...(selectedDataToEdit?.finisherDrawing?.isOutOfNorms === false && {
            value: selectedDataToEdit?.finisherDrawing?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.finisherDrawing?.isOutOfNorms,
        },
        finisherNoOfEnds: {
          ...prev.finisherNoOfEnds,
          ...(selectedDataToEdit?.finisherNoOfEnds?.isOutOfNorms === false && {
            value: selectedDataToEdit?.finisherNoOfEnds?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.finisherNoOfEnds?.isOutOfNorms,
        },
        finisherBottomRollSetting: {
          ...prev.finisherBottomRollSetting,
          ...(selectedDataToEdit?.finisherBottomRollSetting?.isOutOfNorms ===
            false && {
            value: selectedDataToEdit?.finisherBottomRollSetting?.value,
          }),
          isOutOfNorms:
            selectedDataToEdit?.finisherBottomRollSetting?.isOutOfNorms,
        },
        bdFinisher: {
          ...prev.bdFinisher,
          ...(selectedDataToEdit?.bdFinisher?.isOutOfNorms === false && {
            value: selectedDataToEdit?.bdFinisher?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.bdFinisher?.isOutOfNorms,
        },
        finisherDeliveryHank: {
          ...prev.finisherDeliveryHank,
          ...(selectedDataToEdit?.finisherDeliveryHank?.isOutOfNorms ===
            false && {
            value: selectedDataToEdit?.finisherDeliveryHank?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.finisherDeliveryHank?.isOutOfNorms,
        },
        finisherDeliverySpeed: {
          ...prev.finisherDeliverySpeed,
          ...(selectedDataToEdit?.finisherDeliverySpeed?.isOutOfNorms ===
            false && {
            value: selectedDataToEdit?.finisherDeliverySpeed?.value,
          }),
          isOutOfNorms: selectedDataToEdit?.finisherDeliverySpeed?.isOutOfNorms,
        },
      }));
    }

    if ((editMode || viewMode) && selectedDataToEdit) {
      //this is for showing the date select
      setApplied(true);

      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //this all other data fetch

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        breakerDrawing: {
          ...prev.breakerDrawing,
          value: selectedDataToEdit?.breakerDrawing?.value,
          isOutOfNorms: selectedDataToEdit?.breakerDrawing?.isOutOfNorms,
        },
        breakerNoOfEnds: {
          ...prev.breakerNoOfEnds,
          value: selectedDataToEdit?.breakerNoOfEnds?.value,
          isOutOfNorms: selectedDataToEdit?.breakerNoOfEnds?.isOutOfNorms,
        },
        breakerBottomRollSetting: {
          ...prev.breakerBottomRollSetting,
          value: selectedDataToEdit?.breakerBottomRollSetting?.value,
          isOutOfNorms:
            selectedDataToEdit?.breakerBottomRollSetting?.isOutOfNorms,
        },
        bdBreaker: {
          ...prev.bdBreaker,
          value: selectedDataToEdit?.bdBreaker?.value,
          isOutOfNorms: selectedDataToEdit?.bdBreaker?.isOutOfNorms,
        },
        breakerDrawingHank: {
          ...prev.breakerDrawingHank,
          value: selectedDataToEdit?.breakerDrawingHank?.value,
          isOutOfNorms: selectedDataToEdit?.breakerDrawingHank?.isOutOfNorms,
        },
        breakerSpeed: {
          ...prev.breakerSpeed,
          value: selectedDataToEdit?.breakerSpeed?.value,
          isOutOfNorms: selectedDataToEdit?.breakerSpeed?.isOutOfNorms,
        },
        finisherDrawing: {
          ...prev.finisherDrawing,
          value: selectedDataToEdit?.finisherDrawing?.value,
          isOutOfNorms: selectedDataToEdit?.finisherDrawing?.isOutOfNorms,
        },
        finisherNoOfEnds: {
          ...prev.finisherNoOfEnds,
          value: selectedDataToEdit?.finisherNoOfEnds?.value,
          isOutOfNorms: selectedDataToEdit?.finisherNoOfEnds?.isOutOfNorms,
        },
        finisherBottomRollSetting: {
          ...prev.finisherBottomRollSetting,
          value: selectedDataToEdit?.finisherBottomRollSetting?.value,
          isOutOfNorms:
            selectedDataToEdit?.finisherBottomRollSetting?.isOutOfNorms,
        },
        bdFinisher: {
          ...prev.bdFinisher,
          value: selectedDataToEdit?.bdFinisher?.value,
          isOutOfNorms: selectedDataToEdit?.bdFinisher?.isOutOfNorms,
        },
        finisherDeliveryHank: {
          ...prev.finisherDeliveryHank,
          value: selectedDataToEdit?.finisherDeliveryHank?.value,
          isOutOfNorms: selectedDataToEdit?.finisherDeliveryHank?.isOutOfNorms,
        },
        finisherDeliverySpeed: {
          ...prev.finisherDeliverySpeed,
          value: selectedDataToEdit?.finisherDeliverySpeed?.value,
          isOutOfNorms: selectedDataToEdit?.finisherDeliverySpeed?.isOutOfNorms,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, selectedDataToEdit]);

  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
    setFormData((prev) => ({ ...prev, partyCode: event.target.value }));
  };

  const handleFeedback = (event) => {
    setFormData((prev) => ({ ...prev, feedback: event.target.value.toUpperCase(), }));
  };

  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedData?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedData]);

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const parsedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    if (name) {
      // const [field, subField] = name;
      const isOutOfNorms =
        apiStandardsData?.data?.data?.[name] === parsedValue ? false : true;

      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: parsedValue,
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: parsedValue,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = selectedDataToEdit
      ? { ...formData, drawingMasterDraftId: selectedDataToEdit?.id }
      : { ...formData };

    const params = {
      payload: { ...formData, isOutOfNorms: false },
      id: selectedDataToEdit?.id,
    };

    if (isManager) {
      managerUpdateMutation.mutate(params);
    } else {
      submitMutation.mutate(payload);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (editMode) {
      const params = {
        payload: { ...formData, variety: apiStandardsData?.data?.variety },
        id: selectedDataToEdit?.id,
      };
      updateMutation.mutate(params);
    } else {
      saveMutation.mutate({
        ...formData,
        variety: apiStandardsData?.data?.variety,
      });
    }
  };

  const handleApply = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false); // Disable the query
    setSelectedDates("");
    setApiStandardsData({});
  };

  const filteredDataFromApi =
    allSubmittedData?.data?.filter(
      (item) => item.partyCode === partyCode && selectedDates === item.date
    ) || [];

  const enableSubmit =
    formData?.breakerDrawing?.value !== "" &&
    formData?.breakerNoOfEnds?.value !== "" &&
    formData?.breakerBottomRollSetting?.value !== "" &&
    formData?.bdBreaker?.value !== "" &&
    formData?.breakerDrawingHank?.value !== "" &&
    formData?.breakerSpeed?.value !== "" &&
    formData?.finisherDrawing?.value !== "" &&
    formData?.finisherNoOfEnds?.value !== "" &&
    formData?.finisherBottomRollSetting?.value !== "" &&
    formData?.bdFinisher?.value !== "" &&
    formData?.finisherDeliveryHank?.value !== "" &&
    formData?.finisherDeliverySpeed?.value !== "";

  //all outOfNorms shomustuld be false
  const isManagerEnableSubmit =
    !formData?.breakerDrawing?.isOutOfNorms &&
    !formData?.breakerNoOfEnds?.isOutOfNorms &&
    !formData?.breakerBottomRollSetting?.isOutOfNorms &&
    !formData?.bdBreaker?.isOutOfNorms &&
    !formData?.breakerDrawingHank?.isOutOfNorms &&
    !formData?.breakerSpeed?.isOutOfNorms &&
    !formData?.finisherDrawing?.isOutOfNorms &&
    !formData?.finisherNoOfEnds?.isOutOfNorms &&
    !formData?.finisherBottomRollSetting?.isOutOfNorms &&
    !formData?.bdFinisher?.isOutOfNorms &&
    !formData?.finisherDeliveryHank?.isOutOfNorms &&
    !formData?.finisherDeliverySpeed?.isOutOfNorms;

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    apiStandardsData?.data ? apiStandardsData?.data : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    goBack();
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">Add Drawing Record</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <p>Party Code: </p>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              disabled={viewMode || editMode || isManager}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handleApply}
            color="primary"
            style={{
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {applied && partyCode && (
            <Select
              value={selectedDates}
              onChange={handleDateChange}
              label="Select Date"
              fullWidth
            >
              {allDates?.map((date) => (
                <MenuItem key={date} value={date}>
                  {dayjs(date).format("DD-MM-YYYY")}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Particulars</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Previous Data
                </Grid>
                <Grid item xs={12}>
                  {selectedDates && dayjs(selectedDates).format("DD-MM-YYYY")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Actual Data
                </Grid>
                <Grid item xs={12}>
                  {isManager
                    ? dayjs(selectedDataToEdit?.date).format("DD-MM-YYYY")
                    : today}
                </Grid>
              </TableCell>
              {isManager && (
                <TableCell>
                  <Grid item xs={12}>
                    Correction Data
                  </Grid>
                  <Grid item xs={12}>
                    {today}
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Breaker Drawing</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.breakerDrawing}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    color: filteredDataFromApi[0]?.breakerDrawing?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.breakerDrawing?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.breakerDrawing?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.breakerDrawing?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="breakerDrawing"
                  variant="outlined"
                  value={formData?.breakerDrawing?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.breakerDrawing?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.breakerDrawing ===
                        formData?.breakerDrawing?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No of ends</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.breakerNoOfEnds}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.breakerNoOfEnds?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.breakerNoOfEnds?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.breakerNoOfEnds?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.breakerNoOfEnds?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="breakerNoOfEnds"
                  variant="outlined"
                  value={formData?.breakerNoOfEnds?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.breakerNoOfEnds?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.breakerNoOfEnds ===
                        formData?.breakerNoOfEnds?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom roll setting</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.breakerBottomRollSetting}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.breakerBottomRollSetting
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {" "}
                  {filteredDataFromApi[0]?.breakerBottomRollSetting?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.breakerBottomRollSetting
                        ?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.breakerBottomRollSetting?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="breakerBottomRollSetting"
                  variant="outlined"
                  value={formData?.breakerBottomRollSetting?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.breakerBottomRollSetting
                        ?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data
                          ?.breakerBottomRollSetting ===
                        formData?.breakerBottomRollSetting?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD Breaker</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.bdBreaker}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.bdBreaker?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.bdBreaker?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.bdBreaker?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.bdBreaker?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="bdBreaker"
                  variant="outlined"
                  value={formData?.bdBreaker?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.bdBreaker?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.bdBreaker ===
                        formData?.bdBreaker?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Drawing Hank</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.breakerDrawingHank}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.breakerDrawingHank
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.breakerDrawingHank?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.breakerDrawingHank
                        ?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.breakerDrawingHank?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="breakerDrawingHank"
                  variant="outlined"
                  value={formData?.breakerDrawingHank?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.breakerDrawingHank?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.breakerDrawingHank ===
                        formData?.breakerDrawingHank?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.breakerSpeed}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.breakerSpeed?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.breakerSpeed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.breakerSpeed?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.breakerSpeed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="breakerSpeed"
                  variant="outlined"
                  value={formData?.breakerSpeed?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.breakerSpeed?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.breakerSpeed ===
                        formData?.breakerSpeed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Finisher Drawing</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.finisherDrawing}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.finisherDrawing?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.finisherDrawing?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.finisherDrawing?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.finisherDrawing?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="finisherDrawing"
                  variant="outlined"
                  value={formData?.finisherDrawing?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.finisherDrawing?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.finisherDrawing ===
                        formData?.finisherDrawing?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No of ends</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.finisherNoOfEnds}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.finisherNoOfEnds
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.finisherNoOfEnds?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.finisherNoOfEnds?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.finisherNoOfEnds?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="finisherNoOfEnds"
                  variant="outlined"
                  value={formData?.finisherNoOfEnds?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.finisherNoOfEnds?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.finisherNoOfEnds ===
                        formData?.finisherNoOfEnds?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom roll setting</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.finisherBottomRollSetting}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.finisherBottomRollSetting
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.finisherBottomRollSetting?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.finisherBottomRollSetting
                        ?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.finisherBottomRollSetting?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="finisherBottomRollSetting"
                  variant="outlined"
                  value={formData?.finisherBottomRollSetting?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.finisherBottomRollSetting
                        ?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data
                          ?.finisherBottomRollSetting ===
                        formData?.finisherBottomRollSetting?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD Finisher</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.bdFinisher}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.bdFinisher?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.bdFinisher?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.bdFinisher?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.bdFinisher?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="bdFinisher"
                  variant="outlined"
                  value={formData?.bdFinisher?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager && !selectedDataToEdit?.bdFinisher?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.bdFinisher ===
                        formData?.bdFinisher?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Hank</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.finisherDeliveryHank}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.finisherDeliveryHank
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.finisherDeliveryHank?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.finisherDeliveryHank
                        ?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.finisherDeliveryHank?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="finisherDeliveryHank"
                  variant="outlined"
                  value={formData?.finisherDeliveryHank?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.finisherDeliveryHank?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.finisherDeliveryHank ===
                        formData?.finisherDeliveryHank?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Speed</TableCell>
              <TableCell>
                {" "}
                {isError
                  ? "No standards"
                  : !isError &&
                    partyCode &&
                    apiStandardsData?.data?.data?.finisherDeliverySpeed}
              </TableCell>
              <TableCell>
                {" "}
                <p
                  style={{
                    color: filteredDataFromApi[0]?.finisherDeliverySpeed
                      ?.isOutOfNorms
                      ? "red"
                      : "green",
                  }}
                >
                  {filteredDataFromApi[0]?.finisherDeliverySpeed?.value}
                </p>
              </TableCell>
              {isManager && (
                <TableCell>
                  <p
                    style={{
                      color: selectedDataToEdit?.finisherDeliverySpeed
                        ?.isOutOfNorms
                        ? "red"
                        : "green",
                    }}
                  >
                    {selectedDataToEdit?.finisherDeliverySpeed?.value}
                  </p>
                </TableCell>
              )}
              <TableCell>
                <TextField
                  type="number"
                  name="finisherDeliverySpeed"
                  variant="outlined"
                  value={formData?.finisherDeliverySpeed?.value}
                  onChange={handleChange}
                  disabled={
                    viewMode ||
                    (isManager &&
                      !selectedDataToEdit?.finisherDeliverySpeed?.isOutOfNorms)
                  }
                  InputProps={{
                    style: {
                      color:
                        apiStandardsData?.data?.data?.finisherDeliverySpeed ===
                        formData?.finisherDeliverySpeed?.value
                          ? "green"
                          : "red",
                      width: "200px",
                    },
                  }}
                />{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {!isTelevision && (
        <>
          <TextField
            fullWidth
            name="feedback"
            value={formData.feedback}
            onChange={handleFeedback}
            label="Feedback"
            id="outlined-basic"
            variant="outlined"
            style={{ marginTop: "3%" }}
          />
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: "2%" }}
          >
            {!isManager && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "2%",
                }}
                disabled={
                  !partyCode ||
                  enableSubmit ||
                  updateMutation.isLoading ||
                  saveMutation.isLoading
                }
                onClick={handleSave}
              >
                {updateMutation.isLoading || saveMutation.isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save and Edit Later"
                )}
              </Button>
            )}
            <Button
              variant="contained"
              style={{
                background: "#181E72",
                color: "white",
                textTransform: "none",
              }}
              disabled={
                isManager
                  ? !isManagerEnableSubmit
                  : !enableSubmit ||
                    !partyCode ||
                    viewMode ||
                    managerUpdateMutation.isLoading ||
                    submitMutation.isLoading
              }
              onClick={handleSubmit}
            >
              {managerUpdateMutation.isLoading || submitMutation.isLoading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </>
      )}

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default AddDrawingRecord;
