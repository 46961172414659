import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TextField,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import "react-moment";
import { useQuery } from "react-query";
import * as XLSX from "xlsx";
import CustomButton from "../../../components/Button";
import { ContainerWrapper } from "../../../components/styles";
import apiClient from "../../../utils/clients/axios";
import { getVariety } from "../../Blowroom/mutation";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";

function YardReport() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    varietyEnabled: false,
    variety: [],
    machineNo: [],
    machineName: "",
    machineNoEnabled: false,
    lineNoEnabled: false,
  });

  const [machineNoOptions] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    //this is for send the data as a single element from the array
    const varieties = formData?.variety?.join(",") || "";
    const machinesNo = formData?.machineNo?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/finisher/reports?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true }
              : {
                  variety: varieties,
                  machineNo: machinesNo,
                  machineName: formData?.machineName,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      let headers = new Set();
      let dataRows = [];

      const flattenObject = (obj, parentKey = "") => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const newKey = parentKey ? `${parentKey}_${key}` : key;
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            Object.assign(acc, flattenObject(value, newKey));
          } else {
            acc[newKey] = value;
          }
          return acc;
        }, {});
      };

      response?.data?.forEach((item) => {
        let row = {};
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((obj, index) => {
              const flatObj = flattenObject(obj, `${key}_${index + 1}`);
              Object.entries(flatObj).forEach(([subKey, subValue]) => {
                headers.add(subKey);
                row[subKey] = subValue;
              });
            });
          } else if (typeof value === "object" && value !== null) {
            const flatObj = flattenObject(value, key);
            Object.entries(flatObj).forEach(([subKey, subValue]) => {
              headers.add(subKey);
              row[subKey] = subValue;
            });
          } else {
            headers.add(key);
            row[key] =
              key === "created_at" ? dayjs(value).format("DD-MM-YYYY") : value;
          }
        });
        dataRows.push(row);
      });

      headers = Array.from(headers);

      const excludeHeaders = ["id", "updated_at", "employeeId"];
      headers = headers.filter((header) => !excludeHeaders.includes(header));

      //this is for show the 'date' key in ui instaed of created_at
      headers = headers.map((header) => {
        let newHeaders = header === "created_at" ? "date" : header;
        return newHeaders;
      });

      // Move "date" to the 0th position if it exists in the array
      const dateIndex = headers.indexOf("date");

      if (dateIndex > -1) {
        // Remove "date" from its current position
        const [dateHeader] = headers.splice(dateIndex, 1);
        // Insert "date" at the 0th position
        headers.unshift(dateHeader);
      }

      const worksheetData = [headers];

      dataRows.forEach((row) => {
        const rowData = headers.map(
          //this change also for show the 'date' key in ui instaed of created_at
          (header) => row[header === "date" ? "created_at" : header] || ""
        );
        worksheetData.push(rowData);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "FINISHER-DRAWING-REPORTS.xlsx");
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerWrapper container rowGap={2} height={0}>
        <Grid item xs={12}>
          <h2>1 & 1/2 Yard</h2>
        </Grid>
        <Grid item container justifyContent={"space-between"} xs={12} md={8}>
          <Grid item>
            <RadioGroup
              name="reportType"
              value={formData.reportType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="individual"
                control={<Radio />}
                label="Individual Report"
              />
              <FormControlLabel
                value="consolidated"
                control={<Radio />}
                label="Consolidated Report"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container mb={3}>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} alignItems={"center"}>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table>
              <TableRow>
                <TableCell>Variety</TableCell>
                <TableCell style={{ width: 500 }}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.variety}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(e) => handleFormChange(e, "variety")}
                    >
                      {varietyData?.map((variety) => (
                        <MenuItem key={variety} value={variety}>
                          <Checkbox
                            checked={formData?.variety?.indexOf(variety) > -1}
                          />
                          <ListItemText primary={variety} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Machine No</TableCell>
                <TableCell style={{ width: 500 }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        multiple
                        value={formData?.machineNo}
                        disabled={formData.reportType === "consolidated"}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={(event) =>
                          handleInputChange("machineNo", event.target.value)
                        }
                      >
                        {machineNoOptions?.map((machineNo) => (
                          <MenuItem key={machineNo} value={machineNo}>
                            <Checkbox
                              checked={
                                formData?.machineNo?.indexOf(machineNo) > -1
                              }
                            />
                            <ListItemText primary={machineNo} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Machine Name</TableCell>
                <TableCell style={{ width: 500 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={formData?.machineName}
                      disabled={formData.reportType === "consolidated"}
                      onChange={(event) =>
                        handleInputChange("machineName", event.target.value)
                      }
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            </Table>{" "}
          </TableContainer>
        </Grid>

        <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Generate"
            )}
          </CustomButton>
        </Grid>
      </ContainerWrapper>
    </form>
  );
}

export default YardReport;
