import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

const DrawingMasterForm = ({ drawing, setDrawing }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    // const updatedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setDrawing((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Breaker Drawing</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="breakerDrawing"
                  variant="outlined"
                  value={drawing?.breakerDrawing}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No of ends</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="breakerNoOfEnds"
                  variant="outlined"
                  value={drawing?.breakerNoOfEnds}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom roll setting</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="breakerBottomRollSetting"
                  variant="outlined"
                  value={drawing?.breakerBottomRollSetting}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD Breaker</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="bdBreaker"
                  variant="outlined"
                  value={drawing?.bdBreaker}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Drawing Hank</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="breakerDrawingHank"
                  variant="outlined"
                  value={drawing?.breakerDrawingHank}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="breakerSpeed"
                    variant="outlined"
                    value={drawing?.breakerSpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Finisher Drawing</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="finisherDrawing"
                  variant="outlined"
                  value={drawing?.finisherDrawing}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No of Ends</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="finisherNoOfEnds"
                  variant="outlined"
                  value={drawing?.finisherNoOfEnds}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom roll setting</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="finisherBottomRollSetting"
                  variant="outlined"
                  value={drawing?.finisherBottomRollSetting}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD Finisher</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="bdFinisher"
                  variant="outlined"
                  value={drawing?.bdFinisher}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Hank</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="finisherDeliveryHank"
                  variant="outlined"
                  value={drawing?.finisherDeliveryHank}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="finisherDeliverySpeed"
                    variant="outlined"
                    value={drawing?.finisherDeliverySpeed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default DrawingMasterForm;
