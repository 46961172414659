import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { MACHINE_SETTINGS_SIMPLEX_NO } from "../config/masterdata";

const SimplexMasterForm = ({ simplex, setSimplex }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }
    const [field, subfield] = name.split(".");

    if (subfield) {
      setSimplex((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          [subfield]: value,
        },
      }));
    } else {
      setSimplex((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Grid
      container
      // spacing={3}
      item
      xs={12}
      justifyContent="space-between"
      style={{ marginTop: "2%" }}
    >
      {/*  */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Simplex No.</TableCell>
              <TableCell>
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  <Select
                    labelId="single-select-label"
                    value={simplex.simplexNo}
                    onChange={handleChange}
                    name="simplexNo"
                    // label="Select Number"
                  >
                    {MACHINE_SETTINGS_SIMPLEX_NO?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simplex Hank</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="simplexHank"
                  variant="outlined"
                  value={simplex.simplexHank}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TPI/TM</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="tpiTm"
                  variant="outlined"
                  value={simplex.tpiTm}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Speed</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    name="speed"
                    variant="outlined"
                    value={simplex.speed}
                    onChange={handleChange}
                  />
                  <span style={{ paddingLeft: "1%" }}>MPM</span>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bottom roll setting</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="first"
                    // value={simplexBottomRollFields.first}
                    // onChange={handleBottomRollFields}

                    name="bottomRollSetting.x"
                    value={simplex.bottomRollSetting.x}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                    // value={simplex.bottomRollSetting}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="second"
                    // value={simplexBottomRollFields.second}
                    // onChange={handleBottomRollFields}
                    name="bottomRollSetting.y"
                    value={simplex.bottomRollSetting.y}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                    // value={simplex.bottomRollSetting}
                  />
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="third"
                    // value={simplexBottomRollFields.third}
                    // onChange={handleBottomRollFields}
                    name="bottomRollSetting.z"
                    value={simplex.bottomRollSetting.z}
                    onChange={handleChange}
                    // style={{ marginTop: isSmallScreen ? "2%" : 0 }}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                    // value={simplex.bottomRollSetting}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Top roll setting</TableCell>
              <TableCell>
                <Grid item alignItems="center" container>
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="first"
                    // value={simplexTopRollFields.first}
                    // onChange={handleTopRollFields}
                    name="topRollSetting.x"
                    value={simplex.topRollSetting.x}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />{" "}
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    // name="second"
                    // value={simplexTopRollFields.second}
                    // onChange={handleTopRollFields}
                    name="topRollSetting.y"
                    value={simplex.topRollSetting.y}
                    onChange={handleChange}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />
                  <span style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    *
                  </span>{" "}
                  <TextField
                    type="number"
                    variant="outlined"
                    name="topRollSetting.z"
                    value={simplex.topRollSetting.z}
                    onChange={handleChange}
                    // name="third"
                    // value={simplexTopRollFields.third}
                    // onChange={handleTopRollFields}
                    style={{ width: isSmallScreen ? "24%" : "20%" }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BD</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="bd"
                  variant="outlined"
                  value={simplex.bd}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Spacer</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="spacer"
                  variant="outlined"
                  value={simplex.spacer}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Top Arm Pressure</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="topArmPressure"
                  variant="outlined"
                  value={simplex.topArmPressure}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Condensor back</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="condensorBack"
                  variant="outlined"
                  value={simplex.condensorBack}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Condensor Middle</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="condensorMiddle"
                  variant="outlined"
                  value={simplex.condensorMiddle}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Condensor Front</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  name="condensorFront"
                  variant="outlined"
                  value={simplex.condensorFront}
                  onChange={handleChange}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SimplexMasterForm;
