import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import { useQuery } from "react-query";

import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog";
import MasterStandardDialog from "../../../components/MasterStandardDialog";
import { getAllNotificationData } from "../Mutation/mutation";
import DrawingPreviousEntries from "../../MachineSetting/DrawingMachine/DrawingPreviousEntries";
import AddDrawingRecord from "../../MachineSetting/DrawingMachine/AddDrawingRecord";
import dayjs from "dayjs";
import NoDataAvailable from "../../../utils/NoDataAvailable";
import { CircularProgress } from "@mui/material";

const NestedTable = ({ data }) => {
  const fieldsToDisplay = [
    "breakerDrawing",
    "breakerNoOfEnds",
    "breakerBottomRollSetting",
    "bdBreaker",
    "breakerDrawingHank",
    "breakerSpeed",
    "finisherDrawing",
    "finisherNoOfEnds",
    "finisherBottomRollSetting",
    "bdFinisher",
    "finisherDeliveryHank",
    "finisherDeliverySpeed",
  ];

  return (
    <Table size="small" aria-label="nested table">
      <TableBody>
        {fieldsToDisplay?.map((field, index) => {
          // Check if isOutOfNorms is true for the current field
          if (data[field]?.isOutOfNorms) {
            return (
              <TableRow key={index}>
                <TableCell style={{ border: "1px solid #E2E2E2" }}>
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #E2E2E2",
                    color: data[field]?.isOutOfNorms ? "red" : "inherit",
                  }}
                >
                  {data[field]?.value}
                </TableCell>
              </TableRow>
            );
          } else {
            return null; // Return null for fields that are not out of norms
          }
        })}
      </TableBody>
    </Table>
  );
};
function DrawingSettingManager({ isTelevision,setPauseInterval }) {
  const [activeComponent, setActiveComponent] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [openStdDataDialog, setOpenStdDataDialog] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const {
    data: allNotificationData,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    "all-notification-data",
    async () => {
      if (!isTelevision) {
        setIsLoadingData(true);
      }
      try {
        const response = await getAllNotificationData();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        if (!isTelevision) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  const showData = useMemo(() => {
    return allNotificationData?.data?.DrawingMaster || [];
  }, [allNotificationData]);

  const [isManager, setIsManager] = useState(false);
  const [selectedDataToEdit, setSelectedDataToEdit] = useState({});

  const handleRespond = (rowData) => {
    setActiveComponent("AddRecord");
    setIsManager(true);
    setSelectedDataToEdit(rowData);
    if (isTelevision) {
      setPauseInterval(true);
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "PreviousData":
        return (
          <DrawingPreviousEntries goBack={() => setActiveComponent(null)} />
        );
      case "AddRecord":
        return (
          <AddDrawingRecord
            selectedDataToEdit={selectedDataToEdit}
            goBack={() => setActiveComponent(null)}
            isManager={isManager}
            isTelevision={isTelevision}
            setPauseInterval={setPauseInterval}
          />
        );
      default:
        return (
          <Grid container>
            {isLoadingData || isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                position="fixed"
                top={0}
                left={0}
                width="100%"
                zIndex={9999} // Ensure this is above other elements
                background="rgba(255, 255, 255, 0.8)" // Background color with transparency
              >
                <CircularProgress /> {/* Center the loading spinner */}
              </Box>
            ) : isError ? (
              <Grid item xs={12} container justifyContent="center">
                <p>Error loading data</p>
              </Grid>
            ) : (
              <Grid container item md={12} xs={12} alignContent="flex-start">
                <Grid
                  container
                  item
                  md={12}
                  xs={12}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Grid
                    container
                    item
                    md={isTelevision ? 12 : 6}
                    xs={isTelevision ? 12 : 6}
                    justifyContent={isTelevision && "center"}
                  >
                    <h3>DRAWING - MACHINE SETTINGS</h3>
                  </Grid>
                  {!isTelevision && (
                    <Grid
                      item
                      md={5}
                      xs={6}
                      container
                      justifyContent="space-between"
                    >
                      <Button
                        variant="contained"
                        className="view-btn"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          textTransform: "none",
                        }}
                        onClick={handleOpenStdDataDialog}
                      >
                        View Standards
                      </Button>
                      <Link to="">
                        <Button
                          variant="contained"
                          className="add-btn"
                          color="primary"
                          style={{
                            // backgroundColor: "#87bfed",
                            textTransform: "none",
                          }}
                          onClick={() => setActiveComponent("PreviousData")}
                        >
                          View Previous Entries
                        </Button>
                      </Link>
                    </Grid>
                  )}
                </Grid>

                {showData?.length > 0 ? (
                  <TableContainer component={Paper} style={{ marginTop: "2%" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Variety</TableCell>
                          <TableCell align="center">Error</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showData?.length > 0 &&
                          showData?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {dayjs(row?.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{row?.partyCode}</TableCell>
                              <TableCell>
                                <Box>
                                  <NestedTable data={row} />
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  onClick={() => handleRespond(row)}
                                  style={{
                                    border: "1px solid red",
                                    backgroundColor: "white",
                                    marginRight: "4%",
                                    textTransform: "none",
                                  }}
                                >
                                  {isTelevision ? "View" : "Respond"}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoDataAvailable />
                )}
              </Grid>
            )}
          </Grid>
        );
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    // mutation.mutate(selectedIdToDelete);
    handleCloseDeleteDialog();
  };

  const handleOpenStdDataDialog = () => {
    setOpenStdDataDialog(true);
  };

  const handleCloseStdDataDialog = () => {
    setOpenStdDataDialog(false);
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "2%",
        paddingBottom: "4%",
      }}
    >
      {renderComponent()}
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
      />
      <MasterStandardDialog
        open={openStdDataDialog}
        onClose={handleCloseStdDataDialog}
        heading="Drawing Standard Dialog"
      />
    </Grid>
  );
}

export default DrawingSettingManager;
